.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	transition: .5s;
	text-decoration: none;
	cursor: pointer;
	text-align: center;

	&_theme1 {
		border: .7px solid #fff;
		border-radius: 20px;
		font-size: 11px;
		color: #fff;
		font-family: "GothamPro";
		padding-left: 11px;
		padding-right: 11px;
		height: 24px;
		background-color: transparent;
		&:hover {
			background: #fff;
			color: #000;	
		}
	}

	&_theme2 {
		background: $pink;
		color: #fff;
		font-size: 21px;
		font-weight: 700;
		height: 47px;
		border-radius: 7px;
		padding-left: 15px;
		padding-right: 15px;
		&:hover {
			background: darken($pink,10%);	
		}

		&_large {
			height: 67px;
			width: 450px;
			max-width: 100%;
			font-size: 28px;
			border-radius: 40px;
			box-shadow: 0 0 100px 8px rgba(0,0,0,.2);
		}
		&_medium {
			width: auto;
			font-size: 18px;
			height: 68px;
			padding-left: 40px;
			padding-right: 40px;
			text-transform: uppercase;
			border-radius: 40px;
		}
		&_medium2 {
			width: auto;
			font-size: 22px;
			height: 51px;
			padding-left: 70px;
			padding-right: 70px;
			text-transform: uppercase;
			border-radius: 40px;
		}
	}

	&_theme3 {
		background: #fff;
		width: 197px;
		height: 39px;
		font-weight: 700;
		font-size: 14px;
		color: $blue;
		border-radius: 20px;
		border: 2px solid #fff;
		&:hover {
			border-color: #fff;
			background: $blue;
			color: #fff;	
		}
	}

	&_theme4 {
		background: $blue;
		color: #fff;
		font-size: 28px;
		line-height: 28px;
		font-weight: 700;
		height: 70px;
		width: 447px;
		border-radius: 50px;
		padding-left: 15px;
		padding-right: 15px;
		&:hover {
			background: darken($blue, 10%);	
		}

		&_large {
			height: 67px;
			width: 450px;
			font-size: 28px;
			border-radius: 40px;
			box-shadow: 0 0 100px 8px rgba(0,0,0,.2);
		}
		&_small {
			width: 310px;
			height: 49px;
			font-size: 17px;
		}
	}
	&_theme5 {
		background: #fff;
		color: $blue;
		border: 2px solid transparent;
		font-size: 17px;
		line-height: 17px;
		font-weight: 700;
		width: 171px;
		height: 38px;
		border-radius: 20px;
		padding-left: 15px;
		padding-right: 15px;

		&:hover {
			background: $blue;
			border-color: #fff;
			color: #fff;
		}
		&_large {
			height: 65px;
			font-size: 33px;
			line-height: 33px;
			width: auto;
			border-radius: 40px;
			padding-left: 47px;
			padding-right: 47px;
		}
	}

	&_theme6 {
		border: 1px solid #B9CEDF;
		border-radius: 10px;
		font-size: 15px;
		color: $blue2;
		height: 37px;
		background-color: transparent;
		min-width: 180px;
		&:hover {
			background: #B9CEDF;
			color: #fff;
		}
		&_medium {
			height: 46px;
		}
	}

	&_theme7 {
		background: #fff;
		color: $blue;
		font-size: 19px;
		line-height: 25px;
		font-weight: 700;
		width: 180px;
		height: 44px;
		border-radius: 30px;
		padding-left: 15px;
		padding-right: 15px;
		border: 2px solid transparent;

		&:hover {
			background: $blue;
			border-color: #fff;
			color: #fff;
		}
	}

	&_arrow {
		width: 33px;
		height: 33px;
		background: url(../img/icon-arrow-frame.svg) no-repeat;
		&_blue2 {
			background: url(../img/icon-arrow-frame2.svg) no-repeat;
		}
	}

	&_plus {
		width: 40px;
		height: 40px;
		background: #fff url(../img/icon-plus2.svg) no-repeat center;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,.18);
		border-radius: 100%;
	}

	&_store {
		&:hover {
			opacity: .9;	
		}
	}

	&_subscribe {
		position: absolute;
		right: 10px;
		top: 7px;
		width: 26px;
		height: 26px;
		background: url(../img/icon-telegram2.svg) no-repeat;
	}

	&_soc {
		margin-right: 30px;
		flex-shrink: 0;
		&:last-child {
			margin-right: 0;	
		}
		&:hover {
			opacity: .7;
		}
	}

	&_mob-menu {
		width: 32px;
		height: 28px;
		background: url(../img/icon-menu.svg) no-repeat;
	}

	&_user {
		width: 32px;
		height: 32px;
		background: #fff;
		border-radius: 100%;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 17px;
			height: 17px;
			background: url(../img/icon-user.svg) no-repeat;
		}
	}

	&_back {
		width: 33px;
		height: 33px;
		background: url(../img/icon-arrow.svg) no-repeat;
	}

	&_to-top {
		position: fixed;
		left: 50px;
		top: 150px;
		width: 53px;
		height: 53px;
		background: $blue;
		border-radius: 100%;
		z-index: 200;
		opacity: 0;
		visibility: hidden;
		transition: .4s;
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 34px;
			height: 32px;
			background: url(../img/arrow-top.svg);
		}
		&.visible {
			opacity: 1;
			visibility: visible;
		}
	}

	&_search {
		position: absolute;
		left: 15px;
		top: 50%;
		transform: translateY(-50%);
		width: 22px;
		height: 22px;
		background: url(../img/icon-search.svg) no-repeat;
	}
	&_search2 {	
		width: 22px;
		height: 22px;
		background: url(../img/icon-search_white.svg) no-repeat;
		background-size: cover;
		flex-shrink: 0;
		&_large {
			width: 41px;
			height: 41px;
		}
	}
		

}