.nice-select {
	display: inline-block;
	vertical-align: top;
  -webkit-tap-highlight-color: transparent;
  color: #8B9DAF;
  border: 1px solid #BEBEBE;
  border-radius: 4px;
  padding: 2px 15px 3px;
  width: 100%;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  outline: none;
  position: relative;
  text-align: center;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
    &:after {
      content: "";
      position: absolute;
      right: 6px;
      top: 10px;
      border: 7px solid transparent;
      border-top: 7px solid #8B9DAF;
    }
  }
  .nice-select>span {
    position: relative;
    padding-right: 13px;
  }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select.open .list {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;}
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    border: 1px solid #BEBEBE;
    border-radius: 0 0 4px 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    z-index: 9;
    transition: .3s;
    transform: translateY(-5px); }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 15px;
    list-style: none;
    outline: none;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .nice-select .option:hover {
    	background: #F6F7F9;
    }
    .nice-select .option.selected {
      color: $blue2;
      }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }