@font-face {
    font-family: 'agfatumc';
    src: url('../fonts/agfatumc-webfont.eot');
    src: url('../fonts/agfatumc-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/agfatumc-webfont.woff2') format('woff2'),
         url('../fonts/agfatumc-webfont.woff') format('woff'),
         url('../fonts/agfatumc-webfont.ttf') format('truetype'),
         url('../fonts/agfatumc-webfont.svg#agfatumcregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'agfatumc';
    src: url('../fonts/agfatumc-bold-webfont.eot');
    src: url('../fonts/agfatumc-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/agfatumc-bold-webfont.woff2') format('woff2'),
         url('../fonts/agfatumc-bold-webfont.woff') format('woff'),
         url('../fonts/agfatumc-bold-webfont.ttf') format('truetype'),
         url('../fonts/agfatumc-bold-webfont.svg#agfatumcbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url('../fonts/GothamPro.eot?#iefix') format('embedded-opentype'),  url('../fonts/GothamPro.otf')  format('opentype'),
         url('../fonts/GothamPro.woff') format('woff'), url('../fonts/GothamPro.ttf')  format('truetype'), url('../fonts/GothamPro.svg#GothamPro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'GothamPro';
    src: url('../fonts/GothamPro-Light.eot');
    src: local('Gotham Pro Light'), local('GothamPro-Light'),
        url('../fonts/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamPro-Light.woff2') format('woff2'),
        url('../fonts/GothamPro-Light.woff') format('woff'),
        url('../fonts/GothamPro-Light.ttf') format('truetype'),
        url('../fonts/GothamPro-Light.svg#GothamPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}
