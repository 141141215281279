.header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 200;
	background: #F9FBFC; 
	&__lvl1 {
		padding-top: 10px;
		padding-bottom: 10px;
		.container {
			max-width: 1570px;
			display: flex;
			justify-content: flex-end;
		}
	}
	&__lvl2 {
		background: $pink;
		padding-top: 8px;
		padding-bottom: 7px;
		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			max-width: 1580px;
		}
	}

	&__links {
		margin-right: 40px;
		list-style: none;
		display: flex;
		&>li {
			margin-right: 26px;
			a {
				text-transform: uppercase;
				text-decoration: none;
				font-size: 10px;
				line-height: 12px;
				color: #000;
				text-transform: uppercase;
				font-family: 'GothamPro';
				&:hover {
					text-decoration: underline;	
				}
			}
			&:last-child {
				margin-right: 0;	
			}
		}
	}

	&__call {
		font-family: 'GothamPro';
		font-size: 13px;
		a {
			text-decoration: none;
			color: #000;
		}
	}

	&__logo {
		width: 110px;
		line-height: 0;
	}

	&__section-nav {
		display: flex;
		align-items: center;
	}

	&__mob {
		background: $pink;
		padding-top: 10px;
		padding-bottom: 10px;
		display: none;
		color: #fff;
		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	&__ask {
		font-size: 12px;
		line-height: 14px;
		a {
			color: #fff;
			text-decoration: none;
		}
	}


	&_inner {
		.menu {
			&>li {
				&>a {
					font-size: 19px;
					font-weight: 700;
					font-family: 'agfatumc';
				}
			}
		}
		.header {
			&__call {
				font-family: 'agfatumc';
				font-size: 19px;
				font-weight: 700;
				color: #fff;
				a {
					color: #fff;
				}
			}
			&__logo {
				width: 155px;
			}
			&__lvl2 {
				box-shadow: 0 19px 30px 0 rgba(0,0,0,.18);
				padding-top: 17px;
				padding-bottom: 15px;
				.container {
					max-width: 1720px;
				}
			}
			&__section-nav {
				padding-right: 55px;
			}
		}
	}

}

.wrapper {
	padding-top: 88px;
}

.menu {
	list-style: none;
	display: flex;
	margin-right: 42px;
	&>li {
		margin-right: 41px;
		&>a {
			font-family: 'GothamPro';
			font-size: 13px;
			color: #fff;
			text-decoration: none;
			&:hover {
				text-decoration: underline;	
			}
		}
		&:last-child {
			margin-right: 0;	
		}
	}
}

.section-start {
	background: url(../img/start-bg.png) no-repeat -178px -105px;
	min-height: 767px;
	max-width: 1920px;
	margin: 0 auto;
	.container {
		position: relative;
	}
}

.start {
	&__title {
		color: #fff;
		font-size: 41px;
		line-height: 53px;
		max-width: 75%;
		text-align: right;
		padding-top: 210px;
		margin-right: 500px;
	}
	&__site-block {
		position: absolute;
		right: -179px;
		top: 0;
		width: 682px;
		height: 443px;
		background: url(../img/figure-1.svg) no-repeat 0 bottom;
		color: #fff;
		&__inner {
			position: absolute;
			left: 50px;
			bottom: 50px;
		}
		&__text {
			margin-top: 20px;
			font-size: 17px;
			line-height: 21px;
			width: 200px;
			padding-left: 120px;
		}
	}
	&__logotypes {
		order: 3;
		align-items: center;
		justify-content: center;
		display: none;
		&__item {
			border-right: 2px solid rgba(84,121,172,.5);
			height: 66px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50%;
			padding-right: 20px;
			box-sizing: border-box;
			padding-right: 20px;
			margin-right: 20px;
			&:nth-child(2n) {
				border-right: 0;
				margin-right: 0;
				padding-right: 0;
			}
		}
	} 
}

.block-request {
	display: flex;
	align-items: center;
	background: $blue;
	border-radius: 20px;
	max-width: 1305px;
	margin-top: -51px;
	padding: 35px 45px 28px;
	display: flex;
	align-items: center;
	color: #fff;
	box-sizing: border-box;
	box-shadow: 0 0 100px 10px rgba(0,0,0,.3);
	&__left {
		flex-shrink: 0;
		margin-right: 42px;
		text-align: center;
	}
	&__title {
		font-size: 26px;
		line-height: 26px;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	&__title2 {
		font-size: 23px;
		line-height: 20px;
	}
	&__field {
		margin: 0 5px;
		&_text {
			width: 226px;
			input {
				padding-left: 20px;
				padding-right: 20px;
				width: 100%;
				box-sizing: border-box;
				height: 47px;
				color: $blue;
				font-size: 18px;
				border-radius: 7px;
			}
		}
		&_btn {
			width: 172px;
			.btn {
				width: 100%;
			}
		}
	}
	&__form {
		display: flex;
		width: 100%;
		flex-grow: 1;
		justify-content: space-between;
		margin: 0 -5px;
	}
	&__btn {
		display: none;
	}
}

.accounting {
	display: flex;
	justify-content: center;
	padding-top: 18px;
	padding-bottom: 75px;
	&__img {
		position: relative;
		left: -70px;
		margin-right: 30px;
		width: 392px;
		min-width: 310px;
		margin-top: -44px;
		text-align: center;
	}
	&__main {
		width: 430px;
	}
	&__block {
		color: $blue2;
		position: relative;
		padding-left: 50px;
		padding-bottom: 50px;
		margin-bottom: 42px;
		&__bg {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 917px;
			height: 910px;
			background: url(../img/figure-2.svg);
			z-index: -1;
		}
	}
	&__title {
		font-size: 27px;
		line-height: 44px;
		margin-bottom: 20px;
		margin-bottom: 10px;
	}
	&__title2 {
		font-size: 33px;
		line-height: 43px;
	}
}

.men-icons {
	position: relative;
	&__icons-wrap {
		margin-bottom: 15px;		
		display: flex;
		justify-content: space-around;

	}
	&__icon {
		&_pos4 {
			position: absolute;
			left: 50%;
			top: 110px;
			transform: translateX(-50%);
		}
	}
}

.section-accounting {
	.next-sep {
		margin: 50px 0;
	}
}

.next-sep {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	&__icon {
		display: block;
		width: 39px;
		height: 39px;
		border-radius: 100%;
		margin-bottom: 30px;
		position: relative;
		background: rgba(84,121,172,.42);
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: 52%;
			transform: translate(-50%,-50%);
			width: 22px;
			height:10px;
			background: url(../img/icon-arrow-down.svg) no-repeat;
		}
	}
	&__line {
		display: block;
		width: 1px;
		height: 50px;
		background: #DEE4ED;
	}

	&_theme2 {
		.next-sep {
			&__icon {
				background: rgba(84,121,172,.62);
			}
			&__line {
				background: $blue2;
			}
		}
	}
}

.section-title {
	text-align: center;
	font-size: 44px;
	line-height: 50px;
	color: $blue2;
	font-weight: 700;
}

.title {
	text-align: center;
	font-size: 30px;
	line-height: 36px;
	color: $blue2;
	font-weight: 700;
	&_large {
		font-size: 46px;
		line-height: 61px;
	}
	&_medium {
		font-size: 25px;
		line-height: 30px;
		margin-bottom: 25px;
	}
	&_small {
		font-size: 12px;
		line-height: 14px;
		margin-bottom: 20px;
	}
	&.align-left {
		text-align: left;
	}
}

.section-differences1 {
	padding-top: 50px;
	padding-bottom: 85px;
	.next-sep {
		margin-bottom: 20px;
	}
	.section-title {
		margin-bottom: 100px;
	}
}

.differences1 {
	&__cols {
		display: flex;
		justify-content: center;
	}
	&__col {
		width: 50%;
	}
	&__img {
		text-align: center;
		padding-top: 64px;
		&__inner {
			position: relative;
			right: -80px;
		}
	}
	&__main {
		&__inner {
			max-width: 465px;
		}
		&__item {
			margin-left: 62px;
			border-bottom: 1px solid #DEE4ED;
			position: relative;
			height: 130px;
			display: flex;
			align-items: center;
			font-size: 18px;
			line-height: 24px;
			color: $blue2;
		}
		&__icon {
			top: 50%;
			transform: translateY(-50%);
			left: -80px;
			flex-shrink: 0;
			&.icon-minus {
				position: absolute;
			}
		}
	}
}

.icon-minus {
	display: inline-block;
	width: 30px;
	height: 30px;
	background: #FD3A58;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 10px;
		height: 2px;
		background: #fff;
	}
}

.section-differences2 {
	padding-bottom: 190px;
	.next-sep {
		margin-bottom: 47px;
	}
	.section-title {
		margin-bottom: 100px;
	}
}

.differences2 {
	&__cols {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		position: relative;
	}
	&__col {
		width: 50%;
	}
	&__img {
		&__inner {
			position: relative;
			top: -10px;
			text-align: center;
			right: -45px;
			height: 830px;
			width: 790px;
		}
		&__btn {
			position: absolute;
			right: 230px;
			top: 635px;
		}
		
	}
	&__main {
		padding-top: 212px;
		position: relative;
		padding-bottom: 30px;
		&__bg-figure {
			position: absolute;
			left: 33px;
			bottom: 0;
			width: 2000px;
			height: 2000px;
			background: url(../img/figure-3.svg) no-repeat 0 bottom;
			z-index: -1;
		}
	}
	
	&__grafic {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -65px;
		width: 142px;
		height: 142px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 38px;
		line-height: 38px;
		border-radius: 100%;
		background: $blue;
		font-weight: 700;
		color: #fff;
		box-shadow: -15px 15px 50px 0 rgba(0,0,0,.2);
		&:after {
			content: "";
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 100%;
			height: 80px;
			width: 1px;
			background: #DEE4ED;
			z-index: -1;
		}
	}
}

.block-logo {
	position: absolute;
	left: 50%;
	top: -40px;
	transform: translateX(-50%);
	width: 97px;
	height: 97px;
	background: #FD4A68;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 20px 40px 0 rgba(0,0,0,.16);
	&:after {
		content: "";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 100%;
		height: 155px;
		width: 1px;
		background: #DEE4ED;
	}
}

.list-advantages {
	list-style: none;
	margin-left: -14px;
	&>li {
		position: relative;
		padding-left: 77px;
		height: 62px;
		margin-bottom: 10px;
		color: $blue2;
		font-size: 25px;
		line-height: 30px;
		&:last-child {
			margin-bottom: 0;
			&:after {
				display: none;
			}
		}
		&:before {
			content: "+";
			position: absolute;
			left: 0;
			top: 0;
			width: 28px;
			height: 28px;
			background: $blue;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 26px;
			line-height: 26px;
			font-family: "GothamPro";
			font-weight: 300;
			color: #fff;
			box-shadow: -5px 5px 20px 0 rgba(0,0,0,.3);
		}
		&:after {
			content: "";
			position: absolute;
			left: 14px;
			top: 28px;
			height: 42px;
			width: 1px;
			background: #DEE4ED;
			z-index: -1;
		}
	}
}

.block-more {
	text-align: center;
	&__title {
		text-align: center;
		color: $blue2;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 20px;
	}
	&__shadow {
		position: relative;
		margin: 15px 0 -60px;
		height: 100px;
		overflow: hidden;
		&:before {
			content: "";
			position: absolute;
			max-width: 1160px;
			border-radius: 100%;
			left: 50%;
			transform: translateX(-50%);
			top: -45px;
			width: 100%;
			box-shadow: 0 0 100px 20px rgba(0,0,0,.5);
		}
	}
}

.section-benefit {
	padding-top: 80px;
	.next-sep {
		margin-bottom: 80px;
	}
	.section-title {
		margin-bottom: 107px;
	}
}

.benefits {
	display: flex;
	justify-content: center;
	margin-bottom: 75px;
	&__item {
		width: 400px;
		&__title {
			font-size: 30px;
			line-height: 36px;
			color: $blue2;
			font-weight: 700;
			text-align: center;
			margin-bottom: 125px;
		}
		&__main {
			background: #fff;
			border-radius: 30px;
			box-sizing: border-box;
			padding: 0 50px 30px;
			text-align: center;
		}
		&__icon {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 138px;
			height: 138px;
			margin-top: -67px;
			margin-bottom: 42px;
			border-radius: 60px 30px 60px 30px;
			box-shadow: -10px 10px 40px 0 rgba(0,0,0,.15);
			&_pink {
				background: $pink;
			}
			&_blue {
				background: $blue;
			}
		}
		&__text {
			font-size: 25px;
			line-height: 33px;
			color: $blue2;
			text-align: center;
			border-top: 1px solid #DEE4ED;
			height: 300px;
			display: flex;
			align-items: center;
		}
	}
	&__plus {
		margin: 20.5% 20px 0;
	}
}

.offer-btn {
	text-align: center;
	margin-bottom: 60px;
	&__title {
		font-weight: 700;
		font-size: 30px;
		line-height: 36px;
		color: $blue2;
		margin-bottom: 67px;
	}
}

.icon-gradient-block {
	width: 111px;
	height: 111px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 47px;
	background: linear-gradient(to bottom, #fc7387 0%,#87c5fa 100%);
	border-radius: 30px;
	box-shadow: 0 2px 16px 0 rgba(0,0,0,.16);
	&_size-medium {
		width: 84px;
		height: 84px;
		border-radius: 22px;
	}
	&_size-small {
		width: 59px;
		height: 59px;
		border-radius: 13px;
	}
}

.inform-item {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 154px;
	&__text {
		width: 493px;
		text-align: left;
	}
}

.line-sep {
	width: 51px;
	height: 3px;
	background: #B9CEDF;
	margin: 0 auto 84px;
}

.section-about {
	padding-top: 120px;
	.next-sep {
		margin-bottom: 39px;
	}
	.section-title {
		margin-bottom: 68px;
	}
}

.partners {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto 65px;
	max-width: 1280px;
	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 1.7% 45px;
		background: #fff;
		border-radius: 30px;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
		width: 21.5%;
		height: 183px;
		box-shadow: 0 20px 25px 0 rgba(0,0,0,.06);
	}
}

.garanty {
	display: flex;
	justify-content: center;
	&__block {
		display: inline-block;
		width: 389px;
		color: #fff;
		border-radius: 100px;
		background: $pink;
		box-sizing: border-box;
		padding: 16px 45px;
		position: relative;
		&__text1 {
			font-size: 49px;
			line-height: 49px;
		}
		&__text2 {
			font-size: 34px;
			line-height: 34px;
		}
	}
	&__icon {
		position: absolute;
		right: -35px;
		top: -62px;
		width: 220px;
		height: 252px;
		background: url(../img/icon-garanty.svg) no-repeat;
		background-size: cover;
		text-align: center;
		box-sizing: border-box;
		padding-left: 25px;
		padding-top: 85px;
		&__text1 {
			display: block;
			font-size: 30px;
			line-height: 30px;
			margin-bottom: 0px;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -13px;
				width: 30px;
				height: 2px;
				background: #fff;
				opacity: .32;
			}
		}
		&__text2 {
			display: inline-block;
			font-size: 11px;
			line-height: 15px;
		}
	}
}

.section-how-work {
	padding-top: 50px;
	padding-top: 150px;
	.next-sep {
		margin-bottom: 55px;
	}
	.section-title {
		margin-bottom: 78px;
	}
}

.how-work {
	display: flex;
	justify-content: center;
	&__desc {
		width: 410px;
		margin-right: 30px;
	}
	&__img {
		width: 910px;
	}
	&__title {
		font-size: 35px;
		line-height: 45px;
		width: 560px;
		color: $blue2;
		margin-bottom: 10px;
	}
	&__inform {
		position: relative;
		padding: 47px 20px 0 0;
		&__icon {
			border-radius: 100%;
			box-shadow: none;
			width: 60px;
			height: 60px;
			margin-right: 30px;
			flex-shrink: 0;
		}
		&__text {
			color: $blue2;
			font-size: 18px;
			line-height: 24px;
		}
		&__item {
			margin-bottom: 52px;
			display: flex;
			align-items: center;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__bg-figure {
		position: absolute;
		right: 0;
		top: 0;
		width: 1186px;
		height: 1250px;
		background: url(../img/figure-4.svg) no-repeat;
		z-index: -1;
	}
}

.chat-example {
	display: flex;
	justify-content: center;
	position: relative;
	&__phone {
		width: 380px;
		height: 775px;
		position: relative;
		&>img {
			margin: -40px 0 0 -212px;
			max-width: none; 
		}
		&__socials {
			position: absolute;
			left: 0;
			top: 100px;
			width: 100%;
			display: flex;
			justify-content: center;
			&__item {
				margin: 0 5px;
			}
		}
	}
	&__message {
		display: flex;
		align-items: flex-end;
		margin-bottom: 40px;
		&__section {
			width: 665px;
			margin-right: 60px;
			position: relative;
		}
		&__name {
			font-size: 21px;
			color: $blue2;
			line-height: 26px;
			margin-bottom: 12px;
			padding-left: 58px;
		}
		&__main {
			background: #F2F8FC;			
			border: 1px solid #D0DCEB;
			padding: 50px;
			box-sizing: border-box;
			border-radius: 45px;
			font-size: 26px;
			line-height: 34px;
			color: $blue2;
		}
		&__icon {
			flex-shrink: 0;
			width: 114px;
			height: 114px;
			margin-bottom: 25px;
		}
		&__status {
			position: absolute;
			right: 50px;
			bottom: 15px;
			width: 24px;
			height: 14px;
			background: url(../img/msg-status.svg) no-repeat;
			background-size: cover;
			&_read {
				background: url(../img/msg-status_read.svg) no-repeat;
			}
		}
		&_variant2 {
			.chat-example {
				&__message {
					&__icon {
						order: -1;
					}
					&__section {
						margin-right: 0;
						margin-left: 60px;
					}
					&__main {
						background: #fff;
					}
					&__name {
						padding-left: 0;
						padding-right: 58px;
						text-align: right;
					}
				}
			}
		}
	}
	&__messages-wrap {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		max-width: 845px;
		top: 210px;
	}
}

.icon-top {
	display: flex;
	justify-content: center;
	margin-bottom: 35px;
	.icon-gradient-block {
		margin-right: 0;
	}
}

.section-easy {
	padding-top: 55px;
	.next-sep {
		margin-bottom: 42px;
	}
	.section-title {
		margin-bottom: 147px;
	}
}

.video-time {
	text-align: center;
	margin-bottom: 70px;
	&__icon {
		opacity: .43;
	}
	&__text {
		opacity: .43;
		color: $blue2;
		font-size: 18px;
		line-height: 24px;
	}
}

.section-video {
	padding-top: 152px;
	padding-bottom: 70px;
	.icon-top {
		margin-bottom: 75px;
	}
	.section-title {
		margin-bottom: 56px;
	}
}

.video {
	max-width: 980px;
	height: 551px;
	margin: 0 auto;
	background: #ccc;
	border-radius: 50px;
	overflow: hidden;
	position: relative;
	iframe {
		border: 0;
		width: 100%;
		height: 100%;
	}
	&__overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		img {
			max-width: 980px;
		}
		&__play {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 183px;
			height: 183px;
			background: rgba(255,255,255,.74);
			border-radius: 100%;
			&:before {
				content: "";
				position: absolute;
				left: 55%;
				top: 50%;
				transform: translate(-50%,-50%);
				width: 51px;
				height: 62px;
				background: url(../img/icon-play.svg);
			}
		}
	}
}

.branches {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -40px;
	&__item {
		margin: 0 40px 66px;
		width: 247px;
		&__block {
			background: #fff;
			border-radius: 20px;
			box-shadow: 0 3px 30px 0 rgba(111,109,109,.05);
			height: 96px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 18px;
			line-height: 24px;
			color: $blue2;
		}
		&__icon {
			width: 73px;
			height: 73px;
			margin: 0 auto -22px;
			border-radius: 100%;
			position: relative;
			z-index: 5;
		}
	}
}

.section-branches {
	padding-top: 110px;
	.next-sep {
		margin-bottom: 35px;
	}
	.section-title {
		margin-bottom: 56px;
	}
	.container {
		max-width: 920px;
	}
	&__illustration {
		text-align: center;
		margin-bottom: 26px;
	}
	&__main {
		background: #F0F4F8;
		padding-top: 23px;
		padding-bottom: 15px;
	}
}

.icon-ofsset-spec {
	margin: 0 0 0 -9px;
}

.subtitle {
	text-align: center;
	max-width: 690px;
	margin: 0 auto;
	font-size: 25px;
	line-height: 33px;
	color: $blue2;
	margin-bottom: 100px;
}

.section-advantages {
	padding-top: 55px;
	.container {
		max-width: 1490px;
	}
	.next-sep {
		margin-bottom: 14px;
	}
	.section-title {
		margin-bottom: 54px;
	}
}

.advantages {
	display: flex;
	flex-wrap: wrap;
	&__item {
		width: 28.8%;
		min-height: 399px;
		margin: 0 2.2% 80px;
		padding: 31px 20px 28px;
		box-sizing: border-box;
		background: #fff;
		border-radius: 30px;
		box-shadow: 0 3px 30px 0 rgba(0,0,0,.05);
		&__line {
			width: 159px;
			height: 4px;
			background: $blue2;
			opacity: .13;
			margin: 0 auto 30px;
		}
	}
	&__icon {
		text-align: center;
		margin-bottom: 18px;
	}
	&__title {
		font-size: 19px;
		line-height: 25px;
		color: $blue2;
		font-weight: 700;
		text-align: center;
		margin-bottom: 38px;
	}
	&__text {
		font-size: 23px;
		line-height: 30px;
		color: $blue2;
		text-align: center;
		padding-left: 30px;
		padding-right: 30px;
	}
}

.work-for-you {
	width: 790px;
	height: 790px;
	margin: 0 auto;
	position: relative;
	&__ellips {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 60px solid rgba(255,255,255,1);
		box-sizing: border-box;
		border-radius: 100%;
		box-shadow: 0 0 100px 0 rgba(38,153,251,.53), inset 0 0 150px 0 rgba(38,153,251,.53);
	}
	&__illustration {
		position: absolute;
		left: 50%;
		width: 430px;
		top: 125px;
		transform: translateX(-50%);
		img {
			width: 100%;
		}
		&__title {
			text-align: center;
			color: $blue2;
			font-weight: 700;
			font-size: 13px;
			line-height: 17px;
			margin-top: -30px;
		}
	}
	&__item {
		position: absolute;
		width: 218px;
		height: 218px;
		border: 10px solid;
		box-sizing: border-box;
		box-shadow: 0 0 50px 0 rgba(0,0,0,.07);
		border-radius: 100%;
		background: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 15px;
		text-align: center;
		font-size: 15px;
		font-weight: 700;
		line-height: 20px;
		color: $blue2;
		&__sep {
			margin: 20px auto;
			width: 55px;
			height: 4px;
			background: $blue2;
			opacity: .13;
		}
		&:after {
			content: "";
			position: absolute;
			right: 8px;
			top: 15px;
			width: 60px;
			height: 86px;
		}
		&_pos1 {
			left: 50%;
			transform: translateX(-50%);
			top: -72px;
		}
		&_pos2 {
			right: 30px;
			top: 23px;
		}
		&_pos3 {
			right: -73px;
			top: 50%;
			transform: translateY(-50%);
		}
		&_pos4 {
			right: 30px;
			bottom: 45px;
		}
		&_pos5 {
			left: 50%;
			transform: translateX(-50%);
			bottom: -72px;
		}
		&_pos6 {
			left: 30px;
			bottom: 45px;
		}
		&_pos7 {
			left: -73px;
			top: 50%;
			transform: translateY(-50%);
		}
		&_pos8 {
			left: 30px;
			top: 23px;
		}

		&_blue-light {
			border-color: #ACD9FF;
			&:after {
				background-image: url(../img/elem-line-dot_blue-light.svg);
			}
		}
		&_gray {
			border-color: #B9CEDF;
			&:after {
				background-image: url(../img/elem-line-dot_gray.svg);
			}
		}
		&_pink-light {
			border-color: #FFB4BC;
			&:after {
				background-image: url(../img/elem-line-dot_pink-light.svg);
			}
		}
		&_blue {
			border-color: $blue;
			&:after {
				background-image: url(../img/elem-line-dot_blue.svg);
			}
		}
		&_pink {
			border-color: $pink;
			&:after {
				background-image: url(../img/elem-line-dot_pink.svg);
			}
		}
		&_blue-light {
			border-color: #ACD9FF;
			&:after {
				background-image: url(../img/elem-line-dot_blue-light.svg);
			}
		}
		&_blue-light {
			border-color: #ACD9FF;
			&:after {
				background-image: url(../img/elem-line-dot_blue-light.svg);
			}
		}
	}
}

.section-work-for-you {
	padding-top: 40px;
	.next-sep {
		margin-bottom: 55px;
	}
	.section-title {
		margin-bottom: 145px;
	}
	&__btn {
		margin-top: 138px;
		text-align: center;
	}
}

.you-get {
	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 63px;
		&:last-child {
			margin-bottom: 0;	
		}
		&__img {
			width: 280px;
			text-align: center;
			flex-shrink: 0;
			margin-right: 70px;
		}
		&__icon {
			width: 49px;
			height: 49px;
			background: $blue;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0 5px 15px 0 rgba(0,0,0,.26);
			margin-right: 60px;
			flex-shrink: 0;
		}
		&__text {
			color: $blue2;
			font-size: 25px;
			line-height: 33px;
			width: 711px;
		}
		&_img-only {
			justify-content: flex-start;
		}
		&_spec {
			align-items: flex-end;
			.you-get {
				&__item {
					&__text {
						margin-bottom: 40px;
					}
					&__icon {
						margin-bottom: 40px;
					}
				}
			}
		}
	}

}

.section-you-get {
	padding-top: 190px;
	max-width: 1170px;
	.section-title {
		margin-bottom: 64px;
	}
	.next-sep {
		margin-bottom: 32px;
	}
	&__btn {
		text-align: center;
		margin-top: 25px;
	}


}

.section-tariffs {
	padding-top: 80px;
	.next-sep {
		margin-bottom: -20px;
		&__line {
			background: $blue2;
			opacity: .33;
		}
	}
	&__main {
		background: #ACD9FF;
		padding-top: 67px;
		padding-bottom: 55px;
		.section-title {
			margin-bottom: 58px;
		}
	}
	.container {
		max-width: 1270px;
	}

}

.tariffs {
	display: flex;
	justify-content: space-between;
	margin: 0 -22px 47px;
	&__item {
		width: 21.5%;
		margin: 0 1.7% 50px;
		&__top {
			background: #fff;
			border-radius: 12px;
			box-shadow: 0 3px 50px 0 rgba(0,0,0,.17);
			height: 107px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 24px;
			line-height: 28px;
			color: $blue2;
			font-weight: 700;
			position: relative;
			margin-bottom: 6px;
			&__btn {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: -20px;
			}
		}
		&__main {
			background: #fff;
			border-radius: 12px;
			box-shadow: 0 3px 50px 0 rgba(0,0,0,.17);
		}
		&__section {
			padding: 30px 45px 34px;
		}
		&__section-price {
			background: $blue;
			padding: 16px 20px 28px;
			color: #fff;
			text-align: center;
			border-radius: 0 0 12px 12px;
		}
		&__list {
			list-style: none;
			font-size: 18px;
			line-height: 24px;
			color: $blue2;
			margin-bottom: 18px;
			&>li {
				padding-left: 25px;
				position: relative;
				margin-bottom: 8px;
				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 3px;
					width: 16px;
					height: 16px;
					background: url(../img/icon-check.svg);
					background-size: cover;
				}
			}
			&_without-icon {
				&:before {
					display: none;
				}
			}
		}
		&__price {
			font-size: 33px;
			line-height: 33px;
			font-weight: 700;
			border-bottom: 3px solid rgba(231,243,252,.07);
			padding-bottom: 10px;
			margin-bottom: 6px;
		}
		&__price-text {
			color: #ACD9FF;
			font-size: 18px;
			font-weight: 700;
		}

	}
}

.text-btn {
	display: flex;
	justify-content: center;
	&__text {
		font-size: 28px;
		line-height: 28px;
		color: $blue2;
		font-weight: 700;
		margin-right: 30px;
	}
}

.form-connect {
	max-width: 1044px;
	margin: 0 auto;
	border: 1px solid #9FD2FC;
	background: #fff;
	border-radius: 40px;
	padding: 49px 40px 57px;
	box-sizing: border-box;
	&__title {
		text-align: center;
		font-size: 26px;
		line-height: 34px;
		color: $blue2;
		margin-bottom: 54px;
	}
	&__block {
		margin-bottom: 52px;
	}
	&__btn {
		text-align: center;
		margin-top: 65px;
	}
	&__field-title {
		text-align: center;
		font-size: 25px;
		line-height: 33px;
		color: $blue2;
		margin-bottom: 5px;
	}
	&__field {
		max-width: 562px;
		margin: 0 auto;
		input {
			border: 1px solid #B9CEDF;
			font-size: 18px;
			border-radius: 4px;
			height: 52px;
			width: 100%;
			box-sizing: border-box;
			padding: 0 20px;
		}
	}
}

.section-connect {
	padding-top: 130px;
	.section-title {
		margin-bottom: 100px;
	}
}

.app {
	display: flex;
	justify-content: center;
	&__col {
		width: 550px;
		margin-right: 35px;
		padding-top: 15px;
		padding-bottom: 50px;
	}
	&__logo-wrap {
		max-width: 450px;
	}
	&__title {
		color: $blue2;
		font-weight: 700;
		font-size: 44px;
		line-height: 50px;
		margin-bottom: 25px;
	}
	&__title2 {
		color: $blue2;
		font-size: 30px;
		line-height: 39px;
		margin-bottom: 65px;
	}
	&__img {
		width: 544px;
	}
	&__btns {
		max-width: 450px;
		display: flex;
		justify-content: space-between;
	}
	&__block-logo {
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		margin: 0 auto 60px;
		&:after {
			display: none;
		}
	}
}

.section-app {
	padding-top: 263px;
}

.section-mass-media {
	background: #fff;
	padding-top: 198px;
	padding-bottom: 180px;
	.section-title {
		margin-bottom: 130px;
	}
}

.mass-media {
	display: flex;
	&__item {
		width: 330px;
		padding-left: 40px;
		padding-right: 40px;
		box-sizing: border-box;
		margin: 0 85px 10px;
		transition: .4s;
		&__logo {
			border-bottom: 3px solid #DEE4ED;
			height: 52px;
			text-align: center;
		}
		&__text {
			font-size: 22px;
			line-height: 29px;
			color: #B3C3D9;
			padding-top: 50px;
			padding-left: 10px;
			padding-right: 10px;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				left: -74px;
				top: 0px;
				width: 97px;
				height: 63px;
				background: url(../img/quote-1.svg);
			}
			&:after {
				content: "";
				position: absolute;
				right: -74px;
				bottom: -28px;
				width: 97px;
				height: 63px;
				background: url(../img/quote-2.svg);
			}
		}
		&.zindex2 {
			opacity: .17;
		}
		&.zindex3 {
			opacity: .17;
		}
		&.zindex4 {
			opacity: .17;
		}
	}

	.slick-arrow {
		position: absolute;
		bottom: -160px;
		width: 58px;
		height: 58px;
		background: transparent;
		font-size: 0;
	}
	.slick-prev {
		left: 37%;
		background-image: url(../img/arrow-left.svg);
		background-repeat: no-repeat;
		background-position: center;
	}
	.slick-next {
		right: 37%;
		background-image: url(../img/arrow-right.svg);
		background-repeat: no-repeat;
		background-position: center;
	}
}

.reviews {
	display: flex;
	margin-top: -20px;
	margin-bottom: -20px;
	&__item {
		width: 600px;
		padding: 30px 30px 50px;
		margin: 60px 0;
		box-sizing: border-box;
		background: #fff;
		border-radius: 50px;
		box-shadow: 0 3px 60px 0 rgba(0,0,0,.21);
		transform: scale(.92);
		bottom: 5px;
		transform-origin: center bottom;
		opacity: .6;
		margin-left: -85px;
		margin-right: -85px;
		z-index: 0;
		position: relative;
		&.slick-center {
			transform: scale(1);
			opacity: 1;
			z-index: 10;
			position: relative;
			bottom: 0;
		}
		&.zindex1 {
			opacity: .6;
		}
		&.zindex2 {
			z-index: -1;
			opacity: .23;
		}
		&.zindex3 {
			z-index: -2;
			opacity: .23;
		}
		&.zindex4 {
			z-index: -3;
			opacity: .23;
		}
	}
	&__head {
		display: flex;
		margin-bottom: 85px;
		position: relative;
		&__main {
			position: relative;
			box-sizing: border-box;
			padding-top: 30px;
			min-height: 138px;
			&:after {
				content: "";
				position: absolute;
				left: 30%;
				bottom: 0;
				width: 80px;
				height: 2px;
				background: #DEE4ED;
			}
		}
	}
	&__author {
		color: $blue2;
		margin-bottom: 34px;
		font-weight: 700;
		font-size: 25px;
		line-height: 30px;
	} 
	&__desc {
		font-size: 18px;
		line-height: 24px;
		font-style: italic;
		color: $blue2;
	}
	&__text {
		font-size: 22px;
		line-height: 29px;
		padding-left: 60px;
		padding-right: 20px;
		color: $blue2;
	}
	&__photo {
		width: 157px;
		height: 157px;
		overflow: hidden;
		border-radius: 100%;
		margin-right: 30px;
		flex-shrink: 0;
	}

	.slick-arrow {
		position: absolute;
		bottom: -55px;
		width: 58px;
		height: 58px;
		background: #fff;
		border-radius: 4px;
		font-size: 0;
	}
	.slick-prev {
		left: 37%;
		background-image: url(../img/arrow-left.svg);
		background-repeat: no-repeat;
		background-position: center;
	}
	.slick-next {
		right: 37%;
		background-image: url(../img/arrow-right.svg);
		background-repeat: no-repeat;
		background-position: center;
	}
}

.section-reviews {
	padding-top: 144px;
	padding-bottom: 200px;
	.section-title {
		font-size: 35px;
		line-height: 42px;
		margin-bottom: 20px;
	}
	.next-sep {
		margin-bottom: 51px;
	}
}







.footer {
	background: $blue;
	color: #fff;
	padding-top: 66px;
	padding-bottom: 66px;
	.container {
		display: flex;
		justify-content: space-between;
		max-width: 1560px;
	}
	&__section1 {
		display: flex;
		width: 1090px;
	}
	&__logotypes {
		width: 161px;
		margin-right: 135px;
	}
	&__logo {
		padding-bottom: 2px;
		margin-bottom: 5px;
		border-bottom: 1px solid rgba(255,255,255,.8);
	}
	&__logo2 {
		text-align: center;
	}
	&__menu {
		list-style: none;
		columns: 2;
		width: 480px;
		margin-right: 30px;
		&>li {
			margin-bottom: 4px;
			&>a {
				text-decoration: none;
				color: #fff;
				text-decoration: none;
			}
		}
	}
	&__socials {
		display: flex;
		align-items: flex-start;
		padding-top: 00px;
	}
}

.subscribe {
	width: 285px;
	&__form {
		position: relative;
		margin-bottom: 15px;
		input[type="text"] {
			height: 40px;
			width: 100%;
			box-sizing: border-box;
			padding: 0 20px;
			border: 2px solid #fff;
			border-radius: 6px;
			background: transparent;
			color: #fff;
		}
		::-webkit-input-placeholder {
		  color: rgba(255,255,255,.5);
		}

		::-moz-placeholder {
		  color: rgba(255,255,255,.5);
		}

		:-moz-placeholder {
		  color: rgba(255,255,255,.5);
		}

		:-ms-input-placeholder {
		  color: rgba(255,255,255,.5);
		}
	}
	&__text {
		font-size: 13px;
	}
}

.vis-mob {
	display: none;
}

.popup {
    opacity: 0;
    visibility: hidden;
    transition: .5s;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 150;
    &__mask {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.5);
    }
    &__wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
    }
    &__container {
        position: relative;
        z-index: 20;
        display: inline-block;
        box-sizing: border-box;
        background: $blue;
        width: 100%;
        max-width: 367px;
        border-radius: 20px;
        margin-top: 80px;
        margin-bottom: 80px;
        transition: .5s;
        text-align: center;
        color: #fff;
        padding: 34px;
    }
    &__icon {
    	margin-bottom: 16px;
    }
    &__desc {
    	margin-bottom: 22px;
    }
    &__title {
    	text-transform: uppercase;
    	font-size: 13px;
    	line-height: 14px;
    	margin-bottom: 5px;
    	font-weight: 700;
    }
    &__btn {
    	.btn {
    		width: 100%;
    	}
    }
    &__field {
    	margin-bottom: 20px;
    	input {
    		width: 100%;
    		box-sizing: border-box;
    		border-radius: 30px;
    		font-size: 15px;
    		background: #fff;
    		height: 45px;
    		padding-left: 20px;
    		padding-right: 20px;
    		box-shadow: 0 0 12px 0 rgba(0,0,0,.1);
    	}
    }
    &.active {
        opacity: 1;
        visibility: visible;
    }
    &__close {
        position: absolute;
        right: 23px;
        top: 23px;
        cursor: pointer;
        transition: .3s;
        &:before {
            content: "";
            display: inline-block;
            color: #fff;
            width: 22px;
            height: 22px;
            background: url(../img/icon-cross.svg);
            transition: .3s;
        }
    }
    ::-webkit-input-placeholder {
	  color: #B9CEDF;
	}

	::-moz-placeholder {
	  color: #B9CEDF;
	}

	:-moz-placeholder {
	  color: #B9CEDF;
	}

	:-ms-input-placeholder {
	  color: #B9CEDF;
	}

}

.popup-opened {
	overflow: hidden;
	.wrapper {
		* {
			filter: blur(5px);
		}
	}
}

.win-enter {
	color: #fff;
	background: $blue;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	visibility: hidden;
	opacity: 0;
	z-index: 300;
	transition: .4s;
	&__top {
		background: $pink;
		position: relative;
		text-align: center;
		height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		.btn_back {
			position: absolute;
			left: 35px;
			top: 10px;
		}
	}
	&__logo {
		width: 220px;
		margin: 0 auto 100px;
	}
	&__field {
		width: 327px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 35px;
		input {
			width: 100%;
			height: 48px;
			box-sizing: border-box;
			background: #fff;
			border-radius: 8px;
			padding-left: 20px;
			padding-right: 20px;
			font-size: 15px;
		}
		::-webkit-input-placeholder {
		  color: #ACD9FF;
		}

		::-moz-placeholder {
		  color: #ACD9FF;
		}

		:-moz-placeholder {
		  color: #ACD9FF;
		}

		:-ms-input-placeholder {
		  color: #ACD9FF;
		}
	}
	&__main {
		text-align: center;
		padding-top: 40px;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 40px;
	}
	&__btn {
		width: 327px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 80px;
		.btn {
			height: 48px;
			width: 100%;
			font-size: 21px;
			line-height: 21px;
			border-radius: 40px;
		}
	}
	&.active {
		opacity: 1;
		visibility: visible;
	}
}

.icon-user {
	display: inline-block;
	background: #fff;
	border-radius: 100%;
	position: relative;
	width: 32px;
	height: 32px;
	margin-bottom: 32px;
	&:before {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 17px;
		height: 17px;
		background: url(../img/icon-user_blue.svg);
	}
}

.win-mob-menu {
	position: fixed;
	left: 0;
	top: 0;
	width: 233px;
	padding: 55px 32px 20px;
	height: 100%;
	background: $blue;
	box-sizing: border-box;
	overflow-y: auto;
	visibility: hidden;
	opacity: 0;
	z-index: 300;
	transition: .4s;
	box-shadow: 3px 0 6px 0 rgba(0,0,0,.16);
	border-radius: 0 15px 15px 0;
	transform: translateX(-100%);
	&.active {
		opacity: 1;
		visibility: visible;
		transform: translateX(0);
	}
	&__close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        transition: .3s;
        &:before {
            content: "";
            display: inline-block;
            color: #fff;
            width: 22px;
            height: 22px;
            background: url(../img/icon-cross.svg);
            transition: .3s;
        }
    }
    &__logo {
    	width: 134px;
    	padding-bottom: 25px;
    	margin-bottom: 25px;
    	border-bottom: 1px solid #fff;
    	.page-title {
    		padding-bottom: 0;
    		margin-bottom: 0;
    	}
    }
    &__nav {
    	list-style: none;
    	margin-bottom: 50px;
    	&>li {
    		margin-bottom: 25px;
    		&:last-child {
    			margin-bottom: 0;	
    		}
    		&>a {
    			color: #fff;
    			font-size: 17px;
    			text-decoration: none;
    		}
    	}
    }
    &__phone {
    	margin-bottom: 30px;
    	a {
    		text-decoration: none;
    		color: #fff;
    		font-size: 12px;
    	}
    }
    &__socials {
    	text-align: center;
    	margin-bottom: 10px;
    }
}

.win-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.48);
	z-index: 250;
	opacity: 0;
	visibility: hidden;
	transition: .4s;
	&.active {
		opacity: 1;
		visibility: visible;
	}
}

.wow {
	visibility: hidden;
}




/*page blog*/

.breadcrumbs {
	padding-bottom: 20px;
	color: $blue2;
	padding-left: 60px;
	a {
		text-decoration: none;
		&:after {
			content: "/";
			pointer-events: none;
			cursor: default;
			margin-right: -4px;
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}

.page-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.page-top-bar {
	display: flex;
	align-items: flex-start;
}

.page-title {
	width: 360px;
	box-sizing: border-box;
	font-size: 59px;
	line-height: 59px;
	font-weight: 700;
	color: #fff;
	position: relative;
	padding: 74px 0 30px 60px;
	margin-bottom: 25px;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 487px;
		height: 459px;
		background: url(../img/figure-1.svg) no-repeat;
		background-size: cover;
		z-index: -1;
	}
}

.page-title-symbol {
	margin-left: -15px;
}

.top-links {
	a {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: 700;
		height: 44px;
		padding: 0 40px;
		text-decoration: none;
		border: 1px solid transparent;
		border-radius: 30px;
		&:hover {
			border-color: $blue2;				
		}
	}
}

.search-block {
	width: 375px;
	position: relative;
	margin-left: 45px;
	&__field {
		input {
			border-radius: 30px;
			border: 1px solid $blue2;
			border-radius: 30px;
			height: 44px;
			padding: 0 15px 0 70px;
			width: 100%;
			box-sizing: border-box;
			font-size: 12px;
		}
		::-webkit-input-placeholder {
		  color: #B9CEDF;
		}

		::-moz-placeholder {
		  color: #B9CEDF;
		}

		:-moz-placeholder {
		  color: #B9CEDF;
		}

		:-ms-input-placeholder {
		  color: #B9CEDF;
		}
	}
}

.page-preview-img {
	position: relative;
	max-width: 1295px;
	display: flex;
	justify-content: flex-end;
	img {
		max-width: 2000px;
	}
	&__tags {
		position: absolute;
		right: 75px;
		top: 40px;
	}
}

.btns-tag {
	display: flex;
	flex-wrap: wrap;
	a {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin: 0 2px 0 5px;
		font-size: 11px;
		line-height: 11px;
		height: 26px;
		border-radius: 20px;
		padding: 0 10px;
		text-decoration: none;
		font-weight: 700;
		min-width: 82px;
		&:last-child {
			margin-right: 0;	
		}
	}
	&_white {
		a {
			border: 1px solid #fff;
			color: #fff;
		}
	}

	&_theme2 {
		a {
			font-size: 17px;
			color: #B9CEDF;
			height: 45px;
			padding-left: 20px;
			padding-right: 20px;
			margin: 0 10px 20px;
			border: 1px solid #B9CEDF;
			border-radius: 30px;
			font-weight: 700;
			&:hover {
				background: $blue;
				color: #fff;
			}
		}
	}

	&_size-large {
		a {
			font-size: 17px;
			height: 45px;
			padding-left: 20px;
			padding-right: 20px;
			margin: 0 10px 20px;
			border-radius: 30px;
			font-weight: 700;
		}
	}

	&_bottom {
		margin-top: 100px;
		margin-left: 275px;
		margin-bottom: 20px;
	}

	&_offset {
		margin-bottom: 50px;
	}
}

.article-author {
	font-size: 15px;
	line-height: 19px;
	color: $blue2;
	display: flex;
	align-items: center;
	&__photo {
		border-radius: 100%;
		overflow: hidden;
		width: 60px;
		height: 60px;
		line-height: 0;
		margin-right: 20px;
	}
	&_end {
		font-size: 10px;
		border-top: 1px solid #D4E1EB;
		margin-left: -75px;
		margin-right: -75px;
		padding-left: 75px;
		padding-right: 75px;
		padding-top: 30px;
	}
	&__name {
		border-bottom: 1px solid $blue2;
		font-size: 17px;
		line-height: 20px;
		padding-bottom: 4px;
		margin-bottom: 4px;
	}
}

.article-wrapper {
	color: $blue2;
	position: relative;
	padding-top: 47px;
	p {
		font-size: 25px;
		line-height: 33px;
		margin-bottom: 30px;
		&:last-child {
			margin-bottom: 0;	
		}
	}

	blockquote {
		background: #F0F4F8;
		padding: 40px 60px;
		color: $blue2;
		font-size: 29px;
		line-height: 38px;
		font-weight: 700;
		margin-bottom: 30px;
	}
}

.article-row {
	display: flex;
}

.blockquote-main {
	border-left: 3px solid #B9CEDF;
	padding-left: 25px;
}

.article-top-desc {
	font-size: 22px;
	line-height: 29px;
	font-weight: 700;
	margin-bottom: 22px;
	padding-bottom: 25px;
	position: relative;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 205px;
		height: 1px;
		background: #D4E1EB;
	}
}

.first-letter {
	display: inline-block;
	font-size: 90px;
	line-height: 90px;
	margin-right: 25px;
}

.paragraph-spec {
	display: flex;
	align-items: center;
}

.paragraph-main {
	display: inline-block;
}

.text-block {
	background: #fff;
	margin: 0 40px 30px;
	background: #fff;
	max-width: 714px;
	border: 1px solid #B9CEDF;
	padding: 25px 55px 25px;
}

.article-img {
	max-width: 793px;
	box-sizing: border-box;
	margin: 0 auto 50px;
	padding: 45px 45px 20px;
	border-radius: 15px;
	background: #DEE4ED;
	&__img {
		border-radius: 12px;
		overflow: hidden;
		line-height: 0;
	}
	&__desc {
		font-size: 17px;
		line-height: 26px;
		color: #8B9DAF;
		border-top: 2px solid #B9CEDF;
		padding-top: 15px;
		margin-top: 20px;
		padding-left: 30px;
		padding-right: 30px;
		margin-left: -10px;
		margin-right: -10px;
	}
}

.list-info {
	list-style: none;
	font-size: 18px;
	line-height: 24px;
	width: 45%;
	margin: 0 35px 50px;
	&>li {
		border-bottom: 1px solid #DEE4ED;
		display: flex;
		justify-content: space-between;
		min-height: 45px;
		align-items: center;
	}
}

.list-info-top {
	border-bottom: 1px solid #B9CEDF;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 45px;
	font-size: 18px;
	line-height: 24px;
	width: 370px;
	margin-bottom: 15px;
}

.list-info-wrap {
	&__row {
		display: flex;
		margin: 0 -35px;

	}
}

.table-def {
	width: 100%;
	border-width: 5px;
	border-collapse: collapse;
	th {
		background: #B9CEDF;
		height: 40px;
		padding: 10px;
		border: 5px solid #F9FBFC;
	}
	td {
		text-align: center;
		padding: 10px;
		background: #F0F4F8;
		border: 5px solid #F9FBFC;
	}
}

.list-circle {
	font-size: 29px;
	line-height: 39px;
	list-style: none;
	margin-bottom: 30px;
	margin-left: 60px;
	&>li {
		position: relative;
		padding-left: 37px;	
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 13px;
			width: 12px;
			height: 12px;
			background: $blue;
			border-radius: 100%;
		}
	}
}

.list-check {
	font-size: 29px;
	line-height: 39px;
	margin-bottom: 30px;
	margin-left: 60px;
	list-style: none;
	&>li {
		position: relative;
		padding-left: 37px;	
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 15px;
			width: 12px;
			width: 14px;
			height: 12px;
			background: url(../img/icon-check2.svg) no-repeat;
			background-size: cover;
		}
	}
}

.list-numeric {
	font-size: 29px;
	line-height: 39px;
	list-style: none;
	margin-bottom: 30px;
	margin-left: 60px;
	&>li {
		position: relative;
		padding-left: 37px;	
	}
	&__num {
		position: absolute;
		left: -4px;
		top: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 31px;
		height: 31px;
		border-radius: 100%;
		background: $blue;
		font-size: 17px;
		line-height: 17px;
		color: #fff;
	}
}

.comments-wrapper {
	background: #F0F4F8;
	color: $blue2;
	padding: 40px 40px 15px;
}

.comment-item {
	.comment-item {
		padding-left: 80px;

	}
	&__info {
		display: flex;
		align-items: center;
		&__author {
			font-size: 15px;
			font-weight: 700;
			margin-right: 15px;
		}
		&__date {
			font-size: 11px;
			line-height: 14px;
		}
		&__ava {
			width: 58px;
			height: 58px;
			border-radius: 12px;
			margin-right: 15px;
		}
	}
	&__text {
		font-size: 25px;
		line-height: 33px;
	}
	&__main {
		margin-left: 80px;
		border-bottom: 1px solid #D4E1EB;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}
	&__textarea {
		margin-bottom: 15px;
		margin-top: 25px;
		textarea {
			width: 100%;
			box-sizing: border-box;
			padding: 10px 20px;
			border: 1px solid #B9CEDF;
			border-radius: 5px;
			height: 85px;
		}
		::-webkit-input-placeholder {
		  color: #DEE4ED;
		}

		::-moz-placeholder {
		  color: #DEE4ED;
		}

		:-moz-placeholder {
		  color: #DEE4ED;
		}

		:-ms-input-placeholder {
		  color: #DEE4ED;
		}
	}
	&__public-btn {
		text-align: right;
	}
}

.comment-answer-area {
	margin-top: 45px;
}

.comments-lvl2 {
	padding-left: 80px;
}

.block-wrapper {
	background: #fff;
	color: $blue2;
	box-shadow: 0 3px 20px 0 rgba(0,0,0,.06);
	border-radius: 10px;
	padding: 30px;
	margin-bottom: 30px;
	overflow: hidden;
	&__title {
		text-align: center;
		font-size: 31px;
		line-height: 31px;
		color: $blue2;
		border-bottom: 2px solid #D4E1EB;
		padding: 20px 0;
		margin-bottom: 30px;
	}

	&_side {
		padding: 30px;
		margin-bottom: 40px;
		.block-wrapper {
			&__title {
				font-size: 25px;
				line-height: 32px;
				padding-top: 0;
				margin-bottom: 30px;
			}
		}
	}
}

.news-slider {
	margin: 0 -45px;
	&_mod2 {
		.item-news {
			margin: 0 30px;
		}
		&.slider-theme {
			.slick-prev {
				left: 0;
			}
			 .slick-next {
			 	right: 0;
			 }
		}
	}
}

.slider-theme {
	.slick-arrow {
		position: absolute;
		width: 38px;
		height: 38px;
		font-size: 0;
		background-color: #fff;
		border-radius: 10px;
		z-index: 100;
	}
	.slick-prev {
		top: -90px;
		left: 50px;
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 16px;
			height: 30px;
			background: url(../img/arrow-left.svg) center no-repeat;
			background-size: cover;
		}
	}
	.slick-next {
		top: -90px;
		right: 50px;
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 16px;
			height: 30px;
			background: url(../img/arrow-right.svg) center no-repeat;
			background-size: cover;
		}
	}

	.slick-dots {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		list-style: none;
		font-size: 0;
		li {
			margin: 0 7px;
			button {
				width: 10px;
				height: 10px;
				border-radius: 100%;
				background: #B9CEDF;
			}
			&.slick-active {
				button {
					background: $blue2;
				}
			}
		}
	}
}

.item-news {
	width: 340px;
	max-width: 100%;
	margin: 0 45px;
	&__title {
		font-size: 23px;
		line-height: 30px;
		font-weight: 700;
		border-bottom: 1px solid #D4E1EB;
		padding-bottom: 10px;
		margin-bottom: 20px;
		a {
			text-decoration: none;
		}
	}
	&__desc {
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 10px;
	}
	&__img {
		flex-shrink: 0;
		border-radius: 15px;
		overflow: hidden;
		line-height: 0;
		width: 159px;
	}
	&__main {
		display: flex;
		justify-content: space-between;
	}
	&__col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 15px;
	}

	&_row {
		width: 100%;
		.item-news {
			&__col {
				justify-content: center;
			}
			&__img {
				margin-right: 25px;
				max-width: 190px;
			}
			&__title {
				font-size: 20px;
				line-height: 26px;
				border-bottom: 0;
				margin-bottom: 15px;
			}
		}
	}

	&_side {
		border-bottom: 2px solid #D4E1EB;
		padding-bottom: 25px;
		margin-bottom: 25px;
		width: auto;
		margin-left: 0;
		margin-right: 0;
		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
			margin-bottom: 0;		
		}
		.item-news {
			&__title {
				font-size: 17px;
				line-height: 22px;
				border-bottom: 0;
			}
			&__img {
				width: 116px;
				height: 116px;
			}
			&__col {
				margin-right: 20px;
			}
		}
	}

	&_news {
		border-bottom: 2px solid #D4E1EB;
		padding-bottom: 25px;
		margin-bottom: 25px;
		margin-left: 0;
		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
			margin-bottom: 0;		
		}
		.item-news {
			&__title {
				font-size: 17px;
				line-height: 22px;
				border-bottom: 0;
				margin-bottom: 5px;
				min-height: 44px;
			}
		}
		.link-icon {
			font-size: 10px;
			line-height: 14px;
		}
	}


}

.news-row-wrap {
	display: flex;
	align-items: center;
	border-top: 2px solid #DEE4ED;
	border-bottom: 2px solid #DEE4ED;
	margin-left: -80px;
	padding-left: 80px;
	margin-right: -80px;
	padding-right: 30px;
	margin-top: 70px;
	margin-bottom: 70px;
	padding-top: 15px;
	padding-bottom: 15px;
	&>.title {
		font-size: 20px;
		line-height: 26px;
		flex-shrink: 0;
		margin-right: 50px;
	}
}

.table-wrap {
	margin-top: 70px;
	margin-bottom: 70px;
}

.icon {
	display: inline-block;
	line-height: 0;
	&-more {
		width: 16px;
		height: 16px;
		background: url(../img/icon-more.svg) no-repeat;
	}
	&-clock {
		width: 13px;
		height: 13px;
		background: url(../img/icon-clock3.svg) no-repeat;
	}
	&-comments {
		width: 25px;
		height: 25px;
		background: url(../img/icon-comment.svg) no-repeat;
	}
	&-arrow-bot {
		width: 23px;
		height: 12px;
		background: url(../img/icon-arrow-bot.svg) no-repeat;
	}
}

.link-icon {
	text-decoration: none;
	display: flex;
	align-items: center;
	&__text {
		text-decoration: underline;
	}
	.icon {
		margin-right: 10px;
	}
	&:hover {
		.link-icon {
			&__text {
				text-decoration: none;
			}
		}
	}
}

.news-slider2 {
	margin: 0 -15px 35px;
	.slick-prev {
		top: auto;
		bottom: -7px;
		left: 30px;
	}
	.slick-next {
		top: auto;
		bottom: -7px;
		right: 30px;
	}
	.item-news2 {
		&__details {
			width: 100%;
			height: 100%;
			&__section {
				height: 250px;
			}
			&__title {
				font-size: 18px;
				line-height: 24px;
			}
		}
	}
}

.item-news2 {
	width: 403px;
	padding: 0 15px 20px;
	position: relative;
	&__inner {
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 3px 20px 0 rgba(0,0,0,.2);
		color: $blue2;
		position: relative;
	}
	&__img {
		display: block;
		border-radius: 10px;
		height: 234px;
		overflow: hidden;
		line-height: 0;
		position: relative;
		img {
			width: 100%;
			min-height: 100%;
			max-width: 800px;
		}
	}
	&__title {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		z-index: 10;
		font-size: 25px;
		line-height: 28px;
		font-weight: 700;
		color: #fff;
		padding: 40px 30px 30px 60px;
		background: rgba(42,95,141,.7);
	}
	&__desc {
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 15px;
		min-height: 54px;
	}
	&__section {
		padding: 30px 60px;
	}

	&:hover {
		.item-news2__details {
			opacity: 1;
			visibility: visible;
		}
	}

	&__details {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		background: $blue;
		border-radius: 6px;
		box-shadow: 0 3px 20px 0 rgba(0,0,0,.3);
		color: #fff;
		width: calc(100% + 50px);
		height: calc(100% + 80px);
		z-index: 120;
		opacity: 0;
		visibility: hidden;
		transition: .5s;
		&__title {
			font-size: 25px;
			line-height: 28px;
			font-weight: 700;
			
		}
		&__section {
			display: flex;
			align-items: center;
			height: 335px;
			padding: 0 45px;
			overflow: hidden;
		}
		&__bar {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 30px 45px 0;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				height: 11px;
				width: 100%;
				background: url(../img/line-dots.jpg);
			}
		}
		&__info {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -8px;
		}
	}
}

.time-inform {
	font-size: 10px;
	line-height: 13px;
	font-style: italic;
	display: flex;
	align-items: center;
	.icon {
		margin-right: 5px;
	}
}

.content-row {
	display: flex;
}

.sidebar {
	flex-shrink: 0;
	width: 380px;
	margin-left: 40px;
}

.article-top {
	display: flex;
	justify-content: flex-end;
	padding-right: 80px;
	&__inner {
		box-sizing: border-box;
		margin-top: -45px;
		margin-left: -55px;
		background: #F9FBFC;
		position: relative;
		max-width: 1000px;
		padding: 35px 60px;
		margin-bottom: 15px;		
	}
}

.article-author-wrapper {
	border-bottom: 2px solid #D4E1EB;
	max-width: 1000px;
	padding-bottom: 30px;
	box-sizing: border-box;
	margin: 0 -130px;
	padding-left: 130px;
	padding-right: 130px;
}

.article-inner {
	max-width: 780px;
	padding-left: 80px;
}

.comments-count {
	font-size: 11px;
	line-height: 11px;
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	.icon {
		margin-right: 8px;
	}
	&__label {
		font-size: 14px;
		margin-right: 12px;
	}
}


.comments-btn-more {
	text-align: center;
	&__main {
		border-bottom: 1px solid #D4E1EB;
		padding-bottom: 15px;
		margin-bottom: 10px;
	}
}

.write-comment-wrap {
	margin-left: 275px;
	margin-top: 100px;
}

.side-left {
	width: 275px;
	margin-right: 30px;
	flex-shrink: 0;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
}

.informers-wrapper {
	width: 95px;
	position: absolute;
	left: -130px;
	top: 45px;
}

.inform-item-def {
	font-size: 11px;
	line-height: 14px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #D4E1EB;
	padding-bottom: 22px;
	margin-bottom: 22px;
	&:last-child {
		border-bottom: 0;
		padding-bottom: 0;
		margin-bottom: 0;	
	}
	&__icon {
		width: 26px;
		margin-right: 8px;
		img {
			width: 100%;
		}
	}
}

.promo-block {
	display: block;
	background: #0179FF url(../img/banner-bg.png) no-repeat center bottom;
	color: #fff;
	text-decoration: none;
	box-sizing: border-box;
	padding: 40px;
	height: 975px;
	max-width: 273px;
	margin-bottom: 40px;
	&__title {
		display: block;
		font-size: 36px;
		line-height: 42px;
		margin-bottom: 30px;
		font-weight: 700;
	}
	&__desc {
		display: block;
		font-size: 17px;
		line-height: 22px;
		font-weight: 700;
	}

	&_large {
		padding-top: 170px;
		height: 1280px;
		box-sizing: border-box;
		max-width: 100%;
		background-image: url(../img/banner-bg_large.png);
		.promo-block {
			&__title {
				font-size: 46px;
				line-height: 46px;
				margin-bottom: 70px;
			}
			&__desc {
				font-size: 27px;
				line-height: 36px;
			}
		}
	}
	&_theme2 {
		background-image: url(../img/mask-form.svg);
		background-position: center;
		height: 265px;
		max-width: 100%;
		padding: 40px 60px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		&_mini {
			padding: 30px 30px 30px 55px;
			height: 240px;
			.promo-block {
				&__title {
					font-size: 29px;
					line-height: 38px;
					margin-bottom: 15px;
				}
				&__desc {
					font-size: 13px;
					line-height: 17px;
				}
			}
		}
	}

	&_mod2 {
		flex-direction: column;
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: none;
		margin-top: 100px;
		.promo-block {
			&__title {
				display: block;
				width: 100%;
				font-size: 46px;
				line-height: 46px;
				text-align: left;
			}
			&__desc {
				font-size: 26px;
				line-height: 35px;
			}
		}
	}


	&_gorizontal {
		background-image: none;
		min-width: 100%;
		height: auto;
		padding: 0;
		margin: 50px 0;
		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.promo-block {
			&__title {
				font-size: 41px;
				line-height: 41px;
				margin-bottom: 8px;
			}
			&__desc {
				font-size: 28px;
				line-height: 37px;
				&-text-size2 {
					font-size: 31px;
				}
			}
		}
	}

	&__col1 {
		display: flex;
		align-items: center;
		&__main {
			width: 700px;
			margin-left: 30px;
		}
	}
}

.side-title {
	font-size: 16px;
	line-height: 21px;
	font-weight: 700;
	text-align: center;
	border-bottom: 2px solid $blue2;
	text-transform: uppercase;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.btn-wrapper {
	text-align: center;
	margin-top: 50px;
}

.content-main {
	width: 100%;
	flex-grow: 1;
	min-width: 0;
	&_blog {
		.news-wrapper {
			margin: 0 -20px;
		}
		.item-news2 {
			padding: 0 20px 48px;
			width: 33.3%;
			box-sizing: border-box;
			&__inner {
				width: auto;
			}
		}
		.block-wrapper {
			margin-bottom: 48px;
		}
	} 
}

.win-search {
	opacity: 0;
	visibility: hidden;
	background: $pink;
	position: fixed;
	width: 100%;
	top: 48px;
	z-index: 100;
	transition: .5s;
	&.active {
		opacity: 1;
		visibility: visible;
	}
	&__main {
		display: flex;
		box-sizing: border-box;
		padding: 10px 30px;
	}
	&__field {
		flex-grow: 1;
		width: 100%;
		margin-right: 15px;
		box-sizing: border-box;
		input {
			box-sizing: border-box;
			width: 100%;
			height: 45px;
			border-radius: 23px;
			padding-left: 20px;
			padding-right: 20px;
			background: #fff;
		}
		::-webkit-input-placeholder {
		  color: #B9CEDF;
		}

		::-moz-placeholder {
		  color: #B9CEDF;
		}

		:-moz-placeholder {
		  color: #B9CEDF;
		}

		:-ms-input-placeholder {
		  color: #B9CEDF;
		}
	}
}

.bg-figure {
	position: absolute;
	right: 0;
	top: -250px	;
	width: 1800px;
	height: 1890px;
	z-index: -1;
	overflow: hidden;
	img {
		position: relative;
		right: -270px;
		width: 100%;
	}
}

.news-slider3 {
	margin: 0 -30px;
	.slick-list {
		width: calc(100% + 500px);
	}
	.slick-arrow {
		top: 345px;
		z-index: 100;
	}
	.slick-prev {
		left: -20px;
	}
	.slick-next {
		right: 10px;
	}
}

.item-news3 {
	padding: 0 30px 60px;
	&__inner {
		width: 815px;
		border-radius: 10px 10px 5px 5px;
		overflow: hidden;
		background: #fff;
		box-shadow: 0 30px 40px 0 rgba(0,0,0,.16);

	}
	&__img {
		position: relative;
		height: 260px;
		overflow: hidden;
		text-align: center;
		img {
			height: 260px;
			max-width: none;
		}
	}
	&__title {
		margin-bottom: 20px;
		a {
			font-weight: 700;
			font-size: 38px;
			line-height: 50px;
			color: $blue2;
			text-decoration: none;
		}
	}
	&__desc {
		font-size: 25px;
		line-height: 33px;
		color: $blue2;
	}
	&__tags {
		position: absolute;
		right: 35px;
		top: 65px;
	}
	&__bar {
		display: flex;
		justify-content: space-between;
		margin-top: 35px;
	}
	&__section {
		padding: 30px 65px;
	}
	&__informers {
		display: flex;
		align-items: center;
		.inform-item-def {
			border-bottom: 0;
			padding-bottom: 0;
			margin-bottom: 0;
			color: $blue2;
			margin-right: 15px;
		}
	}
	&__link-more {
		a {
			font-size: 18px;
			line-height: 24px;
		}
	}
}

.news-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.blog-section {
	position: relative;
}

.inform-item-white {
	display: flex;
	align-items: center;
	font-size: 11px;
	line-height: 14px;
	font-weight: 700;
	margin: 8px 10px;
	&__icon {
		margin-right: 10px;
		line-height: 0;
	}
}







































