@media (max-width: 992px) {
	
	.start {
		&__title {
			font-size: 30px;
			line-height: 38px;
			margin-right: 300px;
			padding-top: 220px;
		}
		&__site-block {
			width: 300px;
			right: 0;
			&__inner {
				left: 30px;
				right: 30px;
			}
			&__text {
				padding-left: 85px;
			}
		}
	}

	.block-request {
		flex-direction: column;
		&__left {
			margin-right: 0;
			margin-bottom: 20px;
		}
		&__form {
			width: 100%;
			justify-content: space-around;
		}
		&__title {
			font-size: 22px;
			line-height: 22px;
		}
	}

	.accounting {
		&__img {
			left: 0;
		}
	}

	.differences2 {
		&__img {
			&__inner {
				right: -50px;
				transform: scale(.8);
				transform-origin: right center;
			}
		}
	}

	.list-advantages {
		&>li {
			font-size: 20px;
			line-height: 25px;
		}
	}

	.benefits {
		&__item {
			&__main {
				padding: 0 20px 20px;
			}
			&__text {
				font-size: 20px;
				line-height: 26px;
				height: auto;
				min-height: 200px;
			}
		}
	}

	.partners {
		&__item {
			padding-left: 10px;
			padding-right: 10px;
			margin-left: .7%;
			margin-right: .7%;
			width: 23.6%;
		}
	}

	.how-work {
		flex-direction: column;
		&__desc {
			width: 100%;
			margin-right: 0;
		}
		&__img {
			display: none;
		}
		&__bg-figure {
			display: none;
		}
		&__title {
			font-size: 19px;
			line-height: 25px;
			text-align: center;
			width: 100%;
		}
		&__inform {
			&__item {
				position: relative;
				&:after {
					content: "";
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: -26px;
					height: 1px;
					width: 178px;
					background: #DEE4ED;
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
				&__icon {
					margin-right: 20px;
				}
			}
		}
	}

	.chat-example {
		transform: scale(.8);
		transform-origin: center top;
		margin-bottom: -200px;
	}

	.section-title {
		font-size: 30px;
		line-height: 40px;
		br {
			display: none;
		}
	}

	.branches {
		justify-content: center;
		&__item {
			margin: 0 20px 40px;
		}
	}

	.advantages {
		&__item {
			width: 47.4%;
			margin-bottom: 30px;
		}
	}
	
	.work-for-you {
		transform: scale(.7);
		transform-origin: center 0;
		margin-bottom: -270px;
	}

	.you-get {
		&__item {
			&__img {
				transform: scale(.7);
				transform-origin: center;
				margin: 0 -20px;
			}
			&__icon {
				margin-right: 30px;
			}
		}
	}

	.tariffs {
		flex-wrap: wrap;
		&__item {
			margin-left: 1%;
			margin-right: 1%;
			margin-bottom: 30px;
			width: 48%;
		}
	}

	.app {
		&__title {
			font-size: 28px;
			line-height: 34px;
		}
		&__col {
			margin-right: 15px;
			width: 55%;
			flex-shrink: 0;
		}
	}

	.footer {
		.container {
			flex-direction: column;
		}
		&__section1 {
			flex-direction: column;
			width: 100%;
		}
		&__logotypes {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			&>div {
				flex-shrink: 0;
			}
		}
		&__logo2 {
		}
		&__logo {
			padding-right: 25px;
			margin-right: 30px;
			width: 146px;
			border-right: 2px solid rgba(255,255,255,.5);
			border-bottom: 0;
		}
		&__menu {
			width: 100%;
			columns: 1;
			margin: 10px auto;
			max-width: 340px;
		}
		&__socials {
			margin-top: 15px;
			justify-content: center;
		}
	}

	.subscribe {
		width: 340px;
		max-width: 100%;
		margin: 0 auto;
	}

	.footer {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.header {
		&__lvl1 {
			display: none;
		}
		&__lvl2 {
			display: none;
		}
		&__mob {
			display: block;
		}
	}

	.btns-tag_bottom {
		margin-left: 0;
	}

	.wrapper {
		padding-top: 52px;
	}


	.page-title {
		font-size: 36px;
		line-height: 36px;
		padding-top: 15px;
		padding-left: 0px;
		padding-bottom: 20px;
		width: 140px;
		&:after {
			left: -160px;
		}
		.page-title-symbol {
			display: inline-block;
			transform: scale(.7);
			transform-origin: 0 bottom;
		}
	}

	.breadcrumbs {
		padding-left: 30px;
	}

	.page-top-bar {
		.search-block {
			display: none;
		}
	}

	.top-links {
		a {
			padding: 0;
			font-size: 10px;
			border: 0;
			margin-right: 15px;
			position: relative;
			height: auto;
			&:after {
				content: "";
				position: absolute;
				right: -9px;
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
				height:7px;
				background: $blue2;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}

	.content-row {
		flex-wrap: wrap;
	}

	.sidebar {
		width: 100%;
		margin-left: 0;
		margin-top: 25px;
	}

	.article-inner {
		max-width: 100%;
	}

	.hide-tablet {
		display: none;
	}
	
	.item-news2 {
		.btn_theme7 {
			width: 150px;
		}
	}

	.news-slider3 {
		margin-left: -10px;
		margin-right: -10px;
	}

	.item-news3 {
		padding-left: 10px;
		padding-right: 10px;
		&__inner {
			width: 355px;
			position: relative;
			box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
		}
		&__img {
			height: 165px;
			img {
				height: 165px;
			}
		}
		&__section {
			padding: 15px 20px;
		}
		&__tags {
			display: none;
		}
		&__title {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 165px;
			display: flex;
			align-items: center;
			padding: 30px;
			box-sizing: border-box;
			a {
				color: #fff;
				font-size: 24px;
				line-height: 31px;
			}
		} 
		&__desc {
			font-size: 12px;
			line-height: 15px;
		}
		&__link-more {
			a {
				font-size: 13px;
				line-height: 16px;
			}
		}
		&__bar {
			margin-top: 10px;
		}
		.inform-item-def {
			&__icon {
				width: 18px;
			}
		}
	}











}

@media (max-width: 800px) {
	.work-for-you {
		margin-left: -50px;
		margin-right: -50px;
	}

}








