* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  font: 14px 'agfatumc', Arial, sans-serif;
  width: 100%;
  height: 100%;
  line-height: 20px;
  background: #F9FBFC;
}

a {
  color: #5479AC;
  outline: none;
  text-decoration: underline;
  transition: .5s;
}

a:hover {
  text-decoration: none;
}

img {
  border: 0;
  max-width: 100%;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  cursor: pointer;
  border: 0;
  outline: none;
}

input {
  vertical-align: middle;
  outline: none;
  border: 0;
}

input[type="submit"], input[type="button"] {
  cursor: pointer;
  -webkit-appearance: none;
}

textarea {
  outline: none;
  resize: none;
  overflow: auto;
}

:invalid {
  box-shadow: none;
}

ol, ul {
  list-style-position: inside;
}

::-webkit-input-placeholder {
  color: #2699FB;
  opacity: 1;
  transition: opacity 0.3s ease;
}

::-moz-placeholder {
  color: #2699FB;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:-moz-placeholder {
  color: #2699FB;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:-ms-input-placeholder {
  color: #2699FB;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.wrapper {
  min-height: 100%;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  min-width: 414px;
}

.container {
  max-width: 1580px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

.container_large {
  max-width: 1720px;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.hidden {
  overflow: hidden;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

@font-face {
  font-family: 'agfatumc';
  src: url("../fonts/agfatumc-webfont.eot");
  src: url("../fonts/agfatumc-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/agfatumc-webfont.woff2") format("woff2"), url("../fonts/agfatumc-webfont.woff") format("woff"), url("../fonts/agfatumc-webfont.ttf") format("truetype"), url("../fonts/agfatumc-webfont.svg#agfatumcregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'agfatumc';
  src: url("../fonts/agfatumc-bold-webfont.eot");
  src: url("../fonts/agfatumc-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/agfatumc-bold-webfont.woff2") format("woff2"), url("../fonts/agfatumc-bold-webfont.woff") format("woff"), url("../fonts/agfatumc-bold-webfont.ttf") format("truetype"), url("../fonts/agfatumc-bold-webfont.svg#agfatumcbold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url("../fonts/GothamPro.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro.otf") format("opentype"), url("../fonts/GothamPro.woff") format("woff"), url("../fonts/GothamPro.ttf") format("truetype"), url("../fonts/GothamPro.svg#GothamPro") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url("../fonts/GothamPro-Light.eot");
  src: local("Gotham Pro Light"), local("GothamPro-Light"), url("../fonts/GothamPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Light.woff2") format("woff2"), url("../fonts/GothamPro-Light.woff") format("woff"), url("../fonts/GothamPro-Light.ttf") format("truetype"), url("../fonts/GothamPro-Light.svg#GothamPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: .5s;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.btn_theme1 {
  border: .7px solid #fff;
  border-radius: 20px;
  font-size: 11px;
  color: #fff;
  font-family: "GothamPro";
  padding-left: 11px;
  padding-right: 11px;
  height: 24px;
  background-color: transparent;
}

.btn_theme1:hover {
  background: #fff;
  color: #000;
}

.btn_theme2 {
  background: #FF7484;
  color: #fff;
  font-size: 21px;
  font-weight: 700;
  height: 47px;
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
}

.btn_theme2:hover {
  background: #ff4157;
}

.btn_theme2_large {
  height: 67px;
  width: 450px;
  max-width: 100%;
  font-size: 28px;
  border-radius: 40px;
  box-shadow: 0 0 100px 8px rgba(0, 0, 0, 0.2);
}

.btn_theme2_medium {
  width: auto;
  font-size: 18px;
  height: 68px;
  padding-left: 40px;
  padding-right: 40px;
  text-transform: uppercase;
  border-radius: 40px;
}

.btn_theme2_medium2 {
  width: auto;
  font-size: 22px;
  height: 51px;
  padding-left: 70px;
  padding-right: 70px;
  text-transform: uppercase;
  border-radius: 40px;
}

.btn_theme3 {
  background: #fff;
  width: 197px;
  height: 39px;
  font-weight: 700;
  font-size: 14px;
  color: #2699FB;
  border-radius: 20px;
  border: 2px solid #fff;
}

.btn_theme3:hover {
  border-color: #fff;
  background: #2699FB;
  color: #fff;
}

.btn_theme4 {
  background: #2699FB;
  color: #fff;
  font-size: 28px;
  line-height: 28px;
  font-weight: 700;
  height: 70px;
  width: 447px;
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.btn_theme4:hover {
  background: #0480ea;
}

.btn_theme4_large {
  height: 67px;
  width: 450px;
  font-size: 28px;
  border-radius: 40px;
  box-shadow: 0 0 100px 8px rgba(0, 0, 0, 0.2);
}

.btn_theme4_small {
  width: 310px;
  height: 49px;
  font-size: 17px;
}

.btn_theme5 {
  background: #fff;
  color: #2699FB;
  border: 2px solid transparent;
  font-size: 17px;
  line-height: 17px;
  font-weight: 700;
  width: 171px;
  height: 38px;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.btn_theme5:hover {
  background: #2699FB;
  border-color: #fff;
  color: #fff;
}

.btn_theme5_large {
  height: 65px;
  font-size: 33px;
  line-height: 33px;
  width: auto;
  border-radius: 40px;
  padding-left: 47px;
  padding-right: 47px;
}

.btn_theme6 {
  border: 1px solid #B9CEDF;
  border-radius: 10px;
  font-size: 15px;
  color: #5479AC;
  height: 37px;
  background-color: transparent;
  min-width: 180px;
}

.btn_theme6:hover {
  background: #B9CEDF;
  color: #fff;
}

.btn_theme6_medium {
  height: 46px;
}

.btn_theme7 {
  background: #fff;
  color: #2699FB;
  font-size: 19px;
  line-height: 25px;
  font-weight: 700;
  width: 180px;
  height: 44px;
  border-radius: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border: 2px solid transparent;
}

.btn_theme7:hover {
  background: #2699FB;
  border-color: #fff;
  color: #fff;
}

.btn_arrow {
  width: 33px;
  height: 33px;
  background: url(../img/icon-arrow-frame.svg) no-repeat;
}

.btn_arrow_blue2 {
  background: url(../img/icon-arrow-frame2.svg) no-repeat;
}

.btn_plus {
  width: 40px;
  height: 40px;
  background: #fff url(../img/icon-plus2.svg) no-repeat center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  border-radius: 100%;
}

.btn_store:hover {
  opacity: .9;
}

.btn_subscribe {
  position: absolute;
  right: 10px;
  top: 7px;
  width: 26px;
  height: 26px;
  background: url(../img/icon-telegram2.svg) no-repeat;
}

.btn_soc {
  margin-right: 30px;
  flex-shrink: 0;
}

.btn_soc:last-child {
  margin-right: 0;
}

.btn_soc:hover {
  opacity: .7;
}

.btn_mob-menu {
  width: 32px;
  height: 28px;
  background: url(../img/icon-menu.svg) no-repeat;
}

.btn_user {
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 100%;
  position: relative;
}

.btn_user:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 17px;
  height: 17px;
  background: url(../img/icon-user.svg) no-repeat;
}

.btn_back {
  width: 33px;
  height: 33px;
  background: url(../img/icon-arrow.svg) no-repeat;
}

.btn_to-top {
  position: fixed;
  left: 50px;
  top: 150px;
  width: 53px;
  height: 53px;
  background: #2699FB;
  border-radius: 100%;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  transition: .4s;
}

.btn_to-top:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 34px;
  height: 32px;
  background: url(../img/arrow-top.svg);
}

.btn_to-top.visible {
  opacity: 1;
  visibility: visible;
}

.btn_search {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  background: url(../img/icon-search.svg) no-repeat;
}

.btn_search2 {
  width: 22px;
  height: 22px;
  background: url(../img/icon-search_white.svg) no-repeat;
  background-size: cover;
  flex-shrink: 0;
}

.btn_search2_large {
  width: 41px;
  height: 41px;
}

.animated {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes scaleZoom {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.32);
  }
  100% {
    transform: scale(1);
  }
}

.scaleZoom {
  animation-name: scaleZoom;
}

.scaleZoom.wow {
  visibility: visible;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 200;
  background: #F9FBFC;
}

.header__lvl1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.header__lvl1 .container {
  max-width: 1570px;
  display: flex;
  justify-content: flex-end;
}

.header__lvl2 {
  background: #FF7484;
  padding-top: 8px;
  padding-bottom: 7px;
}

.header__lvl2 .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1580px;
}

.header__links {
  margin-right: 40px;
  list-style: none;
  display: flex;
}

.header__links > li {
  margin-right: 26px;
}

.header__links > li a {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 10px;
  line-height: 12px;
  color: #000;
  text-transform: uppercase;
  font-family: 'GothamPro';
}

.header__links > li a:hover {
  text-decoration: underline;
}

.header__links > li:last-child {
  margin-right: 0;
}

.header__call {
  font-family: 'GothamPro';
  font-size: 13px;
}

.header__call a {
  text-decoration: none;
  color: #000;
}

.header__logo {
  width: 110px;
  line-height: 0;
}

.header__section-nav {
  display: flex;
  align-items: center;
}

.header__mob {
  background: #FF7484;
  padding-top: 10px;
  padding-bottom: 10px;
  display: none;
  color: #fff;
}

.header__mob .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__ask {
  font-size: 12px;
  line-height: 14px;
}

.header__ask a {
  color: #fff;
  text-decoration: none;
}

.header_inner .menu > li > a {
  font-size: 19px;
  font-weight: 700;
  font-family: 'agfatumc';
}

.header_inner .header__call {
  font-family: 'agfatumc';
  font-size: 19px;
  font-weight: 700;
  color: #fff;
}

.header_inner .header__call a {
  color: #fff;
}

.header_inner .header__logo {
  width: 155px;
}

.header_inner .header__lvl2 {
  box-shadow: 0 19px 30px 0 rgba(0, 0, 0, 0.18);
  padding-top: 17px;
  padding-bottom: 15px;
}

.header_inner .header__lvl2 .container {
  max-width: 1720px;
}

.header_inner .header__section-nav {
  padding-right: 55px;
}

.wrapper {
  padding-top: 88px;
}

.menu {
  list-style: none;
  display: flex;
  margin-right: 42px;
}

.menu > li {
  margin-right: 41px;
}

.menu > li > a {
  font-family: 'GothamPro';
  font-size: 13px;
  color: #fff;
  text-decoration: none;
}

.menu > li > a:hover {
  text-decoration: underline;
}

.menu > li:last-child {
  margin-right: 0;
}

.section-start {
  background: url(../img/start-bg.png) no-repeat -178px -105px;
  min-height: 767px;
  max-width: 1920px;
  margin: 0 auto;
}

.section-start .container {
  position: relative;
}

.start__title {
  color: #fff;
  font-size: 41px;
  line-height: 53px;
  max-width: 75%;
  text-align: right;
  padding-top: 210px;
  margin-right: 500px;
}

.start__site-block {
  position: absolute;
  right: -179px;
  top: 0;
  width: 682px;
  height: 443px;
  background: url(../img/figure-1.svg) no-repeat 0 bottom;
  color: #fff;
}

.start__site-block__inner {
  position: absolute;
  left: 50px;
  bottom: 50px;
}

.start__site-block__text {
  margin-top: 20px;
  font-size: 17px;
  line-height: 21px;
  width: 200px;
  padding-left: 120px;
}

.start__logotypes {
  order: 3;
  align-items: center;
  justify-content: center;
  display: none;
}

.start__logotypes__item {
  border-right: 2px solid rgba(84, 121, 172, 0.5);
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding-right: 20px;
  box-sizing: border-box;
  padding-right: 20px;
  margin-right: 20px;
}

.start__logotypes__item:nth-child(2n) {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}

.block-request {
  display: flex;
  align-items: center;
  background: #2699FB;
  border-radius: 20px;
  max-width: 1305px;
  margin-top: -51px;
  padding: 35px 45px 28px;
  display: flex;
  align-items: center;
  color: #fff;
  box-sizing: border-box;
  box-shadow: 0 0 100px 10px rgba(0, 0, 0, 0.3);
}

.block-request__left {
  flex-shrink: 0;
  margin-right: 42px;
  text-align: center;
}

.block-request__title {
  font-size: 26px;
  line-height: 26px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.block-request__title2 {
  font-size: 23px;
  line-height: 20px;
}

.block-request__field {
  margin: 0 5px;
}

.block-request__field_text {
  width: 226px;
}

.block-request__field_text input {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  box-sizing: border-box;
  height: 47px;
  color: #2699FB;
  font-size: 18px;
  border-radius: 7px;
}

.block-request__field_btn {
  width: 172px;
}

.block-request__field_btn .btn {
  width: 100%;
}

.block-request__form {
  display: flex;
  width: 100%;
  flex-grow: 1;
  justify-content: space-between;
  margin: 0 -5px;
}

.block-request__btn {
  display: none;
}

.accounting {
  display: flex;
  justify-content: center;
  padding-top: 18px;
  padding-bottom: 75px;
}

.accounting__img {
  position: relative;
  left: -70px;
  margin-right: 30px;
  width: 392px;
  min-width: 310px;
  margin-top: -44px;
  text-align: center;
}

.accounting__main {
  width: 430px;
}

.accounting__block {
  color: #5479AC;
  position: relative;
  padding-left: 50px;
  padding-bottom: 50px;
  margin-bottom: 42px;
}

.accounting__block__bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 917px;
  height: 910px;
  background: url(../img/figure-2.svg);
  z-index: -1;
}

.accounting__title {
  font-size: 27px;
  line-height: 44px;
  margin-bottom: 20px;
  margin-bottom: 10px;
}

.accounting__title2 {
  font-size: 33px;
  line-height: 43px;
}

.men-icons {
  position: relative;
}

.men-icons__icons-wrap {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
}

.men-icons__icon_pos4 {
  position: absolute;
  left: 50%;
  top: 110px;
  transform: translateX(-50%);
}

.section-accounting .next-sep {
  margin: 50px 0;
}

.next-sep {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.next-sep__icon {
  display: block;
  width: 39px;
  height: 39px;
  border-radius: 100%;
  margin-bottom: 30px;
  position: relative;
  background: rgba(84, 121, 172, 0.42);
}

.next-sep__icon:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 22px;
  height: 10px;
  background: url(../img/icon-arrow-down.svg) no-repeat;
}

.next-sep__line {
  display: block;
  width: 1px;
  height: 50px;
  background: #DEE4ED;
}

.next-sep_theme2 .next-sep__icon {
  background: rgba(84, 121, 172, 0.62);
}

.next-sep_theme2 .next-sep__line {
  background: #5479AC;
}

.section-title {
  text-align: center;
  font-size: 44px;
  line-height: 50px;
  color: #5479AC;
  font-weight: 700;
}

.title {
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  color: #5479AC;
  font-weight: 700;
}

.title_large {
  font-size: 46px;
  line-height: 61px;
}

.title_medium {
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 25px;
}

.title_small {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 20px;
}

.title.align-left {
  text-align: left;
}

.section-differences1 {
  padding-top: 50px;
  padding-bottom: 85px;
}

.section-differences1 .next-sep {
  margin-bottom: 20px;
}

.section-differences1 .section-title {
  margin-bottom: 100px;
}

.differences1__cols {
  display: flex;
  justify-content: center;
}

.differences1__col {
  width: 50%;
}

.differences1__img {
  text-align: center;
  padding-top: 64px;
}

.differences1__img__inner {
  position: relative;
  right: -80px;
}

.differences1__main__inner {
  max-width: 465px;
}

.differences1__main__item {
  margin-left: 62px;
  border-bottom: 1px solid #DEE4ED;
  position: relative;
  height: 130px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  color: #5479AC;
}

.differences1__main__icon {
  top: 50%;
  transform: translateY(-50%);
  left: -80px;
  flex-shrink: 0;
}

.differences1__main__icon.icon-minus {
  position: absolute;
}

.icon-minus {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #FD3A58;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
}

.icon-minus:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 2px;
  background: #fff;
}

.section-differences2 {
  padding-bottom: 190px;
}

.section-differences2 .next-sep {
  margin-bottom: 47px;
}

.section-differences2 .section-title {
  margin-bottom: 100px;
}

.differences2__cols {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.differences2__col {
  width: 50%;
}

.differences2__img__inner {
  position: relative;
  top: -10px;
  text-align: center;
  right: -45px;
  height: 830px;
  width: 790px;
}

.differences2__img__btn {
  position: absolute;
  right: 230px;
  top: 635px;
}

.differences2__main {
  padding-top: 212px;
  position: relative;
  padding-bottom: 30px;
}

.differences2__main__bg-figure {
  position: absolute;
  left: 33px;
  bottom: 0;
  width: 2000px;
  height: 2000px;
  background: url(../img/figure-3.svg) no-repeat 0 bottom;
  z-index: -1;
}

.differences2__grafic {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -65px;
  width: 142px;
  height: 142px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  line-height: 38px;
  border-radius: 100%;
  background: #2699FB;
  font-weight: 700;
  color: #fff;
  box-shadow: -15px 15px 50px 0 rgba(0, 0, 0, 0.2);
}

.differences2__grafic:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  height: 80px;
  width: 1px;
  background: #DEE4ED;
  z-index: -1;
}

.block-logo {
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
  width: 97px;
  height: 97px;
  background: #FD4A68;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.16);
}

.block-logo:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  height: 155px;
  width: 1px;
  background: #DEE4ED;
}

.list-advantages {
  list-style: none;
  margin-left: -14px;
}

.list-advantages > li {
  position: relative;
  padding-left: 77px;
  height: 62px;
  margin-bottom: 10px;
  color: #5479AC;
  font-size: 25px;
  line-height: 30px;
}

.list-advantages > li:last-child {
  margin-bottom: 0;
}

.list-advantages > li:last-child:after {
  display: none;
}

.list-advantages > li:before {
  content: "+";
  position: absolute;
  left: 0;
  top: 0;
  width: 28px;
  height: 28px;
  background: #2699FB;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  line-height: 26px;
  font-family: "GothamPro";
  font-weight: 300;
  color: #fff;
  box-shadow: -5px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.list-advantages > li:after {
  content: "";
  position: absolute;
  left: 14px;
  top: 28px;
  height: 42px;
  width: 1px;
  background: #DEE4ED;
  z-index: -1;
}

.block-more {
  text-align: center;
}

.block-more__title {
  text-align: center;
  color: #5479AC;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}

.block-more__shadow {
  position: relative;
  margin: 15px 0 -60px;
  height: 100px;
  overflow: hidden;
}

.block-more__shadow:before {
  content: "";
  position: absolute;
  max-width: 1160px;
  border-radius: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: -45px;
  width: 100%;
  box-shadow: 0 0 100px 20px rgba(0, 0, 0, 0.5);
}

.section-benefit {
  padding-top: 80px;
}

.section-benefit .next-sep {
  margin-bottom: 80px;
}

.section-benefit .section-title {
  margin-bottom: 107px;
}

.benefits {
  display: flex;
  justify-content: center;
  margin-bottom: 75px;
}

.benefits__item {
  width: 400px;
}

.benefits__item__title {
  font-size: 30px;
  line-height: 36px;
  color: #5479AC;
  font-weight: 700;
  text-align: center;
  margin-bottom: 125px;
}

.benefits__item__main {
  background: #fff;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 0 50px 30px;
  text-align: center;
}

.benefits__item__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 138px;
  height: 138px;
  margin-top: -67px;
  margin-bottom: 42px;
  border-radius: 60px 30px 60px 30px;
  box-shadow: -10px 10px 40px 0 rgba(0, 0, 0, 0.15);
}

.benefits__item__icon_pink {
  background: #FF7484;
}

.benefits__item__icon_blue {
  background: #2699FB;
}

.benefits__item__text {
  font-size: 25px;
  line-height: 33px;
  color: #5479AC;
  text-align: center;
  border-top: 1px solid #DEE4ED;
  height: 300px;
  display: flex;
  align-items: center;
}

.benefits__plus {
  margin: 20.5% 20px 0;
}

.offer-btn {
  text-align: center;
  margin-bottom: 60px;
}

.offer-btn__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #5479AC;
  margin-bottom: 67px;
}

.icon-gradient-block {
  width: 111px;
  height: 111px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 47px;
  background: linear-gradient(to bottom, #fc7387 0%, #87c5fa 100%);
  border-radius: 30px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.16);
}

.icon-gradient-block_size-medium {
  width: 84px;
  height: 84px;
  border-radius: 22px;
}

.icon-gradient-block_size-small {
  width: 59px;
  height: 59px;
  border-radius: 13px;
}

.inform-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 154px;
}

.inform-item__text {
  width: 493px;
  text-align: left;
}

.line-sep {
  width: 51px;
  height: 3px;
  background: #B9CEDF;
  margin: 0 auto 84px;
}

.section-about {
  padding-top: 120px;
}

.section-about .next-sep {
  margin-bottom: 39px;
}

.section-about .section-title {
  margin-bottom: 68px;
}

.partners {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 65px;
  max-width: 1280px;
}

.partners__item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.7% 45px;
  background: #fff;
  border-radius: 30px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  width: 21.5%;
  height: 183px;
  box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.06);
}

.garanty {
  display: flex;
  justify-content: center;
}

.garanty__block {
  display: inline-block;
  width: 389px;
  color: #fff;
  border-radius: 100px;
  background: #FF7484;
  box-sizing: border-box;
  padding: 16px 45px;
  position: relative;
}

.garanty__block__text1 {
  font-size: 49px;
  line-height: 49px;
}

.garanty__block__text2 {
  font-size: 34px;
  line-height: 34px;
}

.garanty__icon {
  position: absolute;
  right: -35px;
  top: -62px;
  width: 220px;
  height: 252px;
  background: url(../img/icon-garanty.svg) no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  padding-left: 25px;
  padding-top: 85px;
}

.garanty__icon__text1 {
  display: block;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 0px;
  position: relative;
}

.garanty__icon__text1:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -13px;
  width: 30px;
  height: 2px;
  background: #fff;
  opacity: .32;
}

.garanty__icon__text2 {
  display: inline-block;
  font-size: 11px;
  line-height: 15px;
}

.section-how-work {
  padding-top: 50px;
  padding-top: 150px;
}

.section-how-work .next-sep {
  margin-bottom: 55px;
}

.section-how-work .section-title {
  margin-bottom: 78px;
}

.how-work {
  display: flex;
  justify-content: center;
}

.how-work__desc {
  width: 410px;
  margin-right: 30px;
}

.how-work__img {
  width: 910px;
}

.how-work__title {
  font-size: 35px;
  line-height: 45px;
  width: 560px;
  color: #5479AC;
  margin-bottom: 10px;
}

.how-work__inform {
  position: relative;
  padding: 47px 20px 0 0;
}

.how-work__inform__icon {
  border-radius: 100%;
  box-shadow: none;
  width: 60px;
  height: 60px;
  margin-right: 30px;
  flex-shrink: 0;
}

.how-work__inform__text {
  color: #5479AC;
  font-size: 18px;
  line-height: 24px;
}

.how-work__inform__item {
  margin-bottom: 52px;
  display: flex;
  align-items: center;
}

.how-work__inform__item:last-child {
  margin-bottom: 0;
}

.how-work__bg-figure {
  position: absolute;
  right: 0;
  top: 0;
  width: 1186px;
  height: 1250px;
  background: url(../img/figure-4.svg) no-repeat;
  z-index: -1;
}

.chat-example {
  display: flex;
  justify-content: center;
  position: relative;
}

.chat-example__phone {
  width: 380px;
  height: 775px;
  position: relative;
}

.chat-example__phone > img {
  margin: -40px 0 0 -212px;
  max-width: none;
}

.chat-example__phone__socials {
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.chat-example__phone__socials__item {
  margin: 0 5px;
}

.chat-example__message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
}

.chat-example__message__section {
  width: 665px;
  margin-right: 60px;
  position: relative;
}

.chat-example__message__name {
  font-size: 21px;
  color: #5479AC;
  line-height: 26px;
  margin-bottom: 12px;
  padding-left: 58px;
}

.chat-example__message__main {
  background: #F2F8FC;
  border: 1px solid #D0DCEB;
  padding: 50px;
  box-sizing: border-box;
  border-radius: 45px;
  font-size: 26px;
  line-height: 34px;
  color: #5479AC;
}

.chat-example__message__icon {
  flex-shrink: 0;
  width: 114px;
  height: 114px;
  margin-bottom: 25px;
}

.chat-example__message__status {
  position: absolute;
  right: 50px;
  bottom: 15px;
  width: 24px;
  height: 14px;
  background: url(../img/msg-status.svg) no-repeat;
  background-size: cover;
}

.chat-example__message__status_read {
  background: url(../img/msg-status_read.svg) no-repeat;
}

.chat-example__message_variant2 .chat-example__message__icon {
  order: -1;
}

.chat-example__message_variant2 .chat-example__message__section {
  margin-right: 0;
  margin-left: 60px;
}

.chat-example__message_variant2 .chat-example__message__main {
  background: #fff;
}

.chat-example__message_variant2 .chat-example__message__name {
  padding-left: 0;
  padding-right: 58px;
  text-align: right;
}

.chat-example__messages-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 845px;
  top: 210px;
}

.icon-top {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

.icon-top .icon-gradient-block {
  margin-right: 0;
}

.section-easy {
  padding-top: 55px;
}

.section-easy .next-sep {
  margin-bottom: 42px;
}

.section-easy .section-title {
  margin-bottom: 147px;
}

.video-time {
  text-align: center;
  margin-bottom: 70px;
}

.video-time__icon {
  opacity: .43;
}

.video-time__text {
  opacity: .43;
  color: #5479AC;
  font-size: 18px;
  line-height: 24px;
}

.section-video {
  padding-top: 152px;
  padding-bottom: 70px;
}

.section-video .icon-top {
  margin-bottom: 75px;
}

.section-video .section-title {
  margin-bottom: 56px;
}

.video {
  max-width: 980px;
  height: 551px;
  margin: 0 auto;
  background: #ccc;
  border-radius: 50px;
  overflow: hidden;
  position: relative;
}

.video iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.video__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.video__overlay img {
  max-width: 980px;
}

.video__overlay__play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 183px;
  height: 183px;
  background: rgba(255, 255, 255, 0.74);
  border-radius: 100%;
}

.video__overlay__play:before {
  content: "";
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 51px;
  height: 62px;
  background: url(../img/icon-play.svg);
}

.branches {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -40px;
}

.branches__item {
  margin: 0 40px 66px;
  width: 247px;
}

.branches__item__block {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 30px 0 rgba(111, 109, 109, 0.05);
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: #5479AC;
}

.branches__item__icon {
  width: 73px;
  height: 73px;
  margin: 0 auto -22px;
  border-radius: 100%;
  position: relative;
  z-index: 5;
}

.section-branches {
  padding-top: 110px;
}

.section-branches .next-sep {
  margin-bottom: 35px;
}

.section-branches .section-title {
  margin-bottom: 56px;
}

.section-branches .container {
  max-width: 920px;
}

.section-branches__illustration {
  text-align: center;
  margin-bottom: 26px;
}

.section-branches__main {
  background: #F0F4F8;
  padding-top: 23px;
  padding-bottom: 15px;
}

.icon-ofsset-spec {
  margin: 0 0 0 -9px;
}

.subtitle {
  text-align: center;
  max-width: 690px;
  margin: 0 auto;
  font-size: 25px;
  line-height: 33px;
  color: #5479AC;
  margin-bottom: 100px;
}

.section-advantages {
  padding-top: 55px;
}

.section-advantages .container {
  max-width: 1490px;
}

.section-advantages .next-sep {
  margin-bottom: 14px;
}

.section-advantages .section-title {
  margin-bottom: 54px;
}

.advantages {
  display: flex;
  flex-wrap: wrap;
}

.advantages__item {
  width: 28.8%;
  min-height: 399px;
  margin: 0 2.2% 80px;
  padding: 31px 20px 28px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.05);
}

.advantages__item__line {
  width: 159px;
  height: 4px;
  background: #5479AC;
  opacity: .13;
  margin: 0 auto 30px;
}

.advantages__icon {
  text-align: center;
  margin-bottom: 18px;
}

.advantages__title {
  font-size: 19px;
  line-height: 25px;
  color: #5479AC;
  font-weight: 700;
  text-align: center;
  margin-bottom: 38px;
}

.advantages__text {
  font-size: 23px;
  line-height: 30px;
  color: #5479AC;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.work-for-you {
  width: 790px;
  height: 790px;
  margin: 0 auto;
  position: relative;
}

.work-for-you__ellips {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 60px solid white;
  box-sizing: border-box;
  border-radius: 100%;
  box-shadow: 0 0 100px 0 rgba(38, 153, 251, 0.53), inset 0 0 150px 0 rgba(38, 153, 251, 0.53);
}

.work-for-you__illustration {
  position: absolute;
  left: 50%;
  width: 430px;
  top: 125px;
  transform: translateX(-50%);
}

.work-for-you__illustration img {
  width: 100%;
}

.work-for-you__illustration__title {
  text-align: center;
  color: #5479AC;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  margin-top: -30px;
}

.work-for-you__item {
  position: absolute;
  width: 218px;
  height: 218px;
  border: 10px solid;
  box-sizing: border-box;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
  border-radius: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  color: #5479AC;
}

.work-for-you__item__sep {
  margin: 20px auto;
  width: 55px;
  height: 4px;
  background: #5479AC;
  opacity: .13;
}

.work-for-you__item:after {
  content: "";
  position: absolute;
  right: 8px;
  top: 15px;
  width: 60px;
  height: 86px;
}

.work-for-you__item_pos1 {
  left: 50%;
  transform: translateX(-50%);
  top: -72px;
}

.work-for-you__item_pos2 {
  right: 30px;
  top: 23px;
}

.work-for-you__item_pos3 {
  right: -73px;
  top: 50%;
  transform: translateY(-50%);
}

.work-for-you__item_pos4 {
  right: 30px;
  bottom: 45px;
}

.work-for-you__item_pos5 {
  left: 50%;
  transform: translateX(-50%);
  bottom: -72px;
}

.work-for-you__item_pos6 {
  left: 30px;
  bottom: 45px;
}

.work-for-you__item_pos7 {
  left: -73px;
  top: 50%;
  transform: translateY(-50%);
}

.work-for-you__item_pos8 {
  left: 30px;
  top: 23px;
}

.work-for-you__item_blue-light {
  border-color: #ACD9FF;
}

.work-for-you__item_blue-light:after {
  background-image: url(../img/elem-line-dot_blue-light.svg);
}

.work-for-you__item_gray {
  border-color: #B9CEDF;
}

.work-for-you__item_gray:after {
  background-image: url(../img/elem-line-dot_gray.svg);
}

.work-for-you__item_pink-light {
  border-color: #FFB4BC;
}

.work-for-you__item_pink-light:after {
  background-image: url(../img/elem-line-dot_pink-light.svg);
}

.work-for-you__item_blue {
  border-color: #2699FB;
}

.work-for-you__item_blue:after {
  background-image: url(../img/elem-line-dot_blue.svg);
}

.work-for-you__item_pink {
  border-color: #FF7484;
}

.work-for-you__item_pink:after {
  background-image: url(../img/elem-line-dot_pink.svg);
}

.work-for-you__item_blue-light {
  border-color: #ACD9FF;
}

.work-for-you__item_blue-light:after {
  background-image: url(../img/elem-line-dot_blue-light.svg);
}

.work-for-you__item_blue-light {
  border-color: #ACD9FF;
}

.work-for-you__item_blue-light:after {
  background-image: url(../img/elem-line-dot_blue-light.svg);
}

.section-work-for-you {
  padding-top: 40px;
}

.section-work-for-you .next-sep {
  margin-bottom: 55px;
}

.section-work-for-you .section-title {
  margin-bottom: 145px;
}

.section-work-for-you__btn {
  margin-top: 138px;
  text-align: center;
}

.you-get__item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 63px;
}

.you-get__item:last-child {
  margin-bottom: 0;
}

.you-get__item__img {
  width: 280px;
  text-align: center;
  flex-shrink: 0;
  margin-right: 70px;
}

.you-get__item__icon {
  width: 49px;
  height: 49px;
  background: #2699FB;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.26);
  margin-right: 60px;
  flex-shrink: 0;
}

.you-get__item__text {
  color: #5479AC;
  font-size: 25px;
  line-height: 33px;
  width: 711px;
}

.you-get__item_img-only {
  justify-content: flex-start;
}

.you-get__item_spec {
  align-items: flex-end;
}

.you-get__item_spec .you-get__item__text {
  margin-bottom: 40px;
}

.you-get__item_spec .you-get__item__icon {
  margin-bottom: 40px;
}

.section-you-get {
  padding-top: 190px;
  max-width: 1170px;
}

.section-you-get .section-title {
  margin-bottom: 64px;
}

.section-you-get .next-sep {
  margin-bottom: 32px;
}

.section-you-get__btn {
  text-align: center;
  margin-top: 25px;
}

.section-tariffs {
  padding-top: 80px;
}

.section-tariffs .next-sep {
  margin-bottom: -20px;
}

.section-tariffs .next-sep__line {
  background: #5479AC;
  opacity: .33;
}

.section-tariffs__main {
  background: #ACD9FF;
  padding-top: 67px;
  padding-bottom: 55px;
}

.section-tariffs__main .section-title {
  margin-bottom: 58px;
}

.section-tariffs .container {
  max-width: 1270px;
}

.tariffs {
  display: flex;
  justify-content: space-between;
  margin: 0 -22px 47px;
}

.tariffs__item {
  width: 21.5%;
  margin: 0 1.7% 50px;
}

.tariffs__item__top {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.17);
  height: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 28px;
  color: #5479AC;
  font-weight: 700;
  position: relative;
  margin-bottom: 6px;
}

.tariffs__item__top__btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -20px;
}

.tariffs__item__main {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.17);
}

.tariffs__item__section {
  padding: 30px 45px 34px;
}

.tariffs__item__section-price {
  background: #2699FB;
  padding: 16px 20px 28px;
  color: #fff;
  text-align: center;
  border-radius: 0 0 12px 12px;
}

.tariffs__item__list {
  list-style: none;
  font-size: 18px;
  line-height: 24px;
  color: #5479AC;
  margin-bottom: 18px;
}

.tariffs__item__list > li {
  padding-left: 25px;
  position: relative;
  margin-bottom: 8px;
}

.tariffs__item__list > li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 16px;
  height: 16px;
  background: url(../img/icon-check.svg);
  background-size: cover;
}

.tariffs__item__list_without-icon:before {
  display: none;
}

.tariffs__item__price {
  font-size: 33px;
  line-height: 33px;
  font-weight: 700;
  border-bottom: 3px solid rgba(231, 243, 252, 0.07);
  padding-bottom: 10px;
  margin-bottom: 6px;
}

.tariffs__item__price-text {
  color: #ACD9FF;
  font-size: 18px;
  font-weight: 700;
}

.text-btn {
  display: flex;
  justify-content: center;
}

.text-btn__text {
  font-size: 28px;
  line-height: 28px;
  color: #5479AC;
  font-weight: 700;
  margin-right: 30px;
}

.form-connect {
  max-width: 1044px;
  margin: 0 auto;
  border: 1px solid #9FD2FC;
  background: #fff;
  border-radius: 40px;
  padding: 49px 40px 57px;
  box-sizing: border-box;
}

.form-connect__title {
  text-align: center;
  font-size: 26px;
  line-height: 34px;
  color: #5479AC;
  margin-bottom: 54px;
}

.form-connect__block {
  margin-bottom: 52px;
}

.form-connect__btn {
  text-align: center;
  margin-top: 65px;
}

.form-connect__field-title {
  text-align: center;
  font-size: 25px;
  line-height: 33px;
  color: #5479AC;
  margin-bottom: 5px;
}

.form-connect__field {
  max-width: 562px;
  margin: 0 auto;
}

.form-connect__field input {
  border: 1px solid #B9CEDF;
  font-size: 18px;
  border-radius: 4px;
  height: 52px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.section-connect {
  padding-top: 130px;
}

.section-connect .section-title {
  margin-bottom: 100px;
}

.app {
  display: flex;
  justify-content: center;
}

.app__col {
  width: 550px;
  margin-right: 35px;
  padding-top: 15px;
  padding-bottom: 50px;
}

.app__logo-wrap {
  max-width: 450px;
}

.app__title {
  color: #5479AC;
  font-weight: 700;
  font-size: 44px;
  line-height: 50px;
  margin-bottom: 25px;
}

.app__title2 {
  color: #5479AC;
  font-size: 30px;
  line-height: 39px;
  margin-bottom: 65px;
}

.app__img {
  width: 544px;
}

.app__btns {
  max-width: 450px;
  display: flex;
  justify-content: space-between;
}

.app__block-logo {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  margin: 0 auto 60px;
}

.app__block-logo:after {
  display: none;
}

.section-app {
  padding-top: 263px;
}

.section-mass-media {
  background: #fff;
  padding-top: 198px;
  padding-bottom: 180px;
}

.section-mass-media .section-title {
  margin-bottom: 130px;
}

.mass-media {
  display: flex;
}

.mass-media__item {
  width: 330px;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  margin: 0 85px 10px;
  transition: .4s;
}

.mass-media__item__logo {
  border-bottom: 3px solid #DEE4ED;
  height: 52px;
  text-align: center;
}

.mass-media__item__text {
  font-size: 22px;
  line-height: 29px;
  color: #B3C3D9;
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.mass-media__item__text:before {
  content: "";
  position: absolute;
  left: -74px;
  top: 0px;
  width: 97px;
  height: 63px;
  background: url(../img/quote-1.svg);
}

.mass-media__item__text:after {
  content: "";
  position: absolute;
  right: -74px;
  bottom: -28px;
  width: 97px;
  height: 63px;
  background: url(../img/quote-2.svg);
}

.mass-media__item.zindex2 {
  opacity: .17;
}

.mass-media__item.zindex3 {
  opacity: .17;
}

.mass-media__item.zindex4 {
  opacity: .17;
}

.mass-media .slick-arrow {
  position: absolute;
  bottom: -160px;
  width: 58px;
  height: 58px;
  background: transparent;
  font-size: 0;
}

.mass-media .slick-prev {
  left: 37%;
  background-image: url(../img/arrow-left.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.mass-media .slick-next {
  right: 37%;
  background-image: url(../img/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.reviews {
  display: flex;
  margin-top: -20px;
  margin-bottom: -20px;
}

.reviews__item {
  width: 600px;
  padding: 30px 30px 50px;
  margin: 60px 0;
  box-sizing: border-box;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 3px 60px 0 rgba(0, 0, 0, 0.21);
  transform: scale(0.92);
  bottom: 5px;
  transform-origin: center bottom;
  opacity: .6;
  margin-left: -85px;
  margin-right: -85px;
  z-index: 0;
  position: relative;
}

.reviews__item.slick-center {
  transform: scale(1);
  opacity: 1;
  z-index: 10;
  position: relative;
  bottom: 0;
}

.reviews__item.zindex1 {
  opacity: .6;
}

.reviews__item.zindex2 {
  z-index: -1;
  opacity: .23;
}

.reviews__item.zindex3 {
  z-index: -2;
  opacity: .23;
}

.reviews__item.zindex4 {
  z-index: -3;
  opacity: .23;
}

.reviews__head {
  display: flex;
  margin-bottom: 85px;
  position: relative;
}

.reviews__head__main {
  position: relative;
  box-sizing: border-box;
  padding-top: 30px;
  min-height: 138px;
}

.reviews__head__main:after {
  content: "";
  position: absolute;
  left: 30%;
  bottom: 0;
  width: 80px;
  height: 2px;
  background: #DEE4ED;
}

.reviews__author {
  color: #5479AC;
  margin-bottom: 34px;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
}

.reviews__desc {
  font-size: 18px;
  line-height: 24px;
  font-style: italic;
  color: #5479AC;
}

.reviews__text {
  font-size: 22px;
  line-height: 29px;
  padding-left: 60px;
  padding-right: 20px;
  color: #5479AC;
}

.reviews__photo {
  width: 157px;
  height: 157px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 30px;
  flex-shrink: 0;
}

.reviews .slick-arrow {
  position: absolute;
  bottom: -55px;
  width: 58px;
  height: 58px;
  background: #fff;
  border-radius: 4px;
  font-size: 0;
}

.reviews .slick-prev {
  left: 37%;
  background-image: url(../img/arrow-left.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.reviews .slick-next {
  right: 37%;
  background-image: url(../img/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.section-reviews {
  padding-top: 144px;
  padding-bottom: 200px;
}

.section-reviews .section-title {
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 20px;
}

.section-reviews .next-sep {
  margin-bottom: 51px;
}

.footer {
  background: #2699FB;
  color: #fff;
  padding-top: 66px;
  padding-bottom: 66px;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  max-width: 1560px;
}

.footer__section1 {
  display: flex;
  width: 1090px;
}

.footer__logotypes {
  width: 161px;
  margin-right: 135px;
}

.footer__logo {
  padding-bottom: 2px;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.footer__logo2 {
  text-align: center;
}

.footer__menu {
  list-style: none;
  columns: 2;
  width: 480px;
  margin-right: 30px;
}

.footer__menu > li {
  margin-bottom: 4px;
}

.footer__menu > li > a {
  text-decoration: none;
  color: #fff;
  text-decoration: none;
}

.footer__socials {
  display: flex;
  align-items: flex-start;
  padding-top: 00px;
}

.subscribe {
  width: 285px;
}

.subscribe__form {
  position: relative;
  margin-bottom: 15px;
}

.subscribe__form input[type="text"] {
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  border: 2px solid #fff;
  border-radius: 6px;
  background: transparent;
  color: #fff;
}

.subscribe__form ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.subscribe__form ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.subscribe__form :-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.subscribe__form :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.subscribe__text {
  font-size: 13px;
}

.vis-mob {
  display: none;
}

.popup {
  opacity: 0;
  visibility: hidden;
  transition: .5s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 150;
}

.popup__mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.popup__wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

.popup__container {
  position: relative;
  z-index: 20;
  display: inline-block;
  box-sizing: border-box;
  background: #2699FB;
  width: 100%;
  max-width: 367px;
  border-radius: 20px;
  margin-top: 80px;
  margin-bottom: 80px;
  transition: .5s;
  text-align: center;
  color: #fff;
  padding: 34px;
}

.popup__icon {
  margin-bottom: 16px;
}

.popup__desc {
  margin-bottom: 22px;
}

.popup__title {
  text-transform: uppercase;
  font-size: 13px;
  line-height: 14px;
  margin-bottom: 5px;
  font-weight: 700;
}

.popup__btn .btn {
  width: 100%;
}

.popup__field {
  margin-bottom: 20px;
}

.popup__field input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 30px;
  font-size: 15px;
  background: #fff;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
}

.popup.active {
  opacity: 1;
  visibility: visible;
}

.popup__close {
  position: absolute;
  right: 23px;
  top: 23px;
  cursor: pointer;
  transition: .3s;
}

.popup__close:before {
  content: "";
  display: inline-block;
  color: #fff;
  width: 22px;
  height: 22px;
  background: url(../img/icon-cross.svg);
  transition: .3s;
}

.popup ::-webkit-input-placeholder {
  color: #B9CEDF;
}

.popup ::-moz-placeholder {
  color: #B9CEDF;
}

.popup :-moz-placeholder {
  color: #B9CEDF;
}

.popup :-ms-input-placeholder {
  color: #B9CEDF;
}

.popup-opened {
  overflow: hidden;
}

.popup-opened .wrapper * {
  filter: blur(5px);
}

.win-enter {
  color: #fff;
  background: #2699FB;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  z-index: 300;
  transition: .4s;
}

.win-enter__top {
  background: #FF7484;
  position: relative;
  text-align: center;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.win-enter__top .btn_back {
  position: absolute;
  left: 35px;
  top: 10px;
}

.win-enter__logo {
  width: 220px;
  margin: 0 auto 100px;
}

.win-enter__field {
  width: 327px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
}

.win-enter__field input {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
}

.win-enter__field ::-webkit-input-placeholder {
  color: #ACD9FF;
}

.win-enter__field ::-moz-placeholder {
  color: #ACD9FF;
}

.win-enter__field :-moz-placeholder {
  color: #ACD9FF;
}

.win-enter__field :-ms-input-placeholder {
  color: #ACD9FF;
}

.win-enter__main {
  text-align: center;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
}

.win-enter__btn {
  width: 327px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
}

.win-enter__btn .btn {
  height: 48px;
  width: 100%;
  font-size: 21px;
  line-height: 21px;
  border-radius: 40px;
}

.win-enter.active {
  opacity: 1;
  visibility: visible;
}

.icon-user {
  display: inline-block;
  background: #fff;
  border-radius: 100%;
  position: relative;
  width: 32px;
  height: 32px;
  margin-bottom: 32px;
}

.icon-user:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 17px;
  height: 17px;
  background: url(../img/icon-user_blue.svg);
}

.win-mob-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 233px;
  padding: 55px 32px 20px;
  height: 100%;
  background: #2699FB;
  box-sizing: border-box;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  z-index: 300;
  transition: .4s;
  box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 0 15px 15px 0;
  transform: translateX(-100%);
}

.win-mob-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.win-mob-menu__close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: .3s;
}

.win-mob-menu__close:before {
  content: "";
  display: inline-block;
  color: #fff;
  width: 22px;
  height: 22px;
  background: url(../img/icon-cross.svg);
  transition: .3s;
}

.win-mob-menu__logo {
  width: 134px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #fff;
}

.win-mob-menu__logo .page-title {
  padding-bottom: 0;
  margin-bottom: 0;
}

.win-mob-menu__nav {
  list-style: none;
  margin-bottom: 50px;
}

.win-mob-menu__nav > li {
  margin-bottom: 25px;
}

.win-mob-menu__nav > li:last-child {
  margin-bottom: 0;
}

.win-mob-menu__nav > li > a {
  color: #fff;
  font-size: 17px;
  text-decoration: none;
}

.win-mob-menu__phone {
  margin-bottom: 30px;
}

.win-mob-menu__phone a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
}

.win-mob-menu__socials {
  text-align: center;
  margin-bottom: 10px;
}

.win-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.48);
  z-index: 250;
  opacity: 0;
  visibility: hidden;
  transition: .4s;
}

.win-overlay.active {
  opacity: 1;
  visibility: visible;
}

.wow {
  visibility: hidden;
}

/*page blog*/
.breadcrumbs {
  padding-bottom: 20px;
  color: #5479AC;
  padding-left: 60px;
}

.breadcrumbs a {
  text-decoration: none;
}

.breadcrumbs a:after {
  content: "/";
  pointer-events: none;
  cursor: default;
  margin-right: -4px;
}

.breadcrumbs a:last-child:after {
  display: none;
}

.page-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-top-bar {
  display: flex;
  align-items: flex-start;
}

.page-title {
  width: 360px;
  box-sizing: border-box;
  font-size: 59px;
  line-height: 59px;
  font-weight: 700;
  color: #fff;
  position: relative;
  padding: 74px 0 30px 60px;
  margin-bottom: 25px;
}

.page-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 487px;
  height: 459px;
  background: url(../img/figure-1.svg) no-repeat;
  background-size: cover;
  z-index: -1;
}

.page-title-symbol {
  margin-left: -15px;
}

.top-links a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  height: 44px;
  padding: 0 40px;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 30px;
}

.top-links a:hover {
  border-color: #5479AC;
}

.search-block {
  width: 375px;
  position: relative;
  margin-left: 45px;
}

.search-block__field input {
  border-radius: 30px;
  border: 1px solid #5479AC;
  border-radius: 30px;
  height: 44px;
  padding: 0 15px 0 70px;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
}

.search-block__field ::-webkit-input-placeholder {
  color: #B9CEDF;
}

.search-block__field ::-moz-placeholder {
  color: #B9CEDF;
}

.search-block__field :-moz-placeholder {
  color: #B9CEDF;
}

.search-block__field :-ms-input-placeholder {
  color: #B9CEDF;
}

.page-preview-img {
  position: relative;
  max-width: 1295px;
  display: flex;
  justify-content: flex-end;
}

.page-preview-img img {
  max-width: 2000px;
}

.page-preview-img__tags {
  position: absolute;
  right: 75px;
  top: 40px;
}

.btns-tag {
  display: flex;
  flex-wrap: wrap;
}

.btns-tag a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px 0 5px;
  font-size: 11px;
  line-height: 11px;
  height: 26px;
  border-radius: 20px;
  padding: 0 10px;
  text-decoration: none;
  font-weight: 700;
  min-width: 82px;
}

.btns-tag a:last-child {
  margin-right: 0;
}

.btns-tag_white a {
  border: 1px solid #fff;
  color: #fff;
}

.btns-tag_theme2 a {
  font-size: 17px;
  color: #B9CEDF;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 10px 20px;
  border: 1px solid #B9CEDF;
  border-radius: 30px;
  font-weight: 700;
}

.btns-tag_theme2 a:hover {
  background: #2699FB;
  color: #fff;
}

.btns-tag_size-large a {
  font-size: 17px;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 10px 20px;
  border-radius: 30px;
  font-weight: 700;
}

.btns-tag_bottom {
  margin-top: 100px;
  margin-left: 275px;
  margin-bottom: 20px;
}

.btns-tag_offset {
  margin-bottom: 50px;
}

.article-author {
  font-size: 15px;
  line-height: 19px;
  color: #5479AC;
  display: flex;
  align-items: center;
}

.article-author__photo {
  border-radius: 100%;
  overflow: hidden;
  width: 60px;
  height: 60px;
  line-height: 0;
  margin-right: 20px;
}

.article-author_end {
  font-size: 10px;
  border-top: 1px solid #D4E1EB;
  margin-left: -75px;
  margin-right: -75px;
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 30px;
}

.article-author__name {
  border-bottom: 1px solid #5479AC;
  font-size: 17px;
  line-height: 20px;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.article-wrapper {
  color: #5479AC;
  position: relative;
  padding-top: 47px;
}

.article-wrapper p {
  font-size: 25px;
  line-height: 33px;
  margin-bottom: 30px;
}

.article-wrapper p:last-child {
  margin-bottom: 0;
}

.article-wrapper blockquote {
  background: #F0F4F8;
  padding: 40px 60px;
  color: #5479AC;
  font-size: 29px;
  line-height: 38px;
  font-weight: 700;
  margin-bottom: 30px;
}

.article-row {
  display: flex;
}

.blockquote-main {
  border-left: 3px solid #B9CEDF;
  padding-left: 25px;
}

.article-top-desc {
  font-size: 22px;
  line-height: 29px;
  font-weight: 700;
  margin-bottom: 22px;
  padding-bottom: 25px;
  position: relative;
}

.article-top-desc:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 205px;
  height: 1px;
  background: #D4E1EB;
}

.first-letter {
  display: inline-block;
  font-size: 90px;
  line-height: 90px;
  margin-right: 25px;
}

.paragraph-spec {
  display: flex;
  align-items: center;
}

.paragraph-main {
  display: inline-block;
}

.text-block {
  background: #fff;
  margin: 0 40px 30px;
  background: #fff;
  max-width: 714px;
  border: 1px solid #B9CEDF;
  padding: 25px 55px 25px;
}

.article-img {
  max-width: 793px;
  box-sizing: border-box;
  margin: 0 auto 50px;
  padding: 45px 45px 20px;
  border-radius: 15px;
  background: #DEE4ED;
}

.article-img__img {
  border-radius: 12px;
  overflow: hidden;
  line-height: 0;
}

.article-img__desc {
  font-size: 17px;
  line-height: 26px;
  color: #8B9DAF;
  border-top: 2px solid #B9CEDF;
  padding-top: 15px;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: -10px;
  margin-right: -10px;
}

.list-info {
  list-style: none;
  font-size: 18px;
  line-height: 24px;
  width: 45%;
  margin: 0 35px 50px;
}

.list-info > li {
  border-bottom: 1px solid #DEE4ED;
  display: flex;
  justify-content: space-between;
  min-height: 45px;
  align-items: center;
}

.list-info-top {
  border-bottom: 1px solid #B9CEDF;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
  font-size: 18px;
  line-height: 24px;
  width: 370px;
  margin-bottom: 15px;
}

.list-info-wrap__row {
  display: flex;
  margin: 0 -35px;
}

.table-def {
  width: 100%;
  border-width: 5px;
  border-collapse: collapse;
}

.table-def th {
  background: #B9CEDF;
  height: 40px;
  padding: 10px;
  border: 5px solid #F9FBFC;
}

.table-def td {
  text-align: center;
  padding: 10px;
  background: #F0F4F8;
  border: 5px solid #F9FBFC;
}

.list-circle {
  font-size: 29px;
  line-height: 39px;
  list-style: none;
  margin-bottom: 30px;
  margin-left: 60px;
}

.list-circle > li {
  position: relative;
  padding-left: 37px;
}

.list-circle > li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 13px;
  width: 12px;
  height: 12px;
  background: #2699FB;
  border-radius: 100%;
}

.list-check {
  font-size: 29px;
  line-height: 39px;
  margin-bottom: 30px;
  margin-left: 60px;
  list-style: none;
}

.list-check > li {
  position: relative;
  padding-left: 37px;
}

.list-check > li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 15px;
  width: 12px;
  width: 14px;
  height: 12px;
  background: url(../img/icon-check2.svg) no-repeat;
  background-size: cover;
}

.list-numeric {
  font-size: 29px;
  line-height: 39px;
  list-style: none;
  margin-bottom: 30px;
  margin-left: 60px;
}

.list-numeric > li {
  position: relative;
  padding-left: 37px;
}

.list-numeric__num {
  position: absolute;
  left: -4px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 31px;
  border-radius: 100%;
  background: #2699FB;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
}

.comments-wrapper {
  background: #F0F4F8;
  color: #5479AC;
  padding: 40px 40px 15px;
}

.comment-item .comment-item {
  padding-left: 80px;
}

.comment-item__info {
  display: flex;
  align-items: center;
}

.comment-item__info__author {
  font-size: 15px;
  font-weight: 700;
  margin-right: 15px;
}

.comment-item__info__date {
  font-size: 11px;
  line-height: 14px;
}

.comment-item__info__ava {
  width: 58px;
  height: 58px;
  border-radius: 12px;
  margin-right: 15px;
}

.comment-item__text {
  font-size: 25px;
  line-height: 33px;
}

.comment-item__main {
  margin-left: 80px;
  border-bottom: 1px solid #D4E1EB;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.comment-item__textarea {
  margin-bottom: 15px;
  margin-top: 25px;
}

.comment-item__textarea textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  border: 1px solid #B9CEDF;
  border-radius: 5px;
  height: 85px;
}

.comment-item__textarea ::-webkit-input-placeholder {
  color: #DEE4ED;
}

.comment-item__textarea ::-moz-placeholder {
  color: #DEE4ED;
}

.comment-item__textarea :-moz-placeholder {
  color: #DEE4ED;
}

.comment-item__textarea :-ms-input-placeholder {
  color: #DEE4ED;
}

.comment-item__public-btn {
  text-align: right;
}

.comment-answer-area {
  margin-top: 45px;
}

.comments-lvl2 {
  padding-left: 80px;
}

.block-wrapper {
  background: #fff;
  color: #5479AC;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.block-wrapper__title {
  text-align: center;
  font-size: 31px;
  line-height: 31px;
  color: #5479AC;
  border-bottom: 2px solid #D4E1EB;
  padding: 20px 0;
  margin-bottom: 30px;
}

.block-wrapper_side {
  padding: 30px;
  margin-bottom: 40px;
}

.block-wrapper_side .block-wrapper__title {
  font-size: 25px;
  line-height: 32px;
  padding-top: 0;
  margin-bottom: 30px;
}

.news-slider {
  margin: 0 -45px;
}

.news-slider_mod2 .item-news {
  margin: 0 30px;
}

.news-slider_mod2.slider-theme .slick-prev {
  left: 0;
}

.news-slider_mod2.slider-theme .slick-next {
  right: 0;
}

.slider-theme .slick-arrow {
  position: absolute;
  width: 38px;
  height: 38px;
  font-size: 0;
  background-color: #fff;
  border-radius: 10px;
  z-index: 100;
}

.slider-theme .slick-prev {
  top: -90px;
  left: 50px;
}

.slider-theme .slick-prev:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 30px;
  background: url(../img/arrow-left.svg) center no-repeat;
  background-size: cover;
}

.slider-theme .slick-next {
  top: -90px;
  right: 50px;
}

.slider-theme .slick-next:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 30px;
  background: url(../img/arrow-right.svg) center no-repeat;
  background-size: cover;
}

.slider-theme .slick-dots {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  font-size: 0;
}

.slider-theme .slick-dots li {
  margin: 0 7px;
}

.slider-theme .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #B9CEDF;
}

.slider-theme .slick-dots li.slick-active button {
  background: #5479AC;
}

.item-news {
  width: 340px;
  max-width: 100%;
  margin: 0 45px;
}

.item-news__title {
  font-size: 23px;
  line-height: 30px;
  font-weight: 700;
  border-bottom: 1px solid #D4E1EB;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.item-news__title a {
  text-decoration: none;
}

.item-news__desc {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}

.item-news__img {
  flex-shrink: 0;
  border-radius: 15px;
  overflow: hidden;
  line-height: 0;
  width: 159px;
}

.item-news__main {
  display: flex;
  justify-content: space-between;
}

.item-news__col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 15px;
}

.item-news_row {
  width: 100%;
}

.item-news_row .item-news__col {
  justify-content: center;
}

.item-news_row .item-news__img {
  margin-right: 25px;
  max-width: 190px;
}

.item-news_row .item-news__title {
  font-size: 20px;
  line-height: 26px;
  border-bottom: 0;
  margin-bottom: 15px;
}

.item-news_side {
  border-bottom: 2px solid #D4E1EB;
  padding-bottom: 25px;
  margin-bottom: 25px;
  width: auto;
  margin-left: 0;
  margin-right: 0;
}

.item-news_side:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.item-news_side .item-news__title {
  font-size: 17px;
  line-height: 22px;
  border-bottom: 0;
}

.item-news_side .item-news__img {
  width: 116px;
  height: 116px;
}

.item-news_side .item-news__col {
  margin-right: 20px;
}

.item-news_news {
  border-bottom: 2px solid #D4E1EB;
  padding-bottom: 25px;
  margin-bottom: 25px;
  margin-left: 0;
}

.item-news_news:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.item-news_news .item-news__title {
  font-size: 17px;
  line-height: 22px;
  border-bottom: 0;
  margin-bottom: 5px;
  min-height: 44px;
}

.item-news_news .link-icon {
  font-size: 10px;
  line-height: 14px;
}

.news-row-wrap {
  display: flex;
  align-items: center;
  border-top: 2px solid #DEE4ED;
  border-bottom: 2px solid #DEE4ED;
  margin-left: -80px;
  padding-left: 80px;
  margin-right: -80px;
  padding-right: 30px;
  margin-top: 70px;
  margin-bottom: 70px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.news-row-wrap > .title {
  font-size: 20px;
  line-height: 26px;
  flex-shrink: 0;
  margin-right: 50px;
}

.table-wrap {
  margin-top: 70px;
  margin-bottom: 70px;
}

.icon {
  display: inline-block;
  line-height: 0;
}

.icon-more {
  width: 16px;
  height: 16px;
  background: url(../img/icon-more.svg) no-repeat;
}

.icon-clock {
  width: 13px;
  height: 13px;
  background: url(../img/icon-clock3.svg) no-repeat;
}

.icon-comments {
  width: 25px;
  height: 25px;
  background: url(../img/icon-comment.svg) no-repeat;
}

.icon-arrow-bot {
  width: 23px;
  height: 12px;
  background: url(../img/icon-arrow-bot.svg) no-repeat;
}

.link-icon {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.link-icon__text {
  text-decoration: underline;
}

.link-icon .icon {
  margin-right: 10px;
}

.link-icon:hover .link-icon__text {
  text-decoration: none;
}

.news-slider2 {
  margin: 0 -15px 35px;
}

.news-slider2 .slick-prev {
  top: auto;
  bottom: -7px;
  left: 30px;
}

.news-slider2 .slick-next {
  top: auto;
  bottom: -7px;
  right: 30px;
}

.news-slider2 .item-news2__details {
  width: 100%;
  height: 100%;
}

.news-slider2 .item-news2__details__section {
  height: 250px;
}

.news-slider2 .item-news2__details__title {
  font-size: 18px;
  line-height: 24px;
}

.item-news2 {
  width: 403px;
  padding: 0 15px 20px;
  position: relative;
}

.item-news2__inner {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.2);
  color: #5479AC;
  position: relative;
}

.item-news2__img {
  display: block;
  border-radius: 10px;
  height: 234px;
  overflow: hidden;
  line-height: 0;
  position: relative;
}

.item-news2__img img {
  width: 100%;
  min-height: 100%;
  max-width: 800px;
}

.item-news2__title {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
  font-size: 25px;
  line-height: 28px;
  font-weight: 700;
  color: #fff;
  padding: 40px 30px 30px 60px;
  background: rgba(42, 95, 141, 0.7);
}

.item-news2__desc {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;
  min-height: 54px;
}

.item-news2__section {
  padding: 30px 60px;
}

.item-news2:hover .item-news2__details {
  opacity: 1;
  visibility: visible;
}

.item-news2__details {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #2699FB;
  border-radius: 6px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.3);
  color: #fff;
  width: calc(100% + 50px);
  height: calc(100% + 80px);
  z-index: 120;
  opacity: 0;
  visibility: hidden;
  transition: .5s;
}

.item-news2__details__title {
  font-size: 25px;
  line-height: 28px;
  font-weight: 700;
}

.item-news2__details__section {
  display: flex;
  align-items: center;
  height: 335px;
  padding: 0 45px;
  overflow: hidden;
}

.item-news2__details__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 45px 0;
  position: relative;
}

.item-news2__details__bar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 11px;
  width: 100%;
  background: url(../img/line-dots.jpg);
}

.item-news2__details__info {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.time-inform {
  font-size: 10px;
  line-height: 13px;
  font-style: italic;
  display: flex;
  align-items: center;
}

.time-inform .icon {
  margin-right: 5px;
}

.content-row {
  display: flex;
}

.sidebar {
  flex-shrink: 0;
  width: 380px;
  margin-left: 40px;
}

.article-top {
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
}

.article-top__inner {
  box-sizing: border-box;
  margin-top: -45px;
  margin-left: -55px;
  background: #F9FBFC;
  position: relative;
  max-width: 1000px;
  padding: 35px 60px;
  margin-bottom: 15px;
}

.article-author-wrapper {
  border-bottom: 2px solid #D4E1EB;
  max-width: 1000px;
  padding-bottom: 30px;
  box-sizing: border-box;
  margin: 0 -130px;
  padding-left: 130px;
  padding-right: 130px;
}

.article-inner {
  max-width: 780px;
  padding-left: 80px;
}

.comments-count {
  font-size: 11px;
  line-height: 11px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.comments-count .icon {
  margin-right: 8px;
}

.comments-count__label {
  font-size: 14px;
  margin-right: 12px;
}

.comments-btn-more {
  text-align: center;
}

.comments-btn-more__main {
  border-bottom: 1px solid #D4E1EB;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.write-comment-wrap {
  margin-left: 275px;
  margin-top: 100px;
}

.side-left {
  width: 275px;
  margin-right: 30px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.informers-wrapper {
  width: 95px;
  position: absolute;
  left: -130px;
  top: 45px;
}

.inform-item-def {
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D4E1EB;
  padding-bottom: 22px;
  margin-bottom: 22px;
}

.inform-item-def:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.inform-item-def__icon {
  width: 26px;
  margin-right: 8px;
}

.inform-item-def__icon img {
  width: 100%;
}

.promo-block {
  display: block;
  background: #0179FF url(../img/banner-bg.png) no-repeat center bottom;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  padding: 40px;
  height: 975px;
  max-width: 273px;
  margin-bottom: 40px;
}

.promo-block__title {
  display: block;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 30px;
  font-weight: 700;
}

.promo-block__desc {
  display: block;
  font-size: 17px;
  line-height: 22px;
  font-weight: 700;
}

.promo-block_large {
  padding-top: 170px;
  height: 1280px;
  box-sizing: border-box;
  max-width: 100%;
  background-image: url(../img/banner-bg_large.png);
}

.promo-block_large .promo-block__title {
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 70px;
}

.promo-block_large .promo-block__desc {
  font-size: 27px;
  line-height: 36px;
}

.promo-block_theme2 {
  background-image: url(../img/mask-form.svg);
  background-position: center;
  height: 265px;
  max-width: 100%;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.promo-block_theme2_mini {
  padding: 30px 30px 30px 55px;
  height: 240px;
}

.promo-block_theme2_mini .promo-block__title {
  font-size: 29px;
  line-height: 38px;
  margin-bottom: 15px;
}

.promo-block_theme2_mini .promo-block__desc {
  font-size: 13px;
  line-height: 17px;
}

.promo-block_mod2 {
  flex-direction: column;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: none;
  margin-top: 100px;
}

.promo-block_mod2 .promo-block__title {
  display: block;
  width: 100%;
  font-size: 46px;
  line-height: 46px;
  text-align: left;
}

.promo-block_mod2 .promo-block__desc {
  font-size: 26px;
  line-height: 35px;
}

.promo-block_gorizontal {
  background-image: none;
  min-width: 100%;
  height: auto;
  padding: 0;
  margin: 50px 0;
}

.promo-block_gorizontal .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promo-block_gorizontal .promo-block__title {
  font-size: 41px;
  line-height: 41px;
  margin-bottom: 8px;
}

.promo-block_gorizontal .promo-block__desc {
  font-size: 28px;
  line-height: 37px;
}

.promo-block_gorizontal .promo-block__desc-text-size2 {
  font-size: 31px;
}

.promo-block__col1 {
  display: flex;
  align-items: center;
}

.promo-block__col1__main {
  width: 700px;
  margin-left: 30px;
}

.side-title {
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  text-align: center;
  border-bottom: 2px solid #5479AC;
  text-transform: uppercase;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.btn-wrapper {
  text-align: center;
  margin-top: 50px;
}

.content-main {
  width: 100%;
  flex-grow: 1;
  min-width: 0;
}

.content-main_blog .news-wrapper {
  margin: 0 -20px;
}

.content-main_blog .item-news2 {
  padding: 0 20px 48px;
  width: 33.3%;
  box-sizing: border-box;
}

.content-main_blog .item-news2__inner {
  width: auto;
}

.content-main_blog .block-wrapper {
  margin-bottom: 48px;
}

.win-search {
  opacity: 0;
  visibility: hidden;
  background: #FF7484;
  position: fixed;
  width: 100%;
  top: 48px;
  z-index: 100;
  transition: .5s;
}

.win-search.active {
  opacity: 1;
  visibility: visible;
}

.win-search__main {
  display: flex;
  box-sizing: border-box;
  padding: 10px 30px;
}

.win-search__field {
  flex-grow: 1;
  width: 100%;
  margin-right: 15px;
  box-sizing: border-box;
}

.win-search__field input {
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  border-radius: 23px;
  padding-left: 20px;
  padding-right: 20px;
  background: #fff;
}

.win-search__field ::-webkit-input-placeholder {
  color: #B9CEDF;
}

.win-search__field ::-moz-placeholder {
  color: #B9CEDF;
}

.win-search__field :-moz-placeholder {
  color: #B9CEDF;
}

.win-search__field :-ms-input-placeholder {
  color: #B9CEDF;
}

.bg-figure {
  position: absolute;
  right: 0;
  top: -250px;
  width: 1800px;
  height: 1890px;
  z-index: -1;
  overflow: hidden;
}

.bg-figure img {
  position: relative;
  right: -270px;
  width: 100%;
}

.news-slider3 {
  margin: 0 -30px;
}

.news-slider3 .slick-list {
  width: calc(100% + 500px);
}

.news-slider3 .slick-arrow {
  top: 345px;
  z-index: 100;
}

.news-slider3 .slick-prev {
  left: -20px;
}

.news-slider3 .slick-next {
  right: 10px;
}

.item-news3 {
  padding: 0 30px 60px;
}

.item-news3__inner {
  width: 815px;
  border-radius: 10px 10px 5px 5px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.16);
}

.item-news3__img {
  position: relative;
  height: 260px;
  overflow: hidden;
  text-align: center;
}

.item-news3__img img {
  height: 260px;
  max-width: none;
}

.item-news3__title {
  margin-bottom: 20px;
}

.item-news3__title a {
  font-weight: 700;
  font-size: 38px;
  line-height: 50px;
  color: #5479AC;
  text-decoration: none;
}

.item-news3__desc {
  font-size: 25px;
  line-height: 33px;
  color: #5479AC;
}

.item-news3__tags {
  position: absolute;
  right: 35px;
  top: 65px;
}

.item-news3__bar {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.item-news3__section {
  padding: 30px 65px;
}

.item-news3__informers {
  display: flex;
  align-items: center;
}

.item-news3__informers .inform-item-def {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  color: #5479AC;
  margin-right: 15px;
}

.item-news3__link-more a {
  font-size: 18px;
  line-height: 24px;
}

.news-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.blog-section {
  position: relative;
}

.inform-item-white {
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
  margin: 8px 10px;
}

.inform-item-white__icon {
  margin-right: 10px;
  line-height: 0;
}

.nice-select {
  display: inline-block;
  vertical-align: top;
  -webkit-tap-highlight-color: transparent;
  color: #8B9DAF;
  border: 1px solid #BEBEBE;
  border-radius: 4px;
  padding: 2px 15px 3px;
  width: 100%;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  outline: none;
  position: relative;
  text-align: center;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
}

.nice-select:after {
  content: "";
  position: absolute;
  right: 6px;
  top: 10px;
  border: 7px solid transparent;
  border-top: 7px solid #8B9DAF;
}

.nice-select > span {
  position: relative;
  padding-right: 13px;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}

.nice-select.open .list {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  border: 1px solid #BEBEBE;
  border-radius: 0 0 4px 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  z-index: 9;
  transition: .3s;
  transform: translateY(-5px);
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 15px;
  list-style: none;
  outline: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.nice-select .option:hover {
  background: #F6F7F9;
}

.nice-select .option.selected {
  color: #5479AC;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

@media (max-width: 1770px) {
  .write-comment-wrap {
    margin-left: 0;
  }
  .page-preview-img img {
    max-width: 100%;
  }
  .article-inner {
    padding-left: 30px;
  }
  .news-slider3 .slick-prev {
    left: 30px;
  }
  .news-slider3 .slick-next {
    right: 30px;
  }
}

@media (max-width: 1600px) {
  .side-left {
    display: none;
  }
  .article-inner {
    padding-left: 0;
  }
  .article-author-wrapper {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .article-wrapper {
    padding-top: 30px;
  }
  .informers-wrapper {
    position: static;
    border-bottom: 1px solid #FF7484;
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .informers-wrapper .inform-item-def {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
    margin-right: 20px;
  }
}

@media (max-width: 1530px) {
  .differences2__img {
    display: flex;
    justify-content: flex-end;
  }
  .differences2__img__inner {
    flex-shrink: 0;
  }
  .content-main_blog .item-news2 {
    width: 50%;
  }
  .promo-block_gorizontal .btn_theme5_large {
    font-size: 22px;
    line-height: 26px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 300px;
    flex-shrink: 0;
  }
  .promo-block_gorizontal .promo-block__title {
    font-size: 30px;
    line-height: 36px;
  }
  .promo-block_gorizontal .promo-block__desc-text-size2 {
    font-size: 22px;
    line-height: 24px;
  }
  .promo-block_gorizontal .promo-block__desc {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 1350px) {
  .block-more {
    margin-right: -35px;
    margin-left: -35px;
  }
  .block-request {
    border-radius: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
  .block-request__left {
    flex-shrink: 1;
    margin-right: 20px;
  }
  .block-request__form {
    width: 60%;
  }
  .block-request__title {
    font-size: 22px;
    line-height: 22px;
  }
}

@media (max-width: 1229px) {
  .start__title {
    font-size: 34px;
    line-height: 44px;
  }
  .advantages__item {
    margin-right: 1.3%;
    margin-left: 1.3%;
    width: 30.7%;
  }
  .advantages__text {
    padding-left: 0;
    padding-right: 0;
  }
  .tariffs__item {
    margin-left: 1%;
    margin-right: 1%;
    width: 23%;
  }
  .footer__logotypes {
    margin-right: 60px;
  }
  .footer__menu {
    width: 350px;
    margin-right: 15px;
  }
  .header_inner .menu > li {
    margin-right: 20px;
  }
  .header_inner .menu > li > a {
    font-size: 15px;
  }
  .header_inner .header__call {
    font-size: 15px;
  }
  .header_inner .header__section-nav {
    padding-right: 0;
  }
  .top-links a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .search-block {
    flex-shrink: 0;
    width: 270px;
    margin-left: 20px;
    box-sizing: border-box;
  }
  .article-top__inner {
    margin-left: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
  .title_large {
    font-size: 34px;
    line-height: 40px;
  }
  .comments-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .comment-item__text {
    font-size: 20px;
    line-height: 28px;
  }
  .sidebar {
    width: 250px;
  }
  .sidebar .promo-block_theme2 {
    padding: 30px;
  }
  .sidebar .block-wrapper_side {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sidebar .item-news_side .item-news__img {
    width: 70px;
    height: 70px;
  }
  .sidebar .item-news {
    width: 100%;
  }
  .sidebar .promo-block_mod2 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .sidebar .promo-block_mod2 .promo-block__title {
    font-size: 30px;
    line-height: 30px;
  }
  .sidebar .promo-block_mod2 .promo-block__desc {
    font-size: 22px;
    line-height: 28px;
  }
  .news-row-wrap {
    margin-right: 0;
    margin-left: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
  .promo-block_large {
    padding-left: 15px;
    padding-right: 15px;
  }
  .promo-block_large .promo-block__title {
    font-size: 36px;
    line-height: 42px;
  }
  .promo-block_large .promo-block__desc {
    font-size: 22px;
    line-height: 26px;
  }
  .promo-block__col1__main {
    width: 400px;
  }
}

@media (max-width: 1150px) {
  .differences1__img__inner {
    right: 0;
    padding: 0 30px;
  }
  .section-app {
    padding-top: 150px;
  }
  .app__title {
    font-size: 36px;
    line-height: 42px;
  }
}

@media (max-height: 620px) {
  .win-enter__logo {
    margin-bottom: 50px;
  }
  .win-enter__btn {
    margin-top: 40px;
  }
}

@media (max-width: 992px) {
  .start__title {
    font-size: 30px;
    line-height: 38px;
    margin-right: 300px;
    padding-top: 220px;
  }
  .start__site-block {
    width: 300px;
    right: 0;
  }
  .start__site-block__inner {
    left: 30px;
    right: 30px;
  }
  .start__site-block__text {
    padding-left: 85px;
  }
  .block-request {
    flex-direction: column;
  }
  .block-request__left {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .block-request__form {
    width: 100%;
    justify-content: space-around;
  }
  .block-request__title {
    font-size: 22px;
    line-height: 22px;
  }
  .accounting__img {
    left: 0;
  }
  .differences2__img__inner {
    right: -50px;
    transform: scale(0.8);
    transform-origin: right center;
  }
  .list-advantages > li {
    font-size: 20px;
    line-height: 25px;
  }
  .benefits__item__main {
    padding: 0 20px 20px;
  }
  .benefits__item__text {
    font-size: 20px;
    line-height: 26px;
    height: auto;
    min-height: 200px;
  }
  .partners__item {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: .7%;
    margin-right: .7%;
    width: 23.6%;
  }
  .how-work {
    flex-direction: column;
  }
  .how-work__desc {
    width: 100%;
    margin-right: 0;
  }
  .how-work__img {
    display: none;
  }
  .how-work__bg-figure {
    display: none;
  }
  .how-work__title {
    font-size: 19px;
    line-height: 25px;
    text-align: center;
    width: 100%;
  }
  .how-work__inform__item {
    position: relative;
  }
  .how-work__inform__item:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -26px;
    height: 1px;
    width: 178px;
    background: #DEE4ED;
  }
  .how-work__inform__item:last-child:after {
    display: none;
  }
  .how-work__inform__item__icon {
    margin-right: 20px;
  }
  .chat-example {
    transform: scale(0.8);
    transform-origin: center top;
    margin-bottom: -200px;
  }
  .section-title {
    font-size: 30px;
    line-height: 40px;
  }
  .section-title br {
    display: none;
  }
  .branches {
    justify-content: center;
  }
  .branches__item {
    margin: 0 20px 40px;
  }
  .advantages__item {
    width: 47.4%;
    margin-bottom: 30px;
  }
  .work-for-you {
    transform: scale(0.7);
    transform-origin: center 0;
    margin-bottom: -270px;
  }
  .you-get__item__img {
    transform: scale(0.7);
    transform-origin: center;
    margin: 0 -20px;
  }
  .you-get__item__icon {
    margin-right: 30px;
  }
  .tariffs {
    flex-wrap: wrap;
  }
  .tariffs__item {
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 30px;
    width: 48%;
  }
  .app__title {
    font-size: 28px;
    line-height: 34px;
  }
  .app__col {
    margin-right: 15px;
    width: 55%;
    flex-shrink: 0;
  }
  .footer .container {
    flex-direction: column;
  }
  .footer__section1 {
    flex-direction: column;
    width: 100%;
  }
  .footer__logotypes {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer__logotypes > div {
    flex-shrink: 0;
  }
  .footer__logo {
    padding-right: 25px;
    margin-right: 30px;
    width: 146px;
    border-right: 2px solid rgba(255, 255, 255, 0.5);
    border-bottom: 0;
  }
  .footer__menu {
    width: 100%;
    columns: 1;
    margin: 10px auto;
    max-width: 340px;
  }
  .footer__socials {
    margin-top: 15px;
    justify-content: center;
  }
  .subscribe {
    width: 340px;
    max-width: 100%;
    margin: 0 auto;
  }
  .footer {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .header__lvl1 {
    display: none;
  }
  .header__lvl2 {
    display: none;
  }
  .header__mob {
    display: block;
  }
  .btns-tag_bottom {
    margin-left: 0;
  }
  .wrapper {
    padding-top: 52px;
  }
  .page-title {
    font-size: 36px;
    line-height: 36px;
    padding-top: 15px;
    padding-left: 0px;
    padding-bottom: 20px;
    width: 140px;
  }
  .page-title:after {
    left: -160px;
  }
  .page-title .page-title-symbol {
    display: inline-block;
    transform: scale(0.7);
    transform-origin: 0 bottom;
  }
  .breadcrumbs {
    padding-left: 30px;
  }
  .page-top-bar .search-block {
    display: none;
  }
  .top-links a {
    padding: 0;
    font-size: 10px;
    border: 0;
    margin-right: 15px;
    position: relative;
    height: auto;
  }
  .top-links a:after {
    content: "";
    position: absolute;
    right: -9px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 7px;
    background: #5479AC;
  }
  .top-links a:last-child:after {
    display: none;
  }
  .content-row {
    flex-wrap: wrap;
  }
  .sidebar {
    width: 100%;
    margin-left: 0;
    margin-top: 25px;
  }
  .article-inner {
    max-width: 100%;
  }
  .hide-tablet {
    display: none;
  }
  .item-news2 .btn_theme7 {
    width: 150px;
  }
  .news-slider3 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .item-news3 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .item-news3__inner {
    width: 355px;
    position: relative;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
  }
  .item-news3__img {
    height: 165px;
  }
  .item-news3__img img {
    height: 165px;
  }
  .item-news3__section {
    padding: 15px 20px;
  }
  .item-news3__tags {
    display: none;
  }
  .item-news3__title {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 165px;
    display: flex;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
  }
  .item-news3__title a {
    color: #fff;
    font-size: 24px;
    line-height: 31px;
  }
  .item-news3__desc {
    font-size: 12px;
    line-height: 15px;
  }
  .item-news3__link-more a {
    font-size: 13px;
    line-height: 16px;
  }
  .item-news3__bar {
    margin-top: 10px;
  }
  .item-news3 .inform-item-def__icon {
    width: 18px;
  }
}

@media (max-width: 800px) {
  .work-for-you {
    margin-left: -50px;
    margin-right: -50px;
  }
}

@media (max-width: 767px) {
  .container {
    padding-left: 35px;
    padding-right: 35px;
  }
  .section-start {
    background: transparent;
    min-height: 100%;
    padding-bottom: 50px;
  }
  .start {
    display: flex;
    flex-direction: column;
  }
  .start__title {
    font-size: 24px;
    line-height: 37px;
    color: #5479AC;
    order: 2;
    padding-top: 0;
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
    text-align: center;
  }
  .start__site-block {
    width: calc(100% + 110px);
    position: relative;
    height: 160px;
    margin-left: -35px;
    margin-bottom: 177px;
    background: #2699FB;
    border-radius: 0 0 250px 0;
    background-position: -50px bottom;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .start__site-block__text {
    position: absolute;
    left: 0;
    bottom: -70px;
    width: 100%;
    text-align: center;
    color: #5479AC;
    padding-left: 35px;
    padding-right: 70px;
    box-sizing: border-box;
  }
  .start__site-block__text:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    margin-left: -17px;
    width: 36px;
    height: 1px;
    background: #5479AC;
  }
  .start__site-block__inner {
    bottom: 30px;
    max-width: 257px;
    position: static;
    margin-left: -55px;
  }
  .start__logotypes {
    display: flex;
  }
  .block-request {
    margin-top: 0;
    flex-direction: row;
    padding-top: 22px;
    padding-bottom: 22px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.3);
  }
  .block-request__title {
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 0;
  }
  .block-request__title2 {
    display: none;
  }
  .block-request__form {
    display: none;
  }
  .block-request__btn {
    flex-shrink: 0;
    display: block;
  }
  .block-request__left {
    margin-bottom: 0;
    margin-right: 15px;
  }
  .accounting {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }
  .accounting__img {
    margin: 0 auto -140px;
    order: 2;
    transform: scale(0.6);
    width: 235px;
    transform-origin: center top;
    text-align: center;
  }
  .accounting__main {
    width: 100%;
    text-align: center;
  }
  .accounting__block {
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .accounting__block__bg {
    display: none;
  }
  .accounting__title {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 35px;
  }
  .accounting__title2 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .hide-mob {
    display: none;
  }
  .vis-mob {
    display: block;
  }
  .btn_theme2_large {
    height: 53px;
    width: 290px;
    font-size: 18px;
    line-height: 18px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  .section-accounting {
    padding-top: 50px;
  }
  .section-accounting .next-sep {
    display: none;
  }
  .differences1__cols {
    flex-direction: column;
  }
  .differences1__col {
    width: 100%;
  }
  .differences1__main__inner {
    max-width: 100%;
  }
  .differences1__main__item {
    height: 110px;
    font-size: 15px;
    line-height: 19px;
    margin-left: 0;
    padding-left: 80px;
    padding-right: 15px;
  }
  .differences1__main__icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #8B9DAF;
  }
  .differences1__img {
    padding-top: 0;
  }
  .differences1__img__main {
    max-width: 175px;
    margin: 0 auto -20px;
  }
  .differences1__img .title {
    font-size: 22px;
    line-height: 26px;
  }
  .next-sep_theme2 .next-sep__icon {
    width: 19px;
    height: 19px;
    background: #DEE6EF;
    margin-bottom: 10px;
  }
  .next-sep_theme2 .next-sep__icon:before {
    width: 11px;
    height: 6px;
    background-size: cover;
  }
  .next-sep_theme2 .next-sep__line {
    height: 20px;
    background: #DEE4ED;
  }
  .next-sep__icon {
    width: 19px;
    height: 19px;
    background: #DEE6EF;
    margin-bottom: 10px;
  }
  .next-sep__icon:before {
    width: 11px;
    height: 6px;
    background-size: cover;
  }
  .next-sep__line {
    height: 20px;
  }
  .section-differences1 {
    padding-bottom: 30px;
  }
  .section-differences1 .section-title {
    margin-bottom: 30px;
  }
  .section-differences1 .next-sep {
    margin-bottom: 10px;
  }
  .differences2__cols {
    flex-direction: column;
  }
  .differences2__cols .block-logo {
    position: relative;
    width: 60px;
    height: 60px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 15px;
  }
  .differences2__cols .block-logo:after {
    display: none;
  }
  .differences2__cols .block-logo img {
    max-width: 60%;
  }
  .differences2__main {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0;
  }
  .differences2__main__bg-figure {
    display: none;
  }
  .differences2__img {
    display: none;
  }
  .differences2__grafic {
    display: none;
  }
  .list-advantages {
    margin-left: 0;
  }
  .list-advantages > li {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 24px;
    padding-left: 40px;
    height: auto;
  }
  .list-advantages > li:last-child {
    margin-bottom: 0;
  }
  .list-advantages > li:before {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    box-shadow: none;
  }
  .list-advantages > li:after {
    display: none;
  }
  .section-differences2 {
    padding-bottom: 30px;
  }
  .section-differences2 .next-sep {
    margin-bottom: 10px;
  }
  .section-differences2 .section-title {
    margin-bottom: 60px;
  }
  .block-more__title {
    font-size: 12px;
    line-height: 14px;
  }
  .block-more__shadow {
    display: none;
  }
  .block-more .btn_arrow {
    display: none;
  }
  .benefits {
    margin-bottom: 20px;
  }
  .benefits__item {
    width: 45%;
  }
  .benefits__item__icon {
    margin-top: 0;
    margin-bottom: 20px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    width: 60px;
    height: 60px;
    border-radius: 25px 10px 25px 10px;
  }
  .benefits__item__icon img {
    max-width: 60%;
  }
  .benefits__item__title {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 30px;
  }
  .benefits__item__text {
    font-size: 11px;
    line-height: 13px;
    min-height: 90px;
    border-top: 0;
  }
  .benefits__item__main {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
  .benefits__plus {
    margin: 5% -15px 0;
    width: 110px;
  }
  .offer-btn {
    margin-bottom: 23px;
  }
  .offer-btn__title {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .btn_theme4 {
    height: 38px;
    width: 238px;
    font-size: 13px;
    line-height: 16px;
  }
  .section-benefit {
    padding-top: 10px;
  }
  .section-benefit .section-title {
    margin-bottom: 10px;
  }
  .section-benefit .next-sep {
    margin-bottom: 10px;
  }
  .section-about {
    padding-top: 30px;
  }
  .section-about .section-title {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 25px;
  }
  .section-about .next-sep {
    margin-bottom: 10px;
  }
  .section-about .line-sep {
    display: none;
  }
  .partners {
    background: #F0F4F8;
    margin: 0 -35px;
    padding-left: 35px;
    padding-right: 35px;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .partners__item {
    background: transparent;
    text-align: center;
    width: 100%;
    margin: 0 0 30px;
    box-shadow: none;
    height: auto;
    max-width: 165px;
  }
  .partners__item:last-child {
    margin-bottom: 0;
  }
  .garanty {
    margin-top: -30px;
    transform: scale(0.5);
    transform-origin: center top;
  }
  .garanty__icon {
    right: -75px;
  }
  .garanty__icon__text2 {
    font-size: 14px;
    line-height: 18px;
  }
  .chat-example {
    transform: scale(0.46);
    margin-bottom: -500px;
  }
  .icon-gradient-block_size-medium, .icon-gradient-block_size-small {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  }
  .icon-gradient-block_size-medium img, .icon-gradient-block_size-small img {
    max-width: 80%;
  }
  .section-how-work {
    padding-top: 10px;
  }
  .section-how-work .section-title {
    margin-bottom: 25px;
  }
  .section-how-work .next-sep {
    margin-bottom: 20px;
  }
  .section-easy .section-title {
    margin-bottom: 30px;
  }
  .section-easy .next-sep {
    margin-bottom: 10px;
  }
  .section-video {
    padding-bottom: 0;
  }
  .section-video .icon-top {
    margin-bottom: 20px;
  }
  .section-video .section-title {
    margin-bottom: 15px;
  }
  .video {
    height: 192px;
    max-width: 340px;
    border-radius: 25px;
  }
  .video__overlay img {
    max-width: 100%;
  }
  .video__overlay__play {
    width: 63px;
    height: 63px;
  }
  .video__overlay__play:before {
    width: 18px;
    height: 22px;
    background-size: cover;
  }
  .video-time {
    transform: scale(0.7);
    transform-origin: center top;
    margin-bottom: 0;
  }
  .section-branches {
    padding-top: 45px;
  }
  .section-branches__illustration {
    height: 246px;
    position: relative;
    top: -220px;
    margin-top: 250px;
    margin-bottom: -180px;
  }
  .section-branches__illustration img {
    height: 100%;
  }
  .section-branches__main {
    padding-top: 0;
  }
  .section-branches .section-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 0;
  }
  .branches {
    padding-left: 35px;
    padding-right: 35px;
  }
  .branches__item {
    width: 50%;
    margin: 0 0 20px;
    padding-bottom: 15px;
    border-bottom: 2px solid #DEE4ED;
    position: relative;
  }
  .branches__item:nth-child(2n):after, .branches__item:last-child:after {
    display: none;
  }
  .branches__item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
  .branches__item__block {
    background-color: transparent;
    height: auto;
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
  }
  .branches__item__icon {
    margin: 0 auto 6px;
  }
  .branches__item:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 67px;
    background: #DEE4ED;
  }
  .advantages__item {
    margin: 0 0 30px;
    width: 100%;
    min-height: 100%;
  }
  .advantages__item__line {
    margin-bottom: 20px;
  }
  .advantages__icon img {
    max-width: 92px;
  }
  .advantages__title {
    margin-bottom: 20px;
  }
  .advantages__text {
    font-size: 15px;
    line-height: 19px;
  }
  .subtitle {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .section-advantages .section-title {
    margin-bottom: 30px;
  }
  .section-work-for-you {
    display: none;
  }
  .you-get__item {
    margin-bottom: 30px;
  }
  .you-get__item__img {
    transform: scale(0.4);
    margin: -50px -80px -50px -90px;
  }
  .you-get__item__icon {
    width: 18px;
    height: 18px;
    margin-right: 20px;
  }
  .you-get__item__icon img {
    max-width: 55%;
  }
  .you-get__item__text {
    font-size: 15px;
    line-height: 19px;
  }
  .you-get__item_spec .you-get__item__img {
    margin-bottom: -100px;
  }
  .you-get__item_spec .you-get__item__text {
    margin-bottom: 0;
  }
  .btn_theme2_medium {
    font-size: 10px;
    line-height: 14px;
    height: 44px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  .section-you-get {
    padding-top: 35px;
  }
  .section-you-get .next-sep {
    margin-bottom: 5px;
  }
  .section-you-get .section-title {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 26px;
  }
  .section-tariffs__main {
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
  }
  .tariffs {
    margin-bottom: 20px;
  }
  .tariffs__item {
    box-shadow: none;
    margin: 0 2% 12px;
    width: 46%;
    border: 1px solid #F0F4F8;
    box-sizing: border-box;
    box-shadow: none;
    padding: 0;
    overflow: visible;
  }
  .tariffs__item .nice-select {
    background: #fff;
    border: 0;
    font-size: 12px;
    line-height: 14px;
  }
  .tariffs__item .nice-select:after {
    top: 8px;
    border: 5px solid transparent;
    border-top: 4px solid #8B9DAF;
  }
  .tariffs__item__list {
    font-size: 15px;
    line-height: 19px;
  }
  .tariffs__item__list > li:before {
    top: 0;
  }
  .tariffs__item__top {
    background: transparent;
    box-shadow: none;
    font-size: 13px;
    line-height: 18px;
    height: auto;
    padding-top: 15px;
  }
  .tariffs__item__top__btn {
    display: none;
  }
  .tariffs__item__main {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
  .tariffs__item__section {
    padding: 10px 20px 10px;
  }
  .tariffs__item__section-price {
    background: #F0F4F8;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .tariffs__item__price {
    color: #5479AC;
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 30px;
    line-height: 30px;
  }
  .tariffs__item__price-text {
    font-size: 12px;
    line-height: 12px;
    color: #5479AC;
  }
  .section-tariffs {
    padding-top: 40px;
  }
  .section-tariffs .next-sep {
    margin-bottom: 20px;
  }
  .section-tariffs .next-sep__line {
    background: #DEE4ED;
  }
  .text-btn {
    flex-direction: column;
    align-items: center;
  }
  .text-btn__text {
    font-size: 17px;
    line-height: 21px;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .text-btn .btn_arrow {
    opacity: .28;
  }
  .section-connect {
    padding-top: 50px;
  }
  .section-connect .section-title {
    margin-bottom: 35px;
    font-size: 22px;
    line-height: 25px;
  }
  .form-connect {
    max-width: 300px;
    border: 0;
    background: none;
    padding: 0;
  }
  .form-connect__title {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    padding-left: 10px;
  }
  .form-connect__field-title {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding-left: 15px;
  }
  .form-connect__block {
    margin-bottom: 20px;
  }
  .form-connect__btn {
    margin-top: 40px;
  }
  .form-connect__field input {
    background: #F0F4F8;
    border-color: #E3E8F0;
    border-radius: 30px;
    height: 45px;
  }
  .btn_theme2_medium2 {
    height: 45px;
    font-size: 15px;
    line-height: 20px;
    width: 240px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  .app {
    flex-direction: column;
  }
  .app__img {
    display: none;
  }
  .app__col {
    width: 100%;
    padding-bottom: 0;
  }
  .app__title {
    text-align: center;
  }
  .app__title2 {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 30px;
  }
  .app__btns {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }
  .app__btns .btn {
    margin-bottom: 20px;
  }
  .app__logo-wrap {
    max-width: 100%;
  }
  .app .block-logo {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 30px;
  }
  .section-app {
    padding-top: 90px;
  }
  .section-mass-media {
    display: none;
  }
  .section-reviews {
    padding-top: 45px;
    padding-bottom: 30px;
  }
  .section-reviews .next-sep {
    margin-bottom: 10px;
  }
  .reviews__item {
    box-shadow: none;
    background: transparent;
    margin: 0;
    transform: none;
    width: 320px;
    bottom: auto;
  }
  .reviews__head {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
  }
  .reviews__head__main {
    min-height: auto;
    margin-bottom: 35px;
  }
  .reviews__head__main:after {
    display: none;
  }
  .reviews__photo {
    margin-right: 0;
    width: 122px;
    height: 122px;
  }
  .reviews__author {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .reviews__desc {
    font-size: 15px;
    line-height: 20px;
  }
  .reviews__text {
    font-size: 20px;
    line-height: 26px;
    padding: 0;
    text-align: center;
  }
  .reviews .slick-arrow {
    top: 65px;
    z-index: 100;
  }
  .reviews .slick-prev {
    left: 30px;
  }
  .reviews .slick-next {
    right: 30px;
  }
  .btn_to-top {
    top: 100px;
    left: 30px;
  }
  .list-info-top {
    width: 100%;
  }
  .top-links a.hide-mob {
    display: none;
  }
  .page-top {
    padding-right: 10px;
  }
  .page-preview-img {
    display: none;
  }
  .article-top {
    border-top: 2px solid #D4E1EB;
    padding-top: 20px;
  }
  .article-top__inner {
    margin-top: 0;
    padding: 0;
  }
  .title_large {
    font-size: 23px;
    line-height: 30px;
  }
  .article-top-desc {
    font-size: 13px;
    line-height: 20px;
  }
  .article-top-desc:after {
    display: none;
  }
  .article-author-wrapper {
    margin: 0;
    padding: 15px 0;
  }
  .article-wrapper p {
    font-size: 16px;
    line-height: 20px;
  }
  .article-wrapper blockquote {
    font-size: 16px;
    line-height: 21px;
    padding: 30px 25px;
  }
  .article-wrapper .news-row-wrap > .title {
    flex-shrink: 1;
  }
  .article-wrapper .table-def {
    font-size: 12px;
    line-height: 16px;
    width: 380px;
    margin-left: auto;
    margin-right: auto;
  }
  .article-wrapper .table-def th {
    font-size: 10px;
    line-height: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .article-wrapper .table-def td {
    padding-left: 5px;
    padding-right: 5px;
  }
  .article-wrapper .table-wrap {
    margin-left: -30px;
    margin-right: -30px;
  }
  .news-row-wrap {
    flex-wrap: wrap;
    display: block;
  }
  .news-row-wrap .title {
    margin-bottom: 15px;
    margin-right: 0;
    text-align: center;
  }
  .news-row-wrap .item-news {
    margin: 0;
  }
  .news-row-wrap .item-news_row .item-news__img {
    width: 80px;
    height: 80px;
  }
  .news-row-wrap .item-news_row .item-news__title {
    font-size: 15px;
    line-height: 20px;
  }
  .list-circle {
    font-size: 16px;
    line-height: 20px;
    margin-left: 0;
  }
  .list-circle > li {
    margin-bottom: 15px;
  }
  .list-circle > li:before {
    top: 5px;
  }
  .list-check {
    font-size: 16px;
    line-height: 20px;
    margin-left: 0;
  }
  .list-check > li {
    margin-bottom: 15px;
  }
  .list-check > li:before {
    top: 5px;
  }
  .article-author_end {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .list-numeric {
    font-size: 16px;
    line-height: 20px;
    margin-left: 0;
  }
  .list-numeric > li {
    margin-bottom: 15px;
  }
  .list-numeric__num {
    top: -5px;
  }
  .first-letter {
    font-size: 66px;
    line-height: 50px;
    margin-right: 15px;
  }
  .text-block {
    padding: 20px 15px;
    margin-left: 0;
    margin-right: 0;
  }
  .article-img {
    margin-left: -35px;
    margin-right: -35px;
    border-radius: 0;
    padding: 30px;
  }
  .article-img__desc {
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  }
  .list-info-wrap__row {
    flex-wrap: wrap;
    margin: 0;
  }
  .list-info {
    width: 100%;
    margin: 0 0 50px;
  }
  .write-comment-wrap > .title {
    font-size: 12px;
    line-height: 20px;
  }
  .comments-wrapper {
    margin-left: -35px;
    margin-right: -35px;
    padding-left: 35px;
    padding-right: 35px;
  }
  .comment-item__text {
    font-size: 14px;
    line-height: 20px;
  }
  .comment-item__info__ava {
    width: 33px;
    height: 33px;
    margin-right: 7px;
  }
  .comment-item__info__author {
    font-size: 10px;
    line-height: 12px;
  }
  .comment-item__info__date {
    font-size: 10px;
    line-height: 12px;
  }
  .comment-item__main {
    margin-left: 40px;
  }
  .comment-item .comment-item {
    padding-left: 40px;
  }
  .comment-answer-area {
    margin-top: 10px;
  }
  .comments-lvl2 {
    padding-left: 40px;
  }
  .btns-tag_bottom {
    margin-top: 40px;
    margin-left: 0;
  }
  .btns-tag_theme2 a {
    font-size: 12px;
    line-height: 14px;
    height: 33px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .item-news__img {
    width: 80px;
  }
  .item-news__title {
    font-size: 18px;
    line-height: 22px;
  }
  .item-news2 {
    width: 270px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .item-news2__title {
    padding: 15px;
    font-size: 18px;
    line-height: 22px;
  }
  .item-news2__img {
    height: 180px;
  }
  .item-news2__section {
    padding: 15px;
  }
  .item-news2__details {
    display: none;
  }
  .block-wrapper__title {
    font-size: 20px;
    line-height: 26px;
  }
  .bg-figure {
    display: none;
  }
  .blog-section .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .blog-section .sidebar {
    margin-top: 0;
  }
  .news-wrapper_slider-mob {
    margin-left: 0;
    margin-right: 0;
  }
  .news-wrapper_slider-mob .item-news2 {
    width: 160px;
    padding: 0 7px;
    margin: 10px 0;
  }
  .news-wrapper_slider-mob .item-news2__inner {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
  }
  .news-wrapper_slider-mob .item-news2__desc {
    display: none;
  }
  .news-wrapper_slider-mob .item-news2__title {
    font-size: 15px;
    line-height: 20px;
  }
  .news-wrapper_slider-mob .item-news2__img {
    height: 130px;
  }
  .content-main_blog .news-wrapper_slider-mob {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 30px;
  }
  .content-main_blog .news-wrapper_slider-mob .slick-list {
    padding-left: 20px;
  }
  .btns-tag_offset {
    margin-bottom: 30px;
  }
  .promo-block_gorizontal {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .promo-block_gorizontal {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .promo-block_gorizontal .container {
    flex-direction: column;
  }
  .promo-block_gorizontal .promo-block__title {
    font-size: 27px;
    line-height: 36px;
    margin-bottom: 5px;
  }
  .promo-block_gorizontal .promo-block__desc {
    font-size: 13px;
    line-height: 17px;
  }
  .promo-block_gorizontal .promo-block__desc-text-size2 {
    font-size: 13px;
    line-height: 17px;
  }
  .promo-block_gorizontal .promo-block__col1 {
    margin-bottom: 15px;
  }
  .promo-block_gorizontal .promo-block__col1 > img {
    width: 100px;
  }
  .promo-block_gorizontal .promo-block__col1__main {
    width: 300px;
    margin-left: 15px;
  }
  .promo-block_gorizontal .btn_theme5_large {
    font-size: 18px;
    line-height: 22px;
  }
  .news-slider_mod2 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .news-slider_mod2 .item-news {
    margin-left: 15px;
    margin-right: 15px;
  }
}
