@media (max-width: 767px) {

	.container {
		padding-left: 35px;
		padding-right: 35px;
	}
	
	.section-start {
		background: transparent;
		min-height: 100%;
		padding-bottom: 50px;
	}

	.start {
		display: flex;
		flex-direction: column;
		&__title {
			font-size: 24px;
			line-height: 37px;
			color: $blue2;
			order: 2;
			padding-top: 0;
			max-width: 100%;
			width: 100%;
			margin-right: 0;
			margin-bottom: 60px;
			text-align: center;
		}
		&__site-block {
			width: calc(100% + 110px);
			position: relative;
			height: 160px;
			margin-left: -35px;
			margin-bottom: 177px;
			background: $blue;
			border-radius: 0 0 250px 0;
			background-position: -50px bottom;
			display: flex;
			justify-content: center;
			align-items: center;
			&__text {
				position: absolute;
				left: 0;
				bottom: -70px;
				width: 100%;
				text-align: center;
				color: $blue2;
				padding-left: 35px;
				padding-right: 70px;
				box-sizing: border-box;
				&:after {
					content: "";
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: -30px;
					margin-left: -17px;
					width: 36px;
					height: 1px;
					background: $blue2;
				}
			}
			&__inner {
				bottom: 30px;
				max-width: 257px;
				position: static;
				margin-left: -55px;
			}
		}
		&__logotypes {
			display: flex;
		}
	}
	
	.block-request {
		margin-top: 0;
		flex-direction: row;
		padding-top: 22px;
		padding-bottom: 22px;
		align-items: center;
		justify-content: center;
		box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.3);
		&__title {
			font-size: 11px;
			line-height: 15px;
			margin-bottom: 0;
		}
		&__title2 {
			display: none;
		}
		&__form {
			display: none;
		}
		&__btn {
			flex-shrink: 0;
			display: block;
		}
		&__left {
			margin-bottom: 0;
			margin-right: 15px;
		}
	}

	.accounting {
		flex-direction: column;
		align-items: center;
		padding-bottom: 0;
		&__img {
			margin: 0 auto -140px;
			order: 2;
			transform: scale(.6);
			width: 235px;
			transform-origin: center top;
			text-align: center;
		}
		&__main {
			width: 100%;
			text-align: center;
		}
		&__block {
			padding-left: 0;
			padding-bottom: 0;
			margin-bottom: 0;
			&__bg {
				display: none;
			}
		}
		&__title {
			font-size: 20px;
			line-height: 26px;
			text-align: center;
			margin-bottom: 35px;
		}
		&__title2 {
			font-size: 20px;
			line-height: 26px;
			margin-bottom: 20px;
		}
	}

	.hide-mob {
		display: none;
	}

	.vis-mob {
		display: block;
	}

	.btn_theme2 {
		&_large {
			height: 53px;
			width: 290px;
			font-size: 18px;
			line-height: 18px;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
		}
	}

	.section-accounting {
		padding-top: 50px;
		.next-sep {
			display: none;
		}
	}

	.differences1 {
		&__cols {
			flex-direction: column;
		}
		&__col {
			width: 100%;
		}
		&__main {
			&__inner {
				max-width: 100%;
			}
			&__item {
				height: 110px;
				font-size: 15px;
				line-height: 19px;
				margin-left: 0;
				padding-left: 80px;
				padding-right: 15px;
			}
			&__icon {
				position: absolute;
				left: 20px;
				top: 50%;
				transform: translateY(-50%);
				background-color: #8B9DAF;
			}
		}
		&__img {
			padding-top: 0;
			&__main {
				max-width: 175px;
				margin: 0 auto -20px;
			}
			.title {
				font-size: 22px;
				line-height: 26px;
			}
		}
	}
	.next-sep_theme2 {
		.next-sep {
			&__icon {
				width: 19px;
				height: 19px;
				background: #DEE6EF;
				margin-bottom: 10px;
				&:before {
					width: 11px;
					height: 6px;
					background-size: cover;
				}
			}
			&__line {
				height: 20px;
				background: #DEE4ED;
			}
		}		
	}

	.next-sep {
		&__icon {
			width: 19px;
			height: 19px;
			background: #DEE6EF;
			margin-bottom: 10px;
			&:before {
				width: 11px;
				height: 6px;
				background-size: cover;
			}
		}
		&__line {
			height: 20px;
		}
	}

	.section-differences1 {
		padding-bottom: 30px;
		.section-title {
			margin-bottom: 30px;
		}
		.next-sep {
			margin-bottom: 10px;
		}
	}

	.differences2 {
		&__cols {
			flex-direction: column;
			.block-logo {
				position: relative;
				width: 60px;
				height: 60px;
				box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
				border-radius: 15px;
				&:after {
					display: none;
				}
				img {
					max-width: 60%;
				}
			}
		}
		&__main {
			width: 100%;
			padding-top: 0px;
			padding-bottom: 0;
			&__bg-figure {
				display: none;
			}
		} 
		&__img {
			display: none;
		}
		&__grafic {
			display: none;
		}
	}

	.list-advantages {
		margin-left: 0;
		&>li {
			margin-bottom: 25px;
			font-size: 18px;
			line-height: 24px;
			padding-left: 40px;
			height: auto;
			&:last-child {
				margin-bottom: 0;	
			}
			&:before {
				width: 20px;
				height: 20px;
				font-size: 20px;
				line-height: 20px;
				box-shadow: none;
			}
			&:after {
				display: none;
			}
		}
	}

	.section-differences2 {
		padding-bottom: 30px;
		.next-sep {
			margin-bottom: 10px;
		}
		.section-title {
			margin-bottom: 60px;
		}
	}

	.block-more {
		&__title {
			font-size: 12px;
			line-height: 14px;
		}
		&__shadow {
			display: none;
		}
		.btn_arrow {
			display: none;
		}
	}

	.benefits {
		margin-bottom: 20px;
		&__item {
			width: 45%;
			&__icon {
				margin-top: 0;
				margin-bottom: 20px;
				box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
				width: 60px;
				height: 60px;
				border-radius: 25px 10px 25px 10px;
				img {
					max-width: 60%;
				}
			}
			&__title {
				font-size: 11px;
				line-height: 14px;
				margin-bottom: 30px;
			}
			&__text {
				font-size: 11px;
				line-height: 13px;
				min-height: 90px;
				border-top: 0;
			}
			&__main {
				background-color: transparent;
				padding-left: 0;
				padding-right: 0;
			}
		}
		&__plus {
			margin: 5% -15px 0;
			width: 110px;
		}
	}

	.offer-btn {
		margin-bottom: 23px;
		&__title {
			font-size: 15px;
			line-height: 22px;
			margin-bottom: 20px;
		}
	}

	.btn_theme4 {
		height: 38px;
		width: 238px;
		font-size: 13px;
		line-height: 16px;
	}

	.section-benefit {
		padding-top: 10px;
		.section-title {
			margin-bottom: 10px;
		}
		.next-sep {
			margin-bottom: 10px;
		}
	}

	.section-about {
		padding-top: 30px;
		.section-title {
			font-size: 15px;
			line-height: 20px;
			margin-bottom: 25px;
		}
		.next-sep {
			margin-bottom: 10px;
		}
		.line-sep {
			display: none;
		}

	}

	.partners {
		background: #F0F4F8;
		margin: 0 -35px;
		padding-left: 35px;
		padding-right: 35px;
		flex-direction: column;
		align-items: center;
		padding-top: 30px;
		padding-bottom: 30px;
		&__item {
			background: transparent;
			text-align: center;
			width: 100%;
			margin: 0 0 30px;
			box-shadow: none;
			height: auto;
			max-width: 165px;
			&:last-child {
				margin-bottom: 0;	
			}
		}
	}

	.garanty {
		margin-top: -30px;
		transform: scale(.5);
		transform-origin: center top;
		&__icon {
			right: -75px;
			&__text2 {
				font-size: 14px;
				line-height: 18px;
			}
		}
	}

	.chat-example {
		transform: scale(.46);
		margin-bottom: -500px;
	}

	.icon-gradient-block {
		&_size-medium, &_size-small {
			width: 50px;
			height: 50px;
			border-radius: 10px;
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
			img {
				max-width: 80%;
			}
		}
	}

	.section-how-work {
		padding-top: 10px;
		.section-title {
			margin-bottom: 25px;
		}
		.next-sep {
			margin-bottom: 20px;
		}
	}

	.section-easy {
		.section-title {
			margin-bottom: 30px;
		}
		.next-sep {
			margin-bottom: 10px;
		}
	}

	.section-video {
		padding-bottom: 0;
		.icon-top {
			margin-bottom: 20px;
		}
		.section-title {
			margin-bottom: 15px;
		}
	}

	.video {
		height: 192px;
		max-width: 340px;
		border-radius: 25px;
		&__overlay {
			img {
				max-width: 100%;
			}
			&__play {
				width: 63px;
				height: 63px;
				&:before {
					width: 18px;
					height: 22px;
					background-size: cover;
				}
			}
		}
	}

	.video-time {
		transform: scale(.7);
		transform-origin: center top;
		margin-bottom: 0;
	}

	.section-branches {
		padding-top: 45px;
		&__illustration {
			height: 246px;
			position: relative;
			top: -220px;
			margin-top: 250px;
			margin-bottom: -180px;
			img {
				height: 100%;
			}
		}
		&__main {
			padding-top: 0;
		}
		.section-title {
			font-size: 20px;
			line-height: 26px;
			margin-bottom: 0;
		}
	}

	.branches {
		padding-left: 35px;
		padding-right: 35px;
		&__item {
			width: 50%;
			margin: 0 0 20px;
			padding-bottom: 15px;
			border-bottom: 2px solid #DEE4ED;
			position: relative;
			&:nth-child(2n), &:last-child {
				&:after {
					display: none;
				}
			}
			&:last-child {
				border-bottom: 0;
				margin-bottom: 0;
			}
			&__block {
				background-color: transparent;
				height: auto;
				margin: 0;
				font-weight: 700;
				font-size: 14px;
				line-height: 20px;
				box-shadow: none;
			}
			&__icon {
				margin: 0 auto 6px;
			}
			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
				height: 67px;
				background: #DEE4ED;
			}
		}
	}

	.advantages {
		&__item {
			margin: 0 0 30px;
			width: 100%;
			min-height: 100%;
			&__line {
				margin-bottom: 20px;
			}
		}
		&__icon {
			img {
				max-width: 92px;
			}
		}
		&__title {
			margin-bottom: 20px;
		}
		&__text {
			font-size: 15px;
			line-height: 19px;
		}
	}

	.subtitle {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 40px;
	}

	.section-advantages {
		.section-title {
			margin-bottom: 30px;
		}
	}

	.section-work-for-you {
		display: none;
	}

	.you-get {
		&__item {
			margin-bottom: 30px;
			&__img {
				transform: scale(.4);
				margin: -50px -80px -50px -90px;
			}
			&__icon {
				width: 18px;
				height: 18px;
				margin-right: 20px;
				img {
					max-width: 55%;
				}
			}
			&__text {
				font-size: 15px;
				line-height: 19px;
			}
			&_spec {
				.you-get {
					&__item {
						&__img {
							margin-bottom: -100px;
						}
						&__text {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.btn_theme2_medium {
		font-size: 10px;
		line-height: 14px;
		height: 44px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	}

	.section-you-get {
		padding-top: 35px;
		.next-sep {
			margin-bottom: 5px;
		}
		.section-title {
			margin-bottom: 20px;
			font-size: 20px;
			line-height: 26px;
		}
	}

	.section-tariffs {
		&__main {
			padding-top: 0;
			padding-bottom: 0;
			background-color: transparent;
		}
	}

	.tariffs {
		margin-bottom: 20px;
		&__item {
			box-shadow: none;
			margin: 0 2% 12px;
			width: 46%;
			border: 1px solid #F0F4F8;
			box-sizing: border-box;
			box-shadow: none;
			padding: 0;
			overflow: visible;
			.nice-select {
				background: #fff;
				border: 0;
				font-size: 12px;
				line-height: 14px;
				&:after {
					top: 8px;
					border: 5px solid transparent;
					border-top: 4px solid #8B9DAF;
				}
			}
			&__list {
				font-size: 15px;
				line-height: 19px;
				&>li {
					&:before {
						top: 0;
					}
				}
			}
			&__top {
				background: transparent;
				box-shadow: none;
				font-size: 13px;
				line-height: 18px;
				height: auto;
				padding-top: 15px;
				&__btn {
					display: none;
				}
			}
			&__main {
				background: transparent;
				box-shadow: none;
				padding: 0;
			}
			&__section {
				padding: 10px 20px 10px;
			}
			&__section-price {
				background: #F0F4F8;
				padding-top: 10px;
				padding-bottom: 5px;
			}
			&__price {
				color: $blue2;
				padding-bottom: 0;
				margin-bottom: 0;
				font-size: 30px;
				line-height: 30px;
			}
			&__price-text {
				font-size: 12px;
				line-height: 12px;
				color: $blue2;
			}
		}
	}

	.section-tariffs {
		padding-top: 40px;
		.next-sep {
			margin-bottom: 20px;
			&__line {
				background: #DEE4ED;
			}
		}
	}

	.text-btn {
		flex-direction: column;
		align-items: center;
		&__text {
			font-size: 17px;
			line-height: 21px;
			margin-right: 0;
			margin-bottom: 5px;
		}
		.btn_arrow {
			opacity: .28;
		}
	}

	.section-connect {
		padding-top: 50px;
		.section-title {
			margin-bottom:35px;
			font-size: 22px;
			line-height: 25px;
		}
	}

	.form-connect {
		max-width: 300px;
		border: 0;
		background: none;
		padding: 0;
		&__title {
			font-size: 18px;
			line-height: 24px;
			text-align: left;
			padding-left: 10px;
		}
		&__field-title {
			font-size: 12px;
			line-height: 14px;
			text-align: left;
			padding-left: 15px;
		}
		&__block {
			margin-bottom: 20px;
		}
		&__btn {
			margin-top: 40px;
		}
		&__field {
			input {
				background: #F0F4F8;
				border-color: #E3E8F0;
				border-radius: 30px;
				height: 45px;
			}
		}
	}

	.btn_theme2_medium2 {
		height: 45px;
		font-size: 15px;
		line-height: 20px;
		width: 240px;
		padding-left: 15px;
		padding-right: 15px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	}

	.app {
		flex-direction: column;
		&__img {
			display: none;
		}
		&__col {
			width: 100%;
			padding-bottom: 0;
		}
		&__title {
			text-align: center;
		}
		&__title2 {
			font-size: 18px;
			line-height: 24px;
			text-align: center;
			margin-bottom: 30px;
		}
		&__btns {
			flex-direction: column;
			align-items: center;
			justify-content: center;
			max-width: 100%;
			.btn {
				margin-bottom: 20px;
			}
		}
		&__logo-wrap {
			max-width: 100%;
		}
		.block-logo {
			box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
			margin-bottom: 30px; 
		}
	}

	.section-app {
		padding-top: 90px;
	}

	.section-mass-media {
		display: none;
	}

	.section-reviews {
		padding-top: 45px;
		padding-bottom: 30px;
		.next-sep {
			margin-bottom: 10px;
		}
	}

	.reviews {
		&__item {
			box-shadow: none;
			background: transparent;
			margin: 0;
			transform: none;
			width: 320px;
			bottom: auto;
		}
		&__head {
			flex-direction: column;
			align-items: center;
			text-align: center;
			margin-bottom: 0;
			&__main {
				min-height: auto;
				margin-bottom: 35px;
				&:after {
					display: none;
				}
			}
		}
		&__photo {
			margin-right: 0;
			width: 122px;
			height: 122px;
		}
		&__author {
			font-size: 15px;
			line-height: 20px;
			margin-bottom: 20px;
		}
		&__desc {
			font-size: 15px;
			line-height: 20px;
		}
		&__text {
			font-size: 20px;
			line-height: 26px;
			padding: 0;
			text-align: center;
		}

		.slick-arrow {
			top: 65px;
			z-index: 100;
		}
		.slick-prev {
			left: 30px;
		}
		.slick-next {
			right: 30px;
		}
	}

	.btn_to-top {
		top: 100px;
		left: 30px;
	}

	.list-info-top {
		width: 100%;
	}

	.top-links {
		a {
			&.hide-mob {
				display: none;
			}
		}
	}

	.page-top {
		padding-right: 10px;
	}

	.page-preview-img {
		display: none;
	}

	.article-top {
		border-top: 2px solid #D4E1EB;
		padding-top: 20px;
		&__inner {
			margin-top: 0;
			padding: 0;
		}
	}

	.title_large {
		font-size: 23px;
		line-height: 30px;
	}

	.article-top-desc {
		font-size: 13px;
		line-height: 20px;
		&:after {
			display: none;
		}
	}

	.article-author-wrapper {
		margin: 0;
		padding: 15px 0;
	}

	.article-wrapper {
		p {
			font-size: 16px;
			line-height: 20px;
		}
		blockquote {
			font-size: 16px;
			line-height: 21px;
			padding: 30px 25px;
		}
		.table-wrap {
			

		}

		.news-row-wrap > .title {
			flex-shrink: 1;
		}
		.table-def {
			font-size: 12px;
			line-height: 16px;
			width: 380px;
			margin-left: auto;
			margin-right: auto;
			th {
				font-size: 10px;
				line-height: 14px;
				padding-left: 5px;
				padding-right: 5px;
			}
			td {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
		.table-wrap {
			margin-left: -30px;
			margin-right: -30px;
		}
	}


	.news-row-wrap {
		flex-wrap: wrap;
		display: block;
		.title {
			margin-bottom: 15px;			
			margin-right: 0;
			text-align: center;
		}
		.item-news {
			margin: 0;
		}
		.item-news_row .item-news__img {
			width: 80px;
			height: 80px;
		}
		.item-news_row .item-news__title {
			font-size: 15px;
			line-height: 20px;
		}
	}

	.list-circle {
		font-size: 16px;
		line-height: 20px;
		margin-left: 0;
		&>li {
			margin-bottom: 15px;
			&:before {
				top: 5px;
			}
		}
	}

	.list-check {
		font-size: 16px;
		line-height: 20px;
		margin-left: 0;
		&>li {
			margin-bottom: 15px;
			&:before {
				top: 5px;
			}
		}
	}

	.article-author_end {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.list-numeric {
		font-size: 16px;
		line-height: 20px;
		margin-left: 0;
		&>li {
			margin-bottom: 15px;
		}
		&__num {
			top: -5px;
		}
	}

	.first-letter {
		font-size: 66px;
		line-height: 50px;
		margin-right: 15px;
	}

	.text-block {
		padding: 20px 15px;
		margin-left: 0;
		margin-right: 0;
	}

	.article-img {
		margin-left: -35px;
		margin-right: -35px;
		border-radius: 0;
		padding: 30px;
		&__desc {
			font-size: 13px;
			line-height: 16px;
			text-align: center;
		}
	}

	

	.list-info-wrap {
		&__row {
			flex-wrap: wrap;
			margin: 0;
		}
	}

	.list-info {
		width: 100%;
		margin: 0 0 50px;
	}
	
	.write-comment-wrap {
		&>.title {
			font-size: 12px;
			line-height: 20px;
		}
	}

	.comments-wrapper {
		margin-left: -35px;
		margin-right: -35px;
		padding-left: 35px;
		padding-right: 35px;
	}

	.comment-item {
		&__text {
			font-size: 14px;
			line-height: 20px;
		}
		&__info {
			&__ava {
				width: 33px;
				height: 33px;
				margin-right: 7px;
			}
			&__author {
				font-size: 10px;
				line-height: 12px;
			}
			&__date {
				font-size: 10px;
				line-height: 12px;
			}
		}
		&__main {
			margin-left: 40px;
		}
		.comment-item {
			padding-left: 40px;
		}
	}

	.comment-answer-area {
		margin-top: 10px;
	}

	.comments-lvl2 {
		padding-left: 40px;
	}

	.btns-tag_bottom {
		margin-top: 40px;
		margin-left: 0;
	}

	.btns-tag_theme2 {
		a {
			font-size: 12px;
			line-height: 14px;
			height: 33px;
			margin-left: 5px;
			margin-right: 5px;
			margin-bottom: 10px;
		}
	}

	.item-news {
		&__img {
			width: 80px;
		}
		&__title {
			font-size: 18px;
			line-height: 22px;
		}
	}

	.item-news2 {
		width:270px;
		margin-left: 8px;
		margin-right: 8px;
		&__title {
			padding: 15px;
			font-size: 18px;
			line-height: 22px;
		}
		&__img {
			height: 180px;
		}
		&__section {
			padding: 15px;
		}
		&__details {
			display: none;
		}
	}

	.block-wrapper {
		&__title {
			font-size: 20px;
			line-height: 26px;
		}
	}

	.bg-figure {
		display: none;
	}

	.blog-section {
		.container {
			padding-left: 20px;
			padding-right: 20px;
		}
		.sidebar {
			margin-top: 0;
		}
	}

	.news-wrapper_slider-mob {
		margin-left: 0;
		margin-right: 0;
		.item-news2 {
			width: 160px;
			padding: 0 7px;
			margin: 10px 0;
			&__inner {
				box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
			}
			&__desc {
				display: none;
			}
			&__title {
				font-size: 15px;
				line-height: 20px;
			}
			&__img {
				height: 130px;
			}
		}
	}

	.content-main_blog {
		.news-wrapper_slider-mob {
			margin-left: -20px;
			margin-right: -20px;
			margin-bottom: 30px;
			.slick-list {
				padding-left: 20px;
			}
		}
	}

	.btns-tag {
		&_offset {
			margin-bottom: 30px;
		}
	}

	.promo-block {
		&_gorizontal {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}

	.promo-block {
		&_gorizontal {
			padding-top: 15px;
			padding-bottom: 15px;
			.container {
				flex-direction: column;
			}
			.promo-block {
				&__title {
					font-size: 27px;
					line-height: 36px;
					margin-bottom: 5px;
				}
				&__desc {
					font-size: 13px;
					line-height: 17px;
				}
				&__desc-text-size2 {
					font-size: 13px;
					line-height: 17px;
				}
				&__col1 {
					margin-bottom: 15px;
					&>img {
						width: 100px;
					}
					&__main {
						width: 300px;
						margin-left: 15px;
					}
				}
			}
			.btn_theme5_large {
				font-size: 18px;
				line-height: 22px;
			}
		}
	}

	.news-slider_mod2 {
		margin-left: -15px;
		margin-right: -15px;
		.item-news {
			margin-left: 15px;
			margin-right: 15px;
		}
	}










	
}