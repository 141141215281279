@media (max-width: 1770px) {
	

	.write-comment-wrap {
		margin-left: 0;
	}

	.page-preview-img {
		img {
			max-width: 100%;
		}
	}

	.article-inner {
		padding-left: 30px;
	}

	.news-slider3 {
		.slick-prev {
			left: 30px;
		}
		.slick-next {
			right: 30px;
		}
	}


}

@media (max-width: 1600px) {
	.side-left {
		display: none;
	}

	.article-inner {
		padding-left: 0;
	}

	.article-author-wrapper {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.article-wrapper {
		padding-top: 30px;
	}

	.informers-wrapper {
		position: static;
		border-bottom: 1px solid #FF7484;
		width: 100%;
		display: flex;
		padding-bottom: 20px;
		margin-bottom: 30px;
		.inform-item-def {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: 0;
			margin-right: 20px;
		}
	}


}

@media (max-width: 1530px) {
	.differences2 {
		&__img {
			display: flex;
			justify-content: flex-end;
			&__inner {
				flex-shrink: 0;
			}
		}
	}

	.content-main_blog {
		.item-news2 {
			width: 50%;
		}
	}

	.promo-block {
		&_gorizontal {
			.btn_theme5_large {
				font-size: 22px;
				line-height: 26px;
				padding-left: 20px;
				padding-right: 20px;
				min-width: 300px;
				flex-shrink: 0;
			}
			.promo-block {
				&__title {
					font-size: 30px;
					line-height: 36px;
				}
				&__desc-text-size2 {
					font-size: 22px;
					line-height: 24px;
				}
				&__desc {
					font-size: 20px;
					line-height: 24px;
				}
			}
		}
	}


}

@media (max-width: 1350px) {

	.block-more {
		margin-right: -35px;
		margin-left: -35px;
	}

	.block-request {
		border-radius: 0;
		padding-left: 30px;
		padding-right: 30px;
		&__left {
			flex-shrink: 1;
			margin-right: 20px;
		}
		&__form {
			width: 60%;
		}
		&__title {
			font-size: 22px;
			line-height: 22px;
		}
	}




}

@media (max-width: 1229px) {
	.start {
		&__title {
			font-size: 34px;
			line-height: 44px;
		}
	}

	.advantages {
		&__item {
			margin-right: 1.3%;
			margin-left: 1.3%;
			width: 30.7%;
		}
		&__text {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.tariffs {
		&__item {
			margin-left: 1%;
			margin-right: 1%;
			width: 23%;
		}
	}

	.footer {
		&__logotypes {
			margin-right: 60px;
		}
		&__menu {
			width: 350px;
			margin-right: 15px;
		}
	}

	.header_inner {
		.menu {
			&>li {
				margin-right: 20px;
				&>a {
					font-size: 15px;
				}
			}
		}
		.header {
			&__call {
				font-size: 15px;
			}
			&__section-nav {
				padding-right: 0;
			}
		}
	}

	.top-links {
		a {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.search-block {
		flex-shrink: 0;
		width: 270px;
		margin-left: 20px;
		box-sizing: border-box;
	}

	.article-top {
		&__inner {
			margin-left: 0;
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.title_large {
		font-size: 34px;
		line-height: 40px;
	}

	.comments-wrapper {
		padding-left: 20px;
		padding-right: 20px;
	}
	
	.comment-item {
		&__text {
			font-size: 20px;
			line-height: 28px;
		}
	}

	.sidebar {
		width: 250px;
		.promo-block_theme2 {
			padding: 30px;
		}
		.block-wrapper_side {
			padding-left: 15px;
			padding-right: 15px;
		}
		.item-news_side .item-news__img {
			width: 70px;
			height: 70px;
		}
		.item-news {
			width: 100%;
		}
		.promo-block_mod2 {
			padding-left: 30px;
			padding-right: 30px;
			.promo-block__title {
				font-size: 30px;
				line-height: 30px;
			}
			.promo-block__desc {
				font-size: 22px;
				line-height: 28px;
			}
		}
	}

	.news-row-wrap {
		margin-right: 0;
		margin-left: 0;
		padding-left: 30px;
		padding-right: 30px;
	}

	.promo-block {
		&_large {
			padding-left: 15px;
			padding-right: 15px;
			.promo-block {
				&__title {
					font-size: 36px;
					line-height: 42px;
				}
				&__desc {
					font-size: 22px;
					line-height: 26px;
				}
			}
		}

		&__col1 {
			&__main {
				width: 400px;
			}
		}
	}











}

@media (max-width: 1150px) {
	.differences1 {
		&__img {
			&__inner {
				right: 0;
				padding: 0 30px;
			}
		}
	}

	.section-app {
		padding-top: 150px;
	}

	.app {
		&__title {
			font-size: 36px;
			line-height: 42px;
		}
	}



}

@media (max-height: 620px) {
	.win-enter {
		&__logo {
			margin-bottom: 50px;
		}
		&__btn {
			margin-top: 40px;
		}
	}
}

